import { ReactElement, forwardRef } from 'react';
import { CloseButton, CopyButton, List, Popover } from '@mantine/core';
import { Col } from '../../../components/Layout/Grid/Grid';
import { Box } from '../../../components/Miscellaneous/Box/Box';
import { Text, Tooltip } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { FaClipboard } from 'react-icons/fa';
import { ActionIcon } from '../../../components/Buttons/ActionIcon/ActionIcon';
import { BsCheckSquareFill } from 'react-icons/bs';

interface ShortStats {
  total: number;
  booked: number;
}

interface Stats {
  weekly: ShortStats;
  daily: ShortStats;
}

interface Props {
  setIsShortStatsOpened: CallableFunction;
  stats: Stats;
  laneName: string;
}

export const ShortStatsPopOverContent = ({
  setIsShortStatsOpened,
  stats,
  laneName
}: Props): ReactElement => {
  const copyValue = `${laneName} \n Today \n\t Found: ${
    stats.daily?.total || 0
  } \n\t Booked: ${stats.daily?.booked || 0} \n Weekly \n\t Found: ${
    stats.weekly?.total || 0
  } \n\t Booked: ${stats.weekly?.booked || 0}`;

  const CopyWithTooltip = forwardRef<HTMLDivElement, { copyValue: string }>(
    ({ copyValue }, ref) => (
      <CopyButton value={copyValue} timeout={2000}>
        {({ copied, copy }) => (
          <Tooltip label={copied ? 'Copied' : 'Copy'}>
            <div ref={ref}>
              <ActionIcon color={'gray'} variant="subtle" onClick={copy}>
                {copied ? <BsCheckSquareFill /> : <FaClipboard />}
              </ActionIcon>
            </div>
          </Tooltip>
        )}
      </CopyButton>
    )
  );

  return (
    <>
      <Popover.Dropdown>
        <Flex direction={'row-reverse'}>
          <Tooltip label={'Close'}>
            <CloseButton
              onClick={() => setIsShortStatsOpened(false)}
            ></CloseButton>
          </Tooltip>

          <CopyWithTooltip copyValue={copyValue}></CopyWithTooltip>

          <Box w={'100%'}>
            <Col span="content">
              <Text size="xs" align="left">
                <div>
                  <b>Lane Stats:</b>
                </div>
                <div>
                  <b>Today:</b>
                  <List size={'xs'}>
                    <List.Item>Found: {stats.daily?.total || 0}</List.Item>
                    <List.Item>Booked: {stats.daily?.booked || 0}</List.Item>
                  </List>
                </div>
                <div>
                  <b>This week:</b>
                  <List size={'xs'}>
                    <List.Item>Found: {stats.weekly?.total || 0}</List.Item>
                    <List.Item>Booked: {stats.weekly?.booked || 0}</List.Item>
                  </List>
                </div>
              </Text>
            </Col>
            <Col id="short_stats">
              <Text size={'xs'}>
                Find all booked loads in{' '}
                <a target="_blank" href="/my-loads">
                  My Loads
                </a>{' '}
                section
              </Text>
              <Text size={'xs'}>
                Find all stats in PBI report
                <a
                  href="https://app.powerbi.com/groups/me/apps/d7d2be32-f72e-46fd-b83f-3df4c160947d/reports/5f7dd633-9a72-41fa-ba28-d055ea168290/ReportSection?experience=power-bi"
                  target="_blank"
                >
                  {' '}
                  LOADBOARDS
                </a>
              </Text>
            </Col>
          </Box>
        </Flex>
      </Popover.Dropdown>
    </>
  );
};
