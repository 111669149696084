import { ReactElement, memo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Image } from '../../../components/DataDisplay/Image/Image';
import {
  Text,
  Title,
  Tooltip
} from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Preview } from '../../FindLoadsList/Preview/Preview';
import { ActionIcon } from '../../../components/Buttons/ActionIcon/ActionIcon';
import { Space } from '../../../components/Layout/Space/Space';
import { LoadProps } from '../../../context';
import { Divider } from '../../../components/Miscellaneous/Divider/Divider';
import { Box } from '../../../components/Miscellaneous/Box/Box';
import { reportLoadView } from '../../../api/api';
import { formattedDestinationTime, getLogo } from '../../../utils/utils';
import { MediaQuery } from '../../../components/Layout/MediaQuery/MediaQuery';
import { offerStatusMap } from '../../../utils/offerStatusMap';

interface Props {
  type?: 'booked' | 'offers' | 'modal' | 'bidsLive';
  load: LoadProps;
  statusChanged?: boolean;
  currentSearch?: any;
  offer_status?: string;
  offer?: any;
  onModalOpen?: (offer: any) => void;
}

const getOriginDeadHeadMiles = (load: LoadProps) => {
  const sourceMiles = load.origin_dead_head_miles;
  const aproximatedMiles = load.aproximated_origin_dead_head_miles;

  if (sourceMiles > 0 || sourceMiles === 0) {
    return sourceMiles;
  }

  if (aproximatedMiles > 0 || aproximatedMiles === 0) {
    return `∽${aproximatedMiles}`;
  }
};

export const LoadRow = memo(
  ({
    type,
    load,
    statusChanged,
    currentSearch,
    offer_status,
    offer,
    onModalOpen
  }: Props): ReactElement => {
    const [isPreview, setIsPreview] = useState(false);

    const { stops } = load;

    const [firstStop] = stops;
    const [lastStop] = [...stops].reverse();

    const isModal = type === 'modal';
    const isBidsLive = type === 'bidsLive';

    return (
      <>
        <Paper
          p="lg"
          sx={{
            backgroundColor: isBidsLive
              ? statusChanged
                ? '#fff5e3'
                : 'white'
              : 'white',
            overflow: 'hidden',
            border: isBidsLive
              ? statusChanged
                ? '1px solid #dfaa4b8a'
                : '1px solid #dad8d8'
              : ''
          }}
        >
          <Grid gutter="xl" align="center">
            <Col span={isBidsLive ? 1 : 'content'}>
              <Image
                src={getLogo(load.source)}
                alt="Sample logo"
                width={50}
                height="auto"
              />
            </Col>
            <Col span={12} xl={isBidsLive ? 4 : 3}>
              <Flex justify="space-between" align="center">
                <Box w="100%" ml="xl">
                  <Flex align="center">
                    <Image
                      src="src/assets/icons/elipse.svg"
                      width={12}
                      height={12}
                      ml={-16}
                      mr={16}
                    />
                    <Text fz="md" fw="bold">
                      {firstStop.address.city_name?.length > 0
                        ? `${firstStop.address.city_name}, ${firstStop.address.postal_code}, ${firstStop.address.state_province_code}`
                        : firstStop.address.full_address}
                    </Text>
                  </Flex>
                  <Text fz="sm" c="dimmed" ml={12}>
                    {formattedDestinationTime(
                      firstStop.time_from_local,
                      firstStop.time_to_local,
                      firstStop.time_zone
                    )}
                  </Text>
                </Box>
                <MediaQuery smallerThan="xl" styles={{ display: 'none' }}>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </MediaQuery>
              </Flex>
            </Col>
            <Col span={12} xl={isBidsLive ? 4 : 3}>
              <Flex justify="space-between">
                <Box w="100%" ml="xl">
                  <Flex align="center">
                    <Image
                      src="src/assets/icons/pointer.svg"
                      width={12}
                      height={16}
                      ml={-16}
                      mr={16}
                    />
                    <Text fz="md" fw="bold">
                      {lastStop.address.city_name?.length > 0
                        ? `${lastStop.address.city_name}, ${lastStop.address.postal_code}, ${lastStop.address.state_province_code}`
                        : lastStop.address.full_address}
                    </Text>
                  </Flex>
                  <Text fz="sm" c="dimmed" ml={12}>
                    {formattedDestinationTime(
                      lastStop.time_from_local,
                      lastStop.time_to_local,
                      lastStop.time_zone
                    )}
                  </Text>
                </Box>
                <MediaQuery smallerThan="xl" styles={{ display: 'none' }}>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </MediaQuery>
              </Flex>
            </Col>
            {!isBidsLive && (
              <Col span="content">
                <Flex justify="space-between">
                  <Box w="100%">
                    <Flex justify="space-between" align="center">
                      <Text fz="sm" c="dimmed">
                        Miles
                      </Text>
                      <Text fz="md" fw="bold" ml={50}>
                        {load.customer_miles}
                      </Text>
                    </Flex>
                    <Flex justify="space-between" align="center">
                      <Text fz="sm" c="dimmed">
                        <Tooltip label={'Origin deadhead miles'}>
                          <div>ODH</div>
                        </Tooltip>
                      </Text>
                      <Text fz="md" fw="bold" ml={50}>
                        {getOriginDeadHeadMiles(load)}
                      </Text>
                    </Flex>
                  </Box>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </Flex>
              </Col>
            )}
            {!isModal && !isBidsLive && (
              <Col span="content">
                <Flex justify="space-between">
                  <Box w="100%">
                    <Text fz="sm" c="dimmed">
                      Weight
                    </Text>
                    <Text fz="md" fw="bold">
                      {load.weight} lbs
                    </Text>
                  </Box>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </Flex>
              </Col>
            )}
            {!isBidsLive && (
              <>
                <Col span="content">
                  <Flex justify="space-between">
                    <Box w="100%">
                      <Text fz="sm" c="dimmed">
                        RPM
                      </Text>
                      <Text fz="md" fw="bold">
                        {load.rate_per_mile ? `$${load.rate_per_mile}/mi` : '-'}
                      </Text>
                    </Box>
                    <Divider orientation="vertical" h="auto" ml="xl" />
                  </Flex>
                </Col>
                <Col span="auto">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Title order={2}>
                      <NumericFormat
                        value={load.rate}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Title>
                    {!isModal && !isBidsLive && (
                      <ActionIcon
                        onClick={() => {
                          if (load.source === 'newtrul' && !isPreview) {
                            reportLoadView(load.id);
                          }
                          setIsPreview(!isPreview);
                        }}
                      >
                        {!isPreview && <FaChevronDown />}
                        {isPreview && <FaChevronUp />}
                      </ActionIcon>
                    )}
                  </Box>
                </Col>
              </>
            )}
            {isPreview && (
              <Col span={12} style={{ zIndex: 1, background: 'white' }}>
                <Preview
                  load={load}
                  currentSearch={currentSearch}
                  type={type}
                />
              </Col>
            )}
            {isBidsLive && offer_status && (
              <Col span={3}>
                <Title order={5}>
                  {`Rate: `}
                  <NumericFormat
                    value={load.rate}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Title>
                <Title order={5} mb={5}>
                  {`Offer: `}
                  <NumericFormat
                    value={offer.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Title>
                <Title
                  onClick={() => {
                    if (offer && onModalOpen) {
                      onModalOpen(offer);
                    }
                  }}
                  order={5}
                  className={`offer ${offer_status} opens-modal`}
                >
                  {offerStatusMap[offer_status]}
                </Title>
              </Col>
            )}
          </Grid>
        </Paper>
        <Space h={20} />
      </>
    );
  }
);
