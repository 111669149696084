import { ReactElement, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Text } from '../../../components/Typography/Typography';
import { Price } from '../Common/Price/Price';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Button } from '../../../components/Buttons/Button/Button';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Information } from '../Common/Information/Information';

export const Preview = memo(
  ({
    type,
    load,
    currentSearch = {},
    loadListIndex,
    getListScrollOffset = () => {}
  }: any): ReactElement => {
    const navigate = useNavigate();
    const { full_name, phone, email, position } = load.contacts || {};

    const navigateToDetailsPage = () => {
      navigate(`/details/${load.stable_id}`, {
        state: {
          savedSearchId: currentSearch?.id,
          loadListIndex: loadListIndex,
          loadListScrollOffset: getListScrollOffset()
        }
      });
    };

    return (
      <Grid sx={{ backgroundColor: 'white' }}>
        <Col span={type ? 12 : 9}>
          <Paper sx={{ backgroundColor: '#F7F8F8' }} radius="md" p="xs">
            <Flex gap="md" justify="space-between" align="center" wrap="wrap">
              <Text fz="sm">
                Load number: <b>#{load.load_id}</b>
              </Text>
              <Button
                variant="white"
                color="black"
                rightIcon={<FaArrowRight />}
                onClick={navigateToDetailsPage}
              >
                Full load details
              </Button>
            </Flex>
          </Paper>
          <Grid m="xs">
            <Col span={12}>
              <Information load={{ ...load, preview: true }} />
            </Col>
          </Grid>
        </Col>
        {!type && (
          <Col span={3}>
            <Price
              load={load}
              id={load.load_id}
              loadStableId={load.stable_id}
              source={load.source}
              loadId={load.id}
              rate={load.rate}
              ratePerMile={load.rate_per_mile}
              email={email}
              position={position}
              fullName={full_name}
              phone={phone}
            />
          </Col>
        )}
      </Grid>
    );
  }
);
