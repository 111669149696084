import { useReducer, useEffect, useState } from 'react';
import { MantineProvider } from '@mantine/core';
import { RouterProvider } from 'react-router-dom';
import { routes } from './routes';
import { AppContext, SourceProps } from './context';
import { loadsListReducer } from './reducers/loadsListReducer';
import { offersReducer } from './reducers/offersReducer';
import { savedSearchesReducer } from './reducers/savedSearchesReducer';
import { autoBookingFiltersReducer } from './reducers/autoBookingFiltersReducer';
import { recommendedLoadsReducer } from './reducers/recommendedLoadsReducer';
import theme from './theme/lightTheme';
import './App.css';
import subscriber from './api/Subscriber';
import AccessKeyManager from './api/AccessKeyManager';
import { BidsLive } from './modules/BidsLive/BidsLive';
import { NotificationsProvider } from '@mantine/notifications';
import { getCurrentUser, getSources } from './api/api';
import { usersReducer } from './reducers/usersReducer';
import { store } from './store';
import { Provider } from 'react-redux';
import { displayStatusChangeNotification } from './utils/offerNotifications';

function App() {
  const [user, setUser] = useReducer(usersReducer, null);
  const [sources, setSources] = useState<SourceProps | null>(null);
  const [loads, setLoads] = useReducer(loadsListReducer, []);
  const [offers, setOffers] = useReducer(offersReducer, []);
  const [liveOffers, setLiveOffers] = useReducer(offersReducer, []);
  const [savedSearches, setSavedSearches] = useReducer(
    savedSearchesReducer,
    []
  );
  const [recommendedLoads, setRecommendedLoads] = useReducer(
    recommendedLoadsReducer,
    []
  );
  const [notificationOffers, setNotificationOffers] = useReducer(
    offersReducer,
    []
  );
  const [autoBookingFilters, setAutoBookingFilters] = useReducer(
    autoBookingFiltersReducer,
    []
  );

  useEffect(() => {
    AccessKeyManager.getToken().then((accessToken) => {
      subscriber.subscribe({ accessToken });

      getCurrentUser().then((user) => {
        setUser({
          type: 'SET',
          payload: user || null
        });
      });

      getSources().then((response) => {
        setSources(response || null);
      });

      subscriber.addListener('offers_results', (payload) => {
        setLiveOffers({
          type: 'UPDATE',
          payload: payload.data
        });
        setOffers({
          type: 'UPDATE_EXISTING',
          payload: payload.data
        });
        setNotificationOffers({
          type: 'UPDATE',
          payload: payload.data
        });
        setLoads({
          type: 'OFFER_EVENT',
          payload: payload.data
        });

        displayStatusChangeNotification(payload.data);
      });
    });
  }, []);

  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          user,
          setUser,
          sources,
          setSources,
          loads,
          setLoads,
          offers,
          setOffers,
          liveOffers,
          setLiveOffers,
          savedSearches,
          setSavedSearches,
          notificationOffers,
          setNotificationOffers,
          recommendedLoads,
          setRecommendedLoads,
          autoBookingFilters,
          setAutoBookingFilters
        }}
      >
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <NotificationsProvider position={'top-center'}>
            <RouterProvider router={routes} />
            <BidsLive />
          </NotificationsProvider>
        </MantineProvider>
      </AppContext.Provider>
    </Provider>
  );
}

export default App;
