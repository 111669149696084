import { ReactElement, useState } from 'react';
import {
  AutoBookingFilterProps,
  AutoBookingFiltersSearchProps,
  UserProps
} from '../../../context';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { Group } from '../../../components/Layout/Group/Group';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Button } from '../../../components/Buttons/Button/Button';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { AutoBookingFilterModal } from '../AutoBookingFilterModal';

interface Props {
  onSearch: (data: AutoBookingFiltersSearchProps) => void;
}

export const initialValues: AutoBookingFiltersSearchProps = {
  ab_filters: 'company'
};

export const Filter = ({ onSearch }: Props): ReactElement => {
  const [myBtnActive, setMyBtnActive] = useState<boolean>(false);
  const [companyBtnActive, setCompnayBtnActive] = useState<boolean>(true);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const autoBookingFilterInitailValues: AutoBookingFilterProps = {
    settings: {
      rate_conf_receiver: '',
      enabled: false,
      origin: {
        location: {
          coordinates: {
            longitude: '',
            latitude: ''
          },
          address: {
            formatted_address: '',
            state_code: '',
            limit_to_states: [],
            limit_enabled: false
          }
        },
        radius: 50,
        time_from: '07:00',
        time_to: '15:00',
        exact_time: false
      },
      destination: {
        location: {
          coordinates: {
            longitude: '',
            latitude: ''
          },
          address: {
            formatted_address: '',
            state_code: '',
            limit_to_states: [],
            limit_enabled: false
          }
        },
        radius: 50,
        time_from: '07:00',
        time_to: '15:00',
        exact_time: false
      },
      loaded_miles: {
        min: 350,
        max: 3000
      },
      rpm_from: 1.0,
      capacity_of_lane: 1,
      duration: {
        min: 1,
        max: 3
      }
    }
  };

  return (
    <>
      <Grid>
        <Paper w="100%" ml={5} mr={5} p="lg" shadow="sm">
          <Col span={12}>
            <Flex align={'flex-end'} justify={'space-between'}>
              <Button onClick={() => setIsModalOpened(true)}>
                Create Auto Booking Lane
              </Button>
              <Group>
                <Button
                  type="submit"
                  ml="auto"
                  className={
                    companyBtnActive
                      ? 'ab-filter-active-btn'
                      : 'ab-filter-non-active-btn'
                  }
                  onClick={() => {
                    onSearch({ ab_filters: 'company' });
                    setCompnayBtnActive(true);
                    setMyBtnActive(false);
                  }}
                >
                  See All Auto Booking Lanes
                </Button>
                <Button
                  type="submit"
                  ml="auto"
                  className={
                    myBtnActive
                      ? 'ab-filter-active-btn'
                      : 'ab-filter-non-active-btn'
                  }
                  onClick={() => {
                    onSearch({ ab_filters: 'my' });
                    setCompnayBtnActive(false);
                    setMyBtnActive(true);
                  }}
                >
                  See My Auto Booking Lanes
                </Button>
              </Group>
            </Flex>
          </Col>
        </Paper>
      </Grid>
      <AutoBookingFilterModal
        autoBookingFilter={autoBookingFilterInitailValues}
        opened={isModalOpened}
        close={() => {
          setIsModalOpened(false);
        }}
      />
    </>
  );
};
