import { configureStore } from '@reduxjs/toolkit';
import rowReducer from './slices/rowSlice';

export const store = configureStore({
  reducer: {
    rowReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
