import { ReactElement, forwardRef, useContext, useState } from 'react';
import { BiRadioCircle } from 'react-icons/bi';
import {
  FaMapMarkerAlt,
  FaCog,
  FaTrash,
  FaPauseCircle,
  FaPlayCircle
} from 'react-icons/fa';
import { IoIosStats } from 'react-icons/io';
import { SiProbot } from 'react-icons/si';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Text, Tooltip } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { ActionIcon } from '../../../components/Buttons/ActionIcon/ActionIcon';
import { Space } from '../../../components/Layout/Space/Space';
import { AppContext } from '../../../context';
import { deleteAutoBookFilter, updateAutoBookFilter } from '../../../api/api';
import { AutoBookingFilterModal } from '../AutoBookingFilterModal';
import { Popover } from '@mantine/core';
import '../../../assets/styles/scss/AutoBookingFilters/auto-booking-filter-row.scss';
import { ShortStatsPopOverContent } from '../Popovers/ShortStatsPopOverContent';
import { ToggleStatusPopOverContent } from '../Popovers/ToggleStatusPopOverContent';
import { DeleteFilterPopOverContent } from '../Popovers/DeleteFilterPopOverContent';
import { IoPersonCircle } from 'react-icons/io5';

export const Row = ({ autoBookingFilter, isLastRow }: any): ReactElement => {
  const [enabled, setEnabled] = useState(autoBookingFilter?.settings?.enabled);
  const { setAutoBookingFilters } = useContext(AppContext);
  const [modalOpened, setModalOpened] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isOpened, setIsOpened] = useState(false);
  const [isToggleStatusOpened, setIsToggleStatusOpened] = useState(false);
  const [isShortStatsOpened, setIsShortStatsOpened] = useState(false);

  const toggleEnabled = (enabled: boolean) => {
    updateAutoBookFilter(autoBookingFilter.id || 0, {
      ...autoBookingFilter.settings,
      enabled
    }).then((response: any) => {
      if (response) {
        setAutoBookingFilters({
          type: 'UPDATE',
          payload: response
        });
      }
      setEnabled(enabled);
    });
  };

  const handleDelete = (): any => {
    setIsVisible(false);
    setTimeout(() => {
      deleteAutoBookFilter(autoBookingFilter.id).then(() => {
        setAutoBookingFilters({
          type: 'REMOVE',
          payload: autoBookingFilter
        });
      });
    }, 1000); // Match the duration of the fade-out animation
  };

  const renderDesktopGrid = () => (
    <Grid>
      <Col span={1}>
        <div>
          <SiProbot size={24} />
        </div>
        {autoBookingFilter?.user_is_owner && (
          <Tooltip label={'My lane'}>
            <UserIcon></UserIcon>
          </Tooltip>
        )}
      </Col>
      <Col span={3}>
        <div>
          <b>Creator: </b> {autoBookingFilter.author}
        </div>
        <div>
          <b>Rate Conf Receiver: </b>
          {autoBookingFilter.settings.rate_conf_receiver}
        </div>
      </Col>
      <Col span="auto">
        <Flex align="center">
          <Text color="blue">
            <Flex align="center">
              <BiRadioCircle size={20} />
            </Flex>
          </Text>
          <Text fw="bold" ml="xs">
            {
              autoBookingFilter?.settings?.origin?.location?.address
                ?.formatted_address
            }
          </Text>
        </Flex>
      </Col>
      <Col span="auto">
        <Flex align="center">
          <Text color="blue">
            <Flex align="center">
              <FaMapMarkerAlt />
            </Flex>
          </Text>
          <Text fw="bold" ml="xs">
            {
              autoBookingFilter?.settings?.destination?.location?.address
                ?.formatted_address
            }
          </Text>
        </Flex>
      </Col>
      <Col span={'content'}>
        <Text c={enabled ? 'green' : 'red'} fw={900}>
          {enabled ? 'ACTIVE' : 'PAUSED'}
        </Text>
      </Col>
      <Col span="content">
        {autoBookingFilter && (
          <Popover
            opened={isShortStatsOpened}
            width={350}
            position="bottom"
            withArrow
            shadow="md"
            onClose={() => setIsShortStatsOpened(false)}
          >
            <Popover.Target>
              <span style={{ backgroundColor: 'white', cursor: 'pointer' }}>
                <ActionIcon onClick={() => setIsShortStatsOpened(true)}>
                  <IoIosStats style={{ color: '#868e96' }} />
                </ActionIcon>
              </span>
            </Popover.Target>
            <ShortStatsPopOverContent
              stats={autoBookingFilter.short_stats}
              setIsShortStatsOpened={setIsShortStatsOpened}
              laneName={autoBookingFilter.lane_name}
            ></ShortStatsPopOverContent>
          </Popover>
        )}
      </Col>
      <Col span="content">
        {autoBookingFilter && (
          <Popover
            opened={isToggleStatusOpened}
            width={200}
            position="bottom"
            withArrow
            shadow="md"
            onClose={() => setIsToggleStatusOpened(false)}
          >
            <Popover.Target>
              <span style={{ backgroundColor: 'white', cursor: 'pointer' }}>
                <ActionIcon onClick={() => setIsToggleStatusOpened(true)}>
                  {enabled ? (
                    <FaPauseCircle style={{ color: '#868e96' }} />
                  ) : (
                    <FaPlayCircle style={{ color: '#868e96' }} />
                  )}
                </ActionIcon>
              </span>
            </Popover.Target>
            <ToggleStatusPopOverContent
              setIsToggleStatusOpened={setIsToggleStatusOpened}
              enabled={enabled}
              toggleEnabled={toggleEnabled}
            />
          </Popover>
        )}
      </Col>
      {autoBookingFilter && (
        <Col span="content">
          <ActionIcon
            color="white"
            sx={(theme) => ({
              color: theme.colors.gray[6]
            })}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            <FaCog title="Edit" />
          </ActionIcon>
        </Col>
      )}
      <Col span="content">
        {autoBookingFilter && (
          <Popover
            opened={isOpened}
            width={200}
            position="bottom"
            withArrow
            shadow="md"
            onClose={() => setIsOpened(false)}
          >
            <Popover.Target>
              <span style={{ backgroundColor: 'white', cursor: 'pointer' }}>
                <ActionIcon onClick={() => setIsOpened(true)}>
                  <FaTrash title="Delete" style={{ color: '#868e96' }} />
                </ActionIcon>
              </span>
            </Popover.Target>
            <DeleteFilterPopOverContent
              setIsOpened={setIsOpened}
              handleDelete={handleDelete}
            />
          </Popover>
        )}
      </Col>
    </Grid>
  );

  return (
    <>
      <div className={`${!isVisible ? 'fade-out' : ''}`}>
        <Paper
          p="lg"
          sx={(theme) => ({ border: `1px solid ${theme.colors.gray[0]}` })}
        >
          {renderDesktopGrid()}
        </Paper>
        {!isLastRow && <Space h={20} />}

        {modalOpened && (
          <AutoBookingFilterModal
            autoBookingFilter={autoBookingFilter}
            autoBookingFilterId={autoBookingFilter.id}
            opened={true}
            close={() => {
              setModalOpened(false);
            }}
          />
        )}
      </div>
    </>
  );
};

const UserIcon = forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} {...props}>
    <IoPersonCircle size={24}></IoPersonCircle>
  </div>
));
