import { ReactElement, memo } from 'react';
import { Center } from '../../components/Layout/Center/Center';
import { Modal } from '../../components/Overlays/Modal/Modal';
import { Title } from '../../components/Typography/Typography';
import { BookingForm } from '../../forms/BookingForm/BookingForm';
import { ModalRow } from '../../modules/ModalRow/ModalRow';
import { useStyles } from './BookingModal.style';

interface Props {
  load: any;
  isOpen: boolean;
  setIsOpen: (isFilterModalOpen: boolean) => void;
  onSubmit: (values: any) => void;
}

export const BookingModal = memo(
  ({ load, isOpen, setIsOpen, onSubmit }: Props): ReactElement => {
    const { classes } = useStyles();

    return (
      <Modal
        classNames={classes}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={
          <Center>
            <Title order={3}>Book now</Title>
          </Center>
        }
        size="930px"
        centered
        lockScroll={false}
      >
        <ModalRow load={load} />
        <BookingForm
          load={load}
          onSubmit={onSubmit}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    );
  }
);
