import { useContext, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import useFetch from 'use-http';
import { Button } from '../../../../components/Buttons/Button/Button';
import { Title, Text } from '../../../../components/Typography/Typography';
import { Paper } from '../../../../components/Miscellaneous/Paper/Paper';
import { Flex } from '../../../../components/Layout/Flex/Flex';
import { BookingModal } from '../../../../modals/BookingModal/BookingModal';
import { MakeOfferModal } from '../../../../modals/MakeOfferModal/MakeOfferModal';
import { OFFER_URL } from '../../../../api/api';
import { AppContext } from '../../../../context';

interface IProps {
  load: any;
  id: number;
  rate: number;
  ratePerMile: number;
  email: string;
  position: string;
  fullName: string;
  phone: string;
}

export const Price: React.FC<IProps> = ({
  load,
  id,
  rate,
  ratePerMile,
  email,
  position,
  fullName,
  phone
}) => {
  const { get, post } = useFetch(OFFER_URL);
  const { setLoads } = useContext(AppContext);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isMakeOfferModalOpen, setIsMakeOfferModalOpen] = useState(false);

  return (
    <>
      <Paper shadow="sm" p="md" sx={{ backgroundColor: '#e8f6f1' }}>
        <Title order={2} mb="sm">
          <NumericFormat
            value={rate}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </Title>
        <Text fz="sm" mb="sm">
          Rate per mile:{' '}
          <NumericFormat
            value={ratePerMile}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
          /mi
        </Text>
        <Button
          fullWidth
          size="lg"
          mb="sm"
          onClick={() => {
            setIsBookingModalOpen(true);
          }}
        >
          Book now
        </Button>
        <Button
          fullWidth
          size="lg"
          variant="outline"
          onClick={() => {
            setIsMakeOfferModalOpen(true);
          }}
        >
          Make an offer
        </Button>
        <Flex justify="space-between" mt="sm">
          <Text fz="sm" fw="bold">
            {fullName || '-'}
          </Text>
          <Text fz="sm" c="dimmed">
            {phone || '-'}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text fz="sm" c="dimmed">
            {position || '-'}
          </Text>
          <Text fz="sm" c="dimmed">
            {email || '-'}
          </Text>
        </Flex>
      </Paper>
      {isBookingModalOpen && (
        <BookingModal
          load={load}
          isOpen={isBookingModalOpen}
          setIsOpen={setIsBookingModalOpen}
          onSubmit={(values) => {
            setIsBookingModalOpen(false);
            setLoads({
              type: 'BOOK',
              payload: {
                id
              }
            });
          }}
        />
      )}
      {isMakeOfferModalOpen && (
        <MakeOfferModal
          load={load}
          isOpen={isMakeOfferModalOpen}
          setIsOpen={setIsMakeOfferModalOpen}
          onSubmit={(values) => {
            setIsMakeOfferModalOpen(false);
            setLoads({
              type: 'OFFER',
              payload: {
                id
              }
            });
          }}
        />
      )}
    </>
  );
};
