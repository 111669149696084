import { ReactElement } from 'react';
import type { TitleProps, TextProps, TooltipProps } from '@mantine/core';
import {
  Title as MantineTitle,
  Text as MantineText,
  Tooltip as MantineTooltip
} from '@mantine/core';

export const Title = ({ children, ...rest }: TitleProps): ReactElement => {
  return <MantineTitle {...rest}>{children}</MantineTitle>;
};

export const Text = ({ children, ...rest }: TextProps): ReactElement => {
  return <MantineText {...rest}>{children}</MantineText>;
};

export const Tooltip = ({ children, ...rest }: TooltipProps): ReactElement => {
  return <MantineTooltip {...rest}>{children}</MantineTooltip>;
};
