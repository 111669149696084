import { ReactElement, useEffect, useState } from 'react';
import { Text } from '../../components/Typography/Typography';
import moment from 'moment';

interface TimeStampProps {
  resetTimeStamp: boolean;
}

export const TimeStamp = ({ resetTimeStamp }: TimeStampProps): ReactElement => {
  const [timeStamp, setTimestamp] = useState(moment());
  const [timeText, setTimeText] = useState(timeStamp.fromNow());

  useEffect(() => {
    const interval = setInterval(() => {
      if (resetTimeStamp) {
        setTimestamp(() => moment());
      }
      setTimeText(() => timeStamp.fromNow());
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <Text fz="xs" c="dimmed">
      {timeText}
    </Text>
  );
};
