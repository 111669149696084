export type ActionsType = 'SET' | 'ADD' | 'RESET';

export interface ActionProps {
  type: ActionsType;
  payload: any;
}

type StateProps = any;

export const usersReducer = (state: StateProps, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      return payload;
    case 'RESET':
      return payload;
    default:
      throw new Error('usersReducer issue');
  }
};
