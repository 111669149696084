import { ReactElement, useContext, useState } from 'react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { Button } from '../../../components/Buttons/Button/Button';
import { Select } from '../../../components/Inputs/Select/Select';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { TimeStamp } from '../../TimeStamp/TimeStamp';
import { AvailableLoads } from './AvailableLoads/AvailableLoads';
import { Text } from '../../../components/Typography/Typography';
import { createSavedSearch } from '../../../api/api';
import { AppContext } from '../../../context';
import moment from 'moment';
import { IoIosAlert } from 'react-icons/io';

interface TopBarProps {
  availableLoads: number;
  currentSearch: any;
  onSaveSearchClick: () => void;
  loadsFetching: boolean;
  onSort: any;
  odhOverRadiusLoads: boolean;
}

export const TopBar = ({
  availableLoads,
  currentSearch = {},
  onSaveSearchClick,
  loadsFetching,
  onSort,
  odhOverRadiusLoads
}: TopBarProps): ReactElement => {
  const [sortValue, setSortValue] = useState('odhLow');
  const { setSavedSearches } = useContext(AppContext);

  const findLoadsSortValues = [
    { label: 'Rate Per Mile', value: 'ratePerMile' },
    { label: 'Price Low', value: 'priceLow' },
    { label: 'Price High', value: 'priceHigh' },
    { label: 'Miles Low', value: 'milesLow' },
    { label: 'Miles High', value: 'milesHigh' },
    { label: 'ODH Low', value: 'odhLow' },
    { label: 'ODH High', value: 'odhHigh' },
    {
      label: 'DeadHead+RatePerMile',
      value: 'deadheadPlusRatePerMile'
    },
    {
      label: 'PU Date Accending',
      value: 'puDateAccending'
    }
  ];

  return (
    <Grid>
      <Col span={8}>
        <AvailableLoads length={availableLoads} showLoader={loadsFetching} />
        <TimeStamp resetTimeStamp={loadsFetching} />
        {odhOverRadiusLoads && (
          <Text mt="xs">
            <IoIosAlert
              style={{
                fontSize: 'x-large',
                verticalAlign: 'top',
                marginRight: '0.5rem'
              }}
            />
            Please pay attention that ODH in some of the provided loads exceeds
            the selected radius by more than 10%
          </Text>
        )}
      </Col>
      <Col span={4}>
        <Flex gap="md" justify="end" align="center">
          {/*
          <Button
            color="white"
            size="xs"
            onClick={() => {
              const time_from = moment(
                new Date(currentSearch.time_from)
              ).format('HH:mm');
              const time_to = moment(new Date(currentSearch.time_to)).format(
                'HH:mm'
              );

              const data = { ...currentSearch, time_from, time_to };

              createSavedSearch(data).then((response) => {
                if (response) {
                  setSavedSearches({
                    type: 'ADD',
                    payload: response
                  });
                  onSaveSearchClick();
                }
              });
            }}
            disabled={currentSearch?.isSaved}
            sx={(theme) => ({
              color: theme.colors.gray[6],
              backgroundColor: theme.colors.gray[2]
            })}
            rightIcon={
              currentSearch?.isSaved ? (
                <AiFillStar size={20} />
              ) : (
                <AiOutlineStar size={20} />
              )
            }
          >
            {!currentSearch?.isSaved && 'Save search'}
            {currentSearch?.isSaved && 'Search saved'}
          </Button>
*/}

          <Text fz="sm" c="dimmed">
            Sort by:
          </Text>
          <Select
            size="xs"
            value={sortValue}
            data={findLoadsSortValues}
            onChange={(value: string) => {
              setSortValue(value);
              onSort(value);
            }}
          />
        </Flex>
      </Col>
    </Grid>
  );
};
