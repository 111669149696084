import { createSlice } from '@reduxjs/toolkit';

export interface CounterState {
  openedPreviewLoads: { [key: string]: boolean };
  bookModal: { opened: boolean; data: any };
  biddingModal: { opened: boolean; data: any };
}

const initialState: CounterState = {
  openedPreviewLoads: {},
  bookModal: { opened: false, data: {} },
  biddingModal: { opened: false, data: {} }
};

export const rowSlice = createSlice({
  name: 'rowSlice',
  initialState,
  reducers: {
    setBookingModal: (state, action) => {
      const { opened, data } = action.payload;
      state.bookModal = { opened, data };
    },
    setBiddingModal: (state, action) => {
      const { opened, data } = action.payload;
      state.biddingModal = { opened, data };
    },
    setPreviewLoads: (state, action) => {
      const { stable_id, opened } = action.payload;
      state.openedPreviewLoads[stable_id] = opened;
    },
    resetPreviewLoads: (state) => {
      state.openedPreviewLoads = {};
    }
  }
});

export const {
  setBiddingModal,
  setBookingModal,
  setPreviewLoads,
  resetPreviewLoads
} = rowSlice.actions;

export default rowSlice.reducer;
