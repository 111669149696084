import { ReactElement, useContext } from 'react';
import { Container } from '../../components/Layout/Container/Container';
import { Header } from '../../modules/Header/Header';
import { AppContext, SearchMyLoadProps } from '../../context';
import { Space } from '../../components/Layout/Space/Space';
import { MyLoadsList } from '../../modules/MyLoadsList/MyLoadsList';
import { fetchFilteredOffers } from '../../api/api';
import { Filter } from '../../modules/MyLoadsList/Filter/Filter';
import moment from 'moment';

export const initialValues: SearchMyLoadProps = {
  statuses: ['ok'],
  ranges: [
    moment().subtract(1, 'day').utc().toDate(),
    moment().add(7, 'days').utc().toDate()
  ],
  offers: 'my',
  sources: []
};

export const MyLoadsPage = (): ReactElement => {
  const { offers, setOffers } = useContext(AppContext);

  const handleOnSearch = async (query: SearchMyLoadProps, sort: string) => {
    const formattedQuery: any = Object.assign({}, query); // copy object
    const start = formattedQuery.ranges[0];
    const end = formattedQuery.ranges[1];

    formattedQuery.ranges = [
      start ? moment(start).format('YYYY-MM-DD') : null,
      end ? moment(end).format('YYYY-MM-DD') : null
    ];

    await fetchFilteredOffers(formattedQuery)
      .then((offers) => {
        setOffers({
          type: 'SET',
          payload: offers || []
        });
      })
      .finally(() => {
        setOffers({
          type: 'SORT',
          payload: {
            sortValue: sort
          }
        });
      });
  };

  const handleSort = (value: string) => {
    setOffers({
      type: 'SORT',
      payload: {
        sortValue: value
      }
    });
  };

  return (
    <Container fluid p={0}>
      <Header />
      <Container
        fluid
        sx={{
          minHeight: 'calc(100vh - 80px)',
          backgroundColor: '#F4F6FC',
          boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
        }}
        p={0}
      >
        <Container size="xl">
          <Space h="xl" />
          <Filter
            initialValues={initialValues}
            onSearch={handleOnSearch}
            onSort={handleSort}
          />
          <MyLoadsList type="booked" offers={offers} currentSearch={null} />
        </Container>
      </Container>
    </Container>
  );
};
