import { ReactElement, useState } from 'react';
import { Paper } from '../../components/Miscellaneous/Paper/Paper';
import { SearchForm } from '../../forms/SearchForm/SearchForm';
import { SearchFormProps } from '../../context';
import { useForm } from '@mantine/form';
import { cloneDeep } from 'lodash';
import { Col } from '../../components/Layout/Grid/Grid';
import { Button } from '../../components/Buttons/Button/Button';
import { Collapse, Grid } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { DatePicker } from '@mantine/dates';

interface Props {
  initialSearchList: { list: SearchFormProps[] };
  onSearch: (data: any) => void;
  onReset: (data: any) => void;
}

export const SearchBar = ({
  initialSearchList,
  onSearch,
  onReset
}: Props): ReactElement => {
  const form = useForm({ initialValues: initialSearchList });

  const handleSubmit = (values: any) => {
    const newList = {
      list: values.list.map((e: any, i: number) => {
        return cloneDeep({
          ...values.list[0],
          origin: values.list[i].origin,
          destination: values.list[i].destination
        });
      })
    };

    if (newList.list[0].origin?.location?.address?.formatted_address) {
      onSearch(newList);
      setIsCollapseButtonVisible(true);
      toggle();
    }
  };

  const handleAppendNew = (index: number) => {
    if (form.values.list.length > 20) {
      return;
    }

    form.insertListItem('list', cloneDeep(form.values.list[index]));
  };

  const handleRemove = (index: number) => {
    form.removeListItem('list', index);
  };

  const [opened, { toggle }] = useDisclosure(true);
  const [isCollapseButtonVisible, setIsCollapseButtonVisible] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  function removeSuffix(str: string, suffix: string): string {
    if (str.endsWith(suffix)) {
      return str.slice(0, -suffix.length);
    }
    return str;
  }

  function anyCoordinatesNullOrEmpty(): boolean {
    for (const item of form.values.list) {
      const originCoordinates = item.origin.location.coordinates;

      if (!originCoordinates.latitude || !originCoordinates.longitude) {
        return true;
      }
    }
    return false;
  }

  function getCollapsedSearchList() {
    return (
      <ul
        style={{
          listStyleType: 'none',
          padding: 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: '0.25rem'
        }}
      >
        {form.values.list.map((item, index) => (
          <li key={index}>
            <Paper
              p="sm"
              withBorder
              shadow="md"
              style={{
                padding: '0 0.5rem 0 0.5rem'
              }}
            >
              {removeSuffix(
                item.origin.location.address.formatted_address,
                ', USA'
              )}
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'text-top',
                  padding: '0 0.5rem 0 0.5rem'
                }}
              >
                <FaLongArrowAltRight />
              </div>
              {removeSuffix(
                item.destination.location.address.formatted_address,
                ', USA'
              )}
            </Paper>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Paper p="xl" shadow="sm" style={{ padding: '7px' }}>
      <Collapse transitionTimingFunction="linear" in={opened}>
        {form.values.list.map(
          (initialValues: SearchFormProps, index: number) => {
            return (
              <Grid key={index} gutter="xs" align="end">
                <Col xl={10} span={12}>
                  <SearchForm
                    form={form}
                    index={index}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    onAppendNew={handleAppendNew}
                    onRemove={handleRemove}
                    isPrimary={index == 0}
                    searchClicked={searchClicked}
                    setSearchClicked={setSearchClicked}
                  />
                </Col>
                {index == 0 && (
                  <Col xl={2} span={12}>
                    {' '}
                    <Button
                      type="submit"
                      fullWidth
                      size="md"
                      h={47}
                      onClick={() => {
                        handleSubmit(form.values);
                        setSearchClicked(true);
                      }}
                      disabled={anyCoordinatesNullOrEmpty()}
                    >
                      Search loads
                    </Button>
                  </Col>
                )}
              </Grid>
            );
          }
        )}
      </Collapse>
      <Grid grow>
        <Col span={8}> {!opened && getCollapsedSearchList()}</Col>
        <Col span={2} style={{ alignItems: 'center', display: 'flex' }}>
          {!opened && (
            <DatePicker
              disabled={true}
              value={new Date(form.values.list[0].pickup_date)}
              firstDayOfWeek="sunday"
              weekendDays={[]}
              size="sm"
              radius={6}
            />
          )}
        </Col>
        <Col span={1} style={{ alignItems: 'center', display: 'flex' }}>
          {isCollapseButtonVisible && (
            <Button
              size="sm"
              color="white"
              onClick={toggle}
              rightIcon={opened ? <FaChevronUp /> : <FaChevronDown />}
              sx={(theme) => ({
                color: theme.colors.gray[6],
                backgroundColor: theme.colors.gray[1]
              })}
            >
              {opened ? 'Hide Search' : 'Edit Search'}
            </Button>
          )}
        </Col>
      </Grid>
    </Paper>
  );
};
