import { ReversedGeoProps, SearchFormProps } from '../context';
import request from './request';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000';
export const SUBSCRIBE_URL = `${API_URL}/subscribe`;
export const OFFERS_URL = `${API_URL}/offers`;
export const OFFER_URL = `${API_URL}/offers`;
export const USER_URL = `${API_URL}/user_details`;
export const LOAD_URL = `${API_URL}/loads`;
export const SOURCES_URL = `${API_URL}/sources`;
export const SEARCHES_URL = `${API_URL}/saved_searches`;
export const RECOMMENDATIONS_URL = `${API_URL}/loads/recommendations`;
export const DAT_LANES_SCORE_URL = `${API_URL}/lane_rates/dat`;
export const FREIGHT_WAVES_LANES_SCORE_URL = `${API_URL}/lane_rates/freight_waves`;
export const REVERSE_GEOCODE_URL = `${API_URL}/reverse_geocode`;
export const AUTO_BOOKING_FILTERS_URL = `${API_URL}/auto_booking_filters`;

export async function fetchLoads(
  filter: any,
  setLoads: any,
  wsSessionId: string
) {
  setLoads({
    type: 'SET',
    payload: []
  });

  const response = await request(SUBSCRIBE_URL, {
    method: 'POST',
    headers: { 'ws-session-id': wsSessionId },
    body: JSON.stringify({ filter })
  }).catch(() => {});

  return response?.session_token;
}

export async function autocomplete(input: string) {
  return await request(
    `${API_URL}/autocomplete?` + new URLSearchParams({ input })
  ).catch(() => {});
}

export async function geocode(placeId: any) {
  return await request(
    `${API_URL}/geocode?` + new URLSearchParams({ place_id: placeId })
  ).catch(() => {});
}

export async function reportLoadView(loadId: any) {
  await request(`${API_URL}/events/load_viewed/` + loadId, {
    method: 'POST'
  }).catch(() => {});
}

export async function submitOffer({
  load_id,
  load_stable_id,
  event_type,
  amount,
  form_values,
  wsSessionId
}: any) {
  await request(OFFERS_URL, {
    method: 'POST',
    headers: { 'ws-session-id': wsSessionId },
    body: JSON.stringify({
      load_id,
      load_stable_id,
      event_type,
      amount,
      form_data: { ...form_values }
    })
  }).catch(() => {});
}

export async function fetchFilteredOffers(filter: any) {
  return await request(`${OFFERS_URL}/filter`, {
    method: 'POST',
    body: JSON.stringify({ filter: filter })
  }).catch(() => {});
}

export async function fetchFilteredAutoBookingFilters(filter: any) {
  return await request(`${AUTO_BOOKING_FILTERS_URL}/filter`, {
    method: 'POST',
    body: JSON.stringify({ filter: filter })
  }).catch(() => {});
}

export async function confirmOffer(
  id: any,
  formValues: any,
  wsSessionId: string
) {
  return await request(`${OFFERS_URL}/${id}/confirm`, {
    method: 'POST',
    headers: { 'ws-session-id': wsSessionId },
    body: JSON.stringify({ ...formValues })
  }).catch(() => {});
}

export async function counterOffer(
  id: any,
  formValues: any,
  wsSessionId: string
) {
  return await request(`${OFFERS_URL}/${id}/counter`, {
    method: 'POST',
    headers: { 'ws-session-id': wsSessionId },
    body: JSON.stringify({ ...formValues })
  }).catch(() => {});
}

export async function rejectOffer(id: any, wsSessionId: string) {
  return await request(`${OFFERS_URL}/${id}/reject`, {
    method: 'POST',
    headers: { 'ws-session-id': wsSessionId }
  }).catch(() => {});
}

export async function getCurrentUser() {
  return await request(USER_URL).catch(() => {});
}

export async function getLoad(stable_id: any) {
  return await request(`${LOAD_URL}/${stable_id}`).catch(() => {});
}

export async function getSources() {
  return await request(SOURCES_URL).catch(() => {});
}

export async function createSavedSearch(search: SearchFormProps) {
  return await request(SEARCHES_URL, {
    method: 'POST',
    body: JSON.stringify({ search: { ...search } })
  }).catch(() => {});
}

export async function getSavedSearches() {
  return await request(SEARCHES_URL).catch(() => {});
}

export async function deleteSavedSearch(id: number) {
  await request(`${SEARCHES_URL}/${id}`, {
    method: 'DELETE',
    body: JSON.stringify({ id })
  }).catch(() => {});
}

export async function getRecommendedLoads() {
  return await request(RECOMMENDATIONS_URL).catch(() => {});
}

export async function reverseGeocode(
  latitude: number | null,
  longitude: number | null
) {
  return await request(
    `${REVERSE_GEOCODE_URL}?latitude=${latitude}&longitude=${longitude}`,
    {
      method: 'GET'
    }
  );
}

export async function getDatLaneScore(search: {
  origin: ReversedGeoProps;
  destination: ReversedGeoProps;
}) {
  return await request(
    `${DAT_LANES_SCORE_URL}?origin_city=${search.origin.city}` +
      `&origin_state=${search.origin.state}&origin_latitude=${search.origin.coordinates.lat}` +
      `&origin_longitude=${search.origin.coordinates.lng}&origin_zip=${search.origin.postal_code}` +
      `&destination_city=${search.destination.city}&destination_state=${search.destination.state}` +
      `&destination_latitude=${search.destination.coordinates.lat}&destination_longitude=${search.destination.coordinates.lng}` +
      `&destination_zip=${search.destination.postal_code}`,
    {
      method: 'GET'
    }
  );
}

export async function getFreightWavesLaneScore(
  origin_zip: number | null,
  destination_zip: number | null
) {
  return await request(
    `${FREIGHT_WAVES_LANES_SCORE_URL}?origin_zip=${origin_zip}&destination_zip=${destination_zip}`,
    {
      method: 'GET'
    }
  );
}

export async function updateSavedSearch(id: number, search: any) {
  return await request(`${SEARCHES_URL}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({ search: { ...search } })
  }).catch(() => {});
}

export async function exportOfferLoad(offerId: number) {
  return await request(`${OFFERS_URL}/${offerId}/export_load`, {
    method: 'POST'
  });
}

export async function getAutoBookingFilters() {
  return await request(AUTO_BOOKING_FILTERS_URL).catch(() => {});
}

export async function createAutoBookingFilter(autoBookingFilter: any) {
  return await request(AUTO_BOOKING_FILTERS_URL, {
    method: 'POST',
    body: JSON.stringify({ autoBookingFilter: { ...autoBookingFilter } })
  }).catch(() => {});
}

export async function updateAutoBookFilter(id: number, autoBookingFilter: any) {
  return await request(`${AUTO_BOOKING_FILTERS_URL}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      autoBookingFilter: { ...autoBookingFilter }
    })
  }).catch(() => {});
}

export async function deleteAutoBookFilter(id: number | undefined) {
  if (id) {
    return await request(`${AUTO_BOOKING_FILTERS_URL}/${id}`, {
      method: 'DELETE'
    }).catch(() => {});
  }
}
