import { ReactElement, useEffect, useState } from 'react';
import { Text } from '../../Typography/Typography';
import { Col, Grid } from '../../Layout/Grid/Grid';
import { Flex } from '../../Layout/Flex/Flex';
import { Paper } from '../../Miscellaneous/Paper/Paper';
import { DatePicker } from '../DatePicker/DatePicker';
import { UnstyledButton } from '../../Buttons/UnstyledButton/UnstyledButton';
import moment from 'moment';
import formats from '../../../enums/formats';

interface Props {
  value: string;
  onChange: (date: Date) => void;
}

export const CustomDatePicker = ({ value, onChange }: Props): ReactElement => {
  const [datePickerValue, setDatePickerValue] = useState<Date | null>(
    new Date(value)
  );

  const [days, setDays] = useState(
    [0, 1, 2, 3].map((index) => {
      const date = moment().add(index, 'day');

      return {
        index,
        date,
        isSelected: false
      };
    })
  );

  useEffect(() => {
    setDatePickerValue(new Date(value));
    const day = days.find((day) => day.date.isSame(moment(value), 'day'));
    day && setSelectedDay(day, true);
  }, [value]);

  const setSelectedDay = (selectedDay: any, isSelected: boolean) => {
    setDays((prevDays) =>
      prevDays.map((day) => ({
        ...day,
        isSelected: selectedDay.index === day.index && isSelected
      }))
    );
    onChange(selectedDay.date.format(formats.DATE.DAY.DEFAULT));
  };

  const renderDay = (day: any) => {
    const { index, date, isSelected } = day;

    const todayWeekday = date?.format(formats.DATE.WEEKDAY.DEFAULT);
    const today = date?.format(formats.DATE.MONTH.DEFAULT);

    return (
      <UnstyledButton
        mr="xs"
        mt="xs"
        mb="xs"
        key={index}
        onClick={() => {
          setSelectedDay(day, !isSelected);
        }}
      >
        <Paper
          p="xs"
          sx={(theme) => ({
            width: '100px',
            border: `1px solid ${
              isSelected ? theme.colors.electricGreen[9] : theme.colors.gray[0]
            }`
          })}
        >
          <Text fz="xs" c="dimmed">
            {todayWeekday}
          </Text>
          <Text fz="lg" c="gray" fw="bold">
            {today}
          </Text>
        </Paper>
      </UnstyledButton>
    );
  };

  const renderDays = () => <Flex>{days.map(renderDay)}</Flex>;

  return (
    <Grid align="center">
      <Col span="auto">{renderDays()}</Col>
      <Col span="content">
        <Text fz="xs" c="dimmed">
          Or
        </Text>
      </Col>
      <Col span="auto">
        <DatePicker
          weekendDays={[]}
          placeholder="Pick other dates"
          firstDayOfWeek="sunday"
          variant="filled"
          size="md"
          value={datePickerValue}
          onChange={(date: Date) => {
            setDays((prevDays) =>
              prevDays.map((day) => ({
                ...day,
                isSelected: false
              }))
            );
            onChange(date);
          }}
          clearable={false}
        />
      </Col>
    </Grid>
  );
};
