import React, { ReactElement } from 'react';
import {
  NumberInputProps as MantineNumberInputProps,
  NumberInput as MantineNumberInput
} from '@mantine/core';

interface NumberInputProps extends MantineNumberInputProps {
  allowIntegersOnly?: boolean;
  allowPositiveOnly?: boolean;
}

export const NumberInput = ({
  allowIntegersOnly,
  allowPositiveOnly,
  ...props
}: NumberInputProps): ReactElement => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (allowIntegersOnly && (event.key === '.' || event.key === ',')) {
      event.preventDefault();
    }

    if (allowPositiveOnly && event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <MantineNumberInput
      {...props}
      onKeyDown={
        allowIntegersOnly || allowPositiveOnly ? handleKeyDown : undefined
      }
    />
  );
};
