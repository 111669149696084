import { ReactElement, useEffect, useState } from 'react';
import { Text } from '../../Typography/Typography';
import { Col, Grid } from '../../Layout/Grid/Grid';
import { Flex } from '../../Layout/Flex/Flex';
import { Paper } from '../../Miscellaneous/Paper/Paper';
import { UnstyledButton } from '../../Buttons/UnstyledButton/UnstyledButton';
import { Center } from '../../Layout/Center/Center';

interface Props {
  value: number[];
  onChange: (value: number[]) => void;
}

export const EquipmentSizeSelector = ({
  value: selectedValues,
  onChange
}: Props): ReactElement => {
  const [sizes, setSizes] = useState([
    { label: '53`', value: 53 },
    { label: '48`', value: 48 },
    { label: '45`', value: 45 },
    { label: '40`', value: 40 },
    { label: '32`', value: 32 },
    { label: '20`', value: 20 }
  ]);

  useEffect(() => {
    setSelectedSizes(
      selectedValues.length > 0 ? Math.max(...selectedValues) : 0
    );
  }, [selectedValues]);

  const setSelectedSizes = (selectedSize: number) => {
    setSizes((prevSizes) =>
      prevSizes.map((size) => ({
        ...size,
        isSelected: selectedSize >= size.value
      }))
    );
  };

  const getSelectedValues = (selectedSize: number) => {
    return sizes
      .filter((size) => size.value <= selectedSize)
      .map((size) => size.value);
  };

  const renderSize = (
    { value, isSelected }: { value: number; isSelected?: boolean },
    index: number
  ) => {
    return (
      <UnstyledButton
        mr="xs"
        mt="xs"
        mb="xs"
        key={index}
        onClick={() => {
          onChange(getSelectedValues(value));
        }}
      >
        <Paper
          p="xs"
          sx={(theme) => ({
            width: '100px',
            border: `1px solid ${
              isSelected ? theme.colors.electricGreen[9] : theme.colors.gray[0]
            }`
          })}
        >
          <Center>
            <Text fz="lg" c="gray" fw="bold">
              {value}`
            </Text>
          </Center>
        </Paper>
      </UnstyledButton>
    );
  };

  const renderSizes = () => <Flex>{sizes.map(renderSize)}</Flex>;

  return (
    <Grid align="center">
      <Col span="auto">{renderSizes()}</Col>
    </Grid>
  );
};
