import { ReactElement, forwardRef } from 'react';
import { createStyles } from '@mantine/core';
import { Flex } from '../../Layout/Flex/Flex';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { Select } from '../Select/Select';

interface Props {
  value: string;
  rangeName: string;
  radius: number;
  nothingFound: string;
  data: any;
  onRangeChange: (name: string, value: number) => void;
  disabled?: boolean;
  disableRadius?: boolean;
  validInput?: boolean;
}

const useStyles = createStyles(() => ({
  root: { width: '100%' },
  input: { borderWidth: 0 }
}));

type ItemProps = {
  value: string;
  name: string;
};

const CustomItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ name, value, ...others }: ItemProps, ref) => {
    const style = name === 'zip_code' ? { fontWeight: 'bold' } : {};

    return (
      <div ref={ref} style={style} {...others}>
        {value}
      </div>
    );
  }
);

export default CustomItem;

export const AutocompleteWithSelect = ({
  rangeName,
  radius,
  nothingFound,
  data,
  onRangeChange,
  disabled,
  disableRadius,
  validInput,
  ...props
}: Props): ReactElement => {
  const { classes } = useStyles();

  return (
    <Flex
      justify="center"
      align="center"
      p={2}
      sx={{
        width: '100%',
        outline: validInput ? `2px solid #59ba9d93` : `1px solid #eee`,
        borderRadius: '6px'
      }}
    >
      <Autocomplete
        data={data}
        nothingFound={nothingFound}
        size="md"
        classNames={{
          root: classes.root,
          input: classes.input
        }}
        disabled={disabled}
        itemComponent={CustomItem}
        {...props}
      />
      <Select
        variant="filled"
        value={radius.toString()}
        disabled={disableRadius}
        data={[
          { label: '25 miles', value: '25' },
          { label: '50 miles', value: '50' },
          { label: '75 miles', value: '75' },
          { label: '100 miles', value: '100' },
          { label: '125 miles', value: '125' },
          { label: '150 miles', value: '150' },
          { label: '175 miles', value: '175' },
          { label: '200 miles', value: '200' },
          { label: '225 miles', value: '225' },
          { label: '250 miles', value: '250' },
          { label: '275 miles', value: '275' },
          { label: '300 miles', value: '300' },
          { label: '325 miles', value: '325' },
          { label: '350 miles', value: '350' },
          { label: '375 miles', value: '375' },
          { label: '400 miles', value: '400' }
        ]}
        mr={3}
        size="sm"
        onChange={(value: string) => {
          onRangeChange(rangeName, Number(value));
        }}
        sx={(theme) => ({
          width: 'auto',
          maxWidth: '110px'
        })}
      />
    </Flex>
  );
};
