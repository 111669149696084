import { ReactElement, useState } from 'react';
import { Text } from '../../../../components/Typography/Typography';
import { Col, Grid } from '../../../../components/Layout/Grid/Grid';
import { Anchor, Button } from '@mantine/core';
import { TbPackgeExport } from 'react-icons/tb';
import { exportOfferLoad } from '../../../../api/api';
import { Loader } from '../../../../components/Loaders/Loader';
import { displayNotification } from '../../../../utils/utils';
import { FaEye } from 'react-icons/fa';

export const Information = ({ load, offer }: any): ReactElement => {
  const [loadsExporting, setIsloadsExporting] = useState(false);
  const handleExportClick = async () => {
    try {
      setIsloadsExporting(true);

      const result = await exportOfferLoad(offer.id);

      const link = result.open_road_link || result.load_preview_url;

      if (result.load_preview_url) {
        displayNotification({
          title: 'Load export failed!',
          message:
            'Automatic Load export to Open Road failed. Email notification has been sent to Data Entry team.',
          type: 'alert',
          autoClose: false
        });
        load.meta.open_road_preview_link = result.load_preview_url;
        window.open(link, '_blank', 'noopener,noreferrer');
      } else if (result.open_road_link) {
        displayNotification({
          title: 'Load export success!',
          message: `Load #${load.load_id} successfully exported`,
          type: 'message',
          autoClose: false
        });
        load.meta.open_road_link = result.open_road_link;
        window.open(link, '_blank', 'noopener,noreferrer');
      } else {
        displayNotification({
          title: 'Load export failed!',
          message: `Load #${load.load_id} export critical error!`,
          type: 'alert',
          autoClose: false
        });
      }
    } catch (error: any) {
      displayNotification({
        title: 'Load export error!',
        message: `An error occurred during the export: ${error.message}`,
        type: 'alert',
        autoClose: false
      });
    } finally {
      setIsloadsExporting(false);
    }
  };

  return (
    <Grid>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Equipment
        </Text>
        <Text fz="lg" fw="bold">
          {load.equipment_data.types?.join(', ') || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Commodity
        </Text>
        <Text fz="lg" fw="bold">
          {load.commodities?.join(', ') || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Load type
        </Text>
        <Text fz="lg" fw="bold">
          {load.load_type || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Load requirements
        </Text>
        <Text fz="sm" fw="bold">
          {'-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Equipment length
        </Text>
        <Text fz="lg" fw="bold">
          {load.equipment_data?.length || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Cargo value
        </Text>
        <Text fz="lg" fw="bold">
          {load.cargo_value || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Piece type
        </Text>
        <Text fz="lg" fw="bold">
          {load.packaging_type || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Specialty EQ
        </Text>
        <Text fz="lg" fw="bold">
          {load.speciality_equipment || 'None'}
        </Text>
      </Col>
      <Col span={3}>
        {!load.meta.open_road_link ? (
          load.meta.open_road_preview_link ? (
            <Text fw="bold">
              {'Load export failed. Preview Load '}
              <Anchor
                href={load.meta.open_road_preview_link}
                target="_blank"
                color="red"
                rel="noopener noreferrer"
              >
                here
              </Anchor>
            </Text>
          ) : (
            <Button
              color="white"
              size="sm"
              sx={(theme) => ({
                color: theme.colors.gray[6],
                backgroundColor: theme.colors.gray[1]
              })}
              onClick={handleExportClick}
            >
              Export Load to Open Road
              {loadsExporting ? (
                <Loader
                  size="sm"
                  variant="dots"
                  style={{ marginLeft: '0.5rem' }}
                />
              ) : (
                <TbPackgeExport
                  style={{ marginLeft: '0.5rem', fontSize: '1.5rem' }}
                />
              )}
            </Button>
          )
        ) : (
          <Button
            component="a"
            size="sm"
            href={load.meta.open_road_link}
            target="_blank"
            rel="noopener noreferrer"
            sx={(theme) => ({
              color: theme.colors.gray[6],
              backgroundColor: theme.colors.gray[1]
            })}
          >
            View Load on Open Road
            <FaEye style={{ marginLeft: '0.5rem', fontSize: '1.25rem' }} />
          </Button>
        )}
      </Col>
    </Grid>
  );
};
