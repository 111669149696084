import { ReactElement, useState } from 'react';
import { FilterModal } from '../../../modals/FilterModal/FilterModal';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { SearchFormProps } from '../../../context';
import { useForm } from '@mantine/form';
import { merge } from 'lodash';
import { Select } from '../../../components/Inputs/Select/Select';
import { Checkbox } from '../../../components/Inputs/Checkbox/Checkbox';
import { Text } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { Group } from '../../../components/Layout/Group/Group';

interface Props {
  initialValues: any;
  onSearch: (data: any) => void;
  onReset: (data: any) => void;
}

export const MyLoadsOffersFilter = ({
  initialValues,
  onSearch,
  onReset
}: Props): ReactElement => {
  const [sortValue, setSortValue] = useState('status');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchFormValues, setSearchFormValues] = useState<any>({});

  const myLoadsAndOffersSortValues = [
    { label: 'Status', value: 'status' },
    { label: 'Pickup date', value: 'pickupDate' },
    { label: 'Delivery date', value: 'deliveryDate' },
    { label: 'RPM', value: 'rpm' },
    {
      label: 'User',
      value: 'user'
    }
  ];

  const form = useForm({
    initialValues
  });

  const handleFilterModal = (values: any) => {
    if (values.origin?.location?.address?.formatted_address) {
      setSearchFormValues(values);
      setIsFilterModalOpen(true);
    }
  };

  const handleSubmit = (values: any) => {
    setIsFilterModalOpen(false);
    if (values.origin?.location?.address?.formatted_address) {
      onSearch(values);
    }
  };

  const [value, setValue] = useState<DateRangePickerValue>([
    new Date(2021, 11, 1),
    new Date(2021, 11, 5)
  ]);

  return (
    <Grid align="center">
      <Col span={6}>
        <Group>
          <DateRangePicker
            label={
              <Text size="sm" c="dimmed">
                Pickup and delivery dates
              </Text>
            }
            placeholder="Pick dates range"
            value={value}
            onChange={setValue}
            size="lg"
          />
          <Select
            label={
              <Text size="sm" c="dimmed">
                Status
              </Text>
            }
            value={'booked'}
            data={[{ label: 'Booked', value: 'booked' }]}
            size="lg"
          />
        </Group>
      </Col>
      <Col span={6}>
        <Flex gap="md" justify="end" align="center">
          <Checkbox
            label={
              <Text size="md" c="dimmed">
                Whole company loads
              </Text>
            }
            value="FTL"
            checked={true}
            onChange={(event) => {}}
            radius="sm"
            mt={30}
          />
          <Select
            label={
              <Text size="sm" c="dimmed">
                Sort by
              </Text>
            }
            size="lg"
            value={sortValue}
            data={myLoadsAndOffersSortValues}
            onChange={(value: string) => {
              // setSortValue(value);
              // onSort(value);
            }}
          />
        </Flex>
      </Col>
    </Grid>
    // <Paper p="xl" shadow="sm">
    //   <DateRangePicker
    //     type="range"
    //     label="Pick dates range"
    //     placeholder="Pick dates range"
    //     onChange={() => {}}
    //     mx="auto"
    //     maw={400}
    //   />
    //   <Select
    //     data={[{ label: 'Booked', value: 1 }]}
    //     {...form.getInputProps('stops')}
    //     size="md"
    //     mb="xs"
    //   />
    //   <Checkbox
    //     value="FTL"
    //     label="Whole company loads"
    //     checked={true}
    //     onChange={(event) => {}}
    //     radius="sm"
    //   />
    //   <Text fz="sm" c="dimmed">
    //     Sort by:
    //   </Text>
    //   <Select
    //     size="xs"
    //     value={'deadhead'}
    //     data={[
    //       { label: 'Deadhead', value: 'deadhead' },
    //       { label: 'Rate Per Mile', value: 'ratePerMile' },
    //       { label: 'Price Low', value: 'priceLow' },
    //       { label: 'Price High', value: 'priceHigh' },
    //       {
    //         label: 'DeadHead+RatePerMile',
    //         value: 'deadheadPlusRatePerMile'
    //       },
    //       {
    //         label: 'PU Date Accending',
    //         value: 'puDateAccending'
    //       }
    //     ]}
    //     onChange={(value: string) => {}}
    //   />
    //   {/* <SearchForm
    //     form={form}
    //     handleFilterModal={handleFilterModal}
    //     onSubmit={handleSubmit}
    //   />
    //   {isFilterModalOpen && (
    //     <FilterModal
    //       form={form}
    //       isFilterModalOpen={isFilterModalOpen}
    //       setIsFilterModalOpen={setIsFilterModalOpen}
    //       onSubmit={handleSubmit}
    //       onReset={onReset}
    //     />
    //   )} */}
    // </Paper>
  );
};
