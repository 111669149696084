import { ReactElement, useState } from 'react';
import { Paper } from '../../components/Miscellaneous/Paper/Paper';
import { SearchByCityForm } from '../../forms/SearchByCityForm/SearchByCityForm';
import { useForm } from '@mantine/form';
import { SearchFormProps, ReversedGeoProps } from '../../context';
import {
  getDatLaneScore,
  getFreightWavesLaneScore,
  reverseGeocode
} from '../../api/api';

export const initialValues: any = {
  origin: {
    location: {
      coordinates: {
        longitude: '',
        latitude: ''
      },
      address: {
        formatted_address: ''
      }
    }
  },
  destination: {
    location: {
      coordinates: {
        longitude: '',
        latitude: ''
      },
      address: {
        formatted_address: ''
      }
    }
  }
};

export const SearchByCityBar = ({
  onFetchingChange: setFetching,
  onResults: setResults
}: any): ReactElement => {
  const handleSearch = async (v: SearchFormProps) => {
    let results: void | any[];
    const { latitude: origin_lat, longitude: origin_lon } =
      v.origin.location.coordinates;
    const { latitude: destination_lat, longitude: destination_lon } =
      v.destination.location.coordinates;

    setFetching(true);

    try {
      const [originGeo, destinationGeo]: void | ReversedGeoProps[] =
        await Promise.all([
          reverseGeocode(origin_lat, origin_lon),
          reverseGeocode(destination_lat, destination_lon)
        ]);

      results = await Promise.all([
        getFreightWavesLaneScore(
          originGeo.postal_code,
          destinationGeo.postal_code
        )
      ]);
    } catch (e) {
      setFetching(false);
    }

    setResults({ freightWaves: results?.[0] });
    setFetching(false);
  };

  const form = useForm({
    initialValues
  });

  const handleSubmit = (values: any) => {
    if (values.origin?.location?.address?.formatted_address) {
      return handleSearch(values);
    }
  };

  return (
    <Paper p="xl" shadow="sm">
      <SearchByCityForm form={form} onSubmit={handleSubmit} />
    </Paper>
  );
};
