import { ReactElement } from 'react';
import type { BoxProps } from '@mantine/core';
import { Box as MantineBox } from '@mantine/core';

interface Props extends BoxProps {
  onClick?: () => void;
}

export const Box = ({ children, ...props }: Props): ReactElement => {
  return <MantineBox {...props}>{children}</MantineBox>;
};
