import { ReactElement, useState } from 'react';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { Grid, Col } from '../../components/Layout/Grid/Grid';
import { Button } from '../../components/Buttons/Button/Button';
import { TextInput } from '../../components/Inputs/TextInput/TextInput';
import { NumberInput } from '../../components/Inputs/NumberInput/NumberInput';
import { Stepper, Step } from '../../components/Navigation/Stepper/Stepper';
import { Title, Text } from '../../components/Typography/Typography';
import { Loader } from '../../components/Loaders/Loader';
import { Flex } from '../../components/Layout/Flex/Flex';
import { AiOutlineCheck } from 'react-icons/ai';
import { COMPLIANCE_TYPES } from '../../enums/load';

interface Props {
  load: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export const MakeOfferForm = ({
  load,
  onSubmit,
  onCancel
}: Props): ReactElement => {
  const form = useForm({
    initialValues: {
      offer: ''
    },
    validate: {
      offer: (value) => (!value ? 'Required' : null)
    }
  });
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [isApproved, setIsApproved] = useState(true);

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const navigateToLoadsListPage = () => {
    navigate('/my-loads', { state: { isReturnFromDetailsPage: true } });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      {/* <Stepper
        active={active}
        onStepClick={setActive}
        breakpoint="xs"
        size="xs"
        mt="xl"
      >
        <Step label="Submit" allowStepSelect={false}> */}
      <Grid gutter="xs" align="end">
        <Col span={12}>
          <Flex align="center" gap="xl">
            <NumberInput
              label={
                <Text size="sm" c="dimmed">
                  Your offer
                </Text>
              }
              icon="$"
              type="number"
              {...form.getInputProps('offer')}
              size="md"
              allowIntegersOnly
              mt="md"
            />
            <Text mt="xl" size="sm" c="dimmed">
              Please insert your offer amount
              {load?.bidding?.compliance_data?.type ==
                COMPLIANCE_TYPES.INDIVIDUAL && (
                <>
                  &nbsp; & &nbsp;
                  <a
                    className={
                      load.bidding.compliance_data.required
                        ? 'compliance individual-link'
                        : ''
                    }
                    target={'_blank'}
                    href={load.bidding.compliance_data.link}
                  >
                    Fill compliance link
                  </a>
                </>
              )}
            </Text>
          </Flex>
        </Col>
        <Col sm={2} mt="xl">
          <Button
            variant="white"
            color="black"
            onClick={onCancel}
            fullWidth
            size="md"
          >
            Cancel
          </Button>
        </Col>
        <Col sm={10}>
          <Button fullWidth type="submit" size="md">
            Submit offer
          </Button>
        </Col>
      </Grid>
      {/* </Step>
        <Step label="Review" allowStepSelect={false}>
          <Grid gutter="xs" align="end">
            <Col span={12} mt="xl">
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  gap="xs"
                >
                  <Loader size="md" variant="dots" />
                  <Title order={5}>Reviewing your offer</Title>
                  <Text size="sm" c="dimmed">
                    It might take some time to get a response. Please be
                    patient.
                  </Text>
                </Flex>
              </Col>
            </Col>
            <Col span={12} mt="xl">
              <Button
                variant="white"
                color="black"
                size="md"
                onClick={onCancel}
                fullWidth
              >
                Cancel
              </Button>
            </Col>
          </Grid>
        </Step>
        <Step label="Response" allowStepSelect={false}>
          {isApproved && (
            <Grid gutter="xs" align="end">
              <Col span={12} mt="xl">
                <Col span={12}>
                  <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    gap="xs"
                  >
                    <AiOutlineCheck size={20} />
                    <Title order={5}>
                      Congratulations! Your offer has been approved!
                    </Title>
                    <NumberInput
                      icon="$"
                      type="number"
                      {...form.getInputProps('offer')}
                      size="md"
                      disabled
                    />
                    <Text size="sm" c="dimmed">
                      Please continue and complete booking of approved offer
                    </Text>
                  </Flex>
                </Col>
              </Col>
              <Col sm={2} mt="xl">
                <Button
                  variant="white"
                  color="black"
                  size="md"
                  onClick={onCancel}
                  fullWidth
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={10}>
                <Button
                  size="md"
                  fullWidth
                  onClick={() => {
                    nextStep();
                  }}
                >
                  Book offer
                </Button>
              </Col>
            </Grid>
          )}
          {!isApproved && (
            <Grid gutter="xs" align="end">
              <Col span={12} mt="xl">
                <Col span={12}>
                  <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    gap="xs"
                  >
                    <Title order={5}>Counter offer</Title>
                    <TextInput
                      label="Your offer"
                      icon="$"
                      type="number"
                      {...form.getInputProps('offer')}
                      size="lg"
                    />
                  </Flex>
                </Col>
              </Col>
              <Col sm={2} mt="xl">
                <Button
                  variant="white"
                  color="black"
                  size="md"
                  onClick={onCancel}
                  fullWidth
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={10}>
                <Button
                  size="md"
                  fullWidth
                  onClick={() => {
                    nextStep();
                  }}
                >
                  Submit counter offer
                </Button>
              </Col>
            </Grid>
          )}
        </Step>
        <Step label="Book" allowStepSelect={false}>
          <Grid gutter="xs" align="end">
            <Col span={12} mt="xl">
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  gap="xs"
                >
                  <AiOutlineCheck size={20} />
                  <Title order={5}>Thank you!</Title>
                  <Text size="sm" c="dimmed">
                    Your load was successfully booked. Please find a load number
                    below.
                  </Text>
                  <Text size="sm" c="dimmed">
                    Rate Confirmation information will come separatelly.
                  </Text>
                  <Text size="sm" c="dimmed" fw="bold">
                    Po Load Number: #{load.load_id}
                  </Text>
                  <Text size="sm" c="dimmed">
                    You can check your loads information anytime in section "My
                    Loads"
                  </Text>
                </Flex>
              </Col>
            </Col>
            <Col sm={2} mt="xl">
              <Button
                variant="white"
                color="black"
                size="md"
                onClick={onCancel}
                fullWidth
              >
                Cancel
              </Button>
            </Col>
            <Col sm={10}>
              <Button
                type="submit"
                size="md"
                fullWidth
                onClick={navigateToLoadsListPage}
              >
                My loads
              </Button>
            </Col>
          </Grid>
        </Step> */}
      {/* <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed> */}
      {/* </Stepper> */}
    </form>
  );
};
