import { ReactElement, useEffect, useState } from 'react';
// import { FilterModal } from '../../../modals/FilterModal/FilterModal';
// import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { SearchMyLoadProps } from '../../../context';
import { useForm } from '@mantine/form';
import { merge } from 'lodash';
import { Select } from '../../../components/Inputs/Select/Select';
// import { Checkbox } from '../../../components/Inputs/Checkbox/Checkbox';
import { Text } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { DateRangePicker } from '@mantine/dates';
import { Group } from '../../../components/Layout/Group/Group';
import { MultiSelect } from '../../../components/Inputs/MultiSelect/MultiSelect';
import { Button } from '../../../components/Buttons/Button/Button';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';

interface Props {
  initialValues: any;
  onSearch: (data: SearchMyLoadProps, sort: string) => void;
  onSort: (data: string) => void;
}

export const Filter = ({
  initialValues,
  onSearch,
  onSort
}: Props): ReactElement => {
  const [statusSortValue, setStatusSortValue] = useState('all');
  const [userOffersSortValue, setUserOffersStatusSortValue] = useState('all');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchFormValues, setSearchFormValues] = useState<any>({});

  const statusSortValues = [
    { label: 'All', value: 'all' },
    { label: 'At Delivery', value: 'atDelivery' },
    { label: 'Booked', value: 'booked' },
    { label: 'Cancelled', value: 'cancelled' },
    {
      label: 'Delivered',
      value: 'delivered'
    },
    {
      label: 'Loaded',
      value: 'loaded'
    },
    {
      label: 'Picked up',
      value: 'pickedUp'
    },
    {
      label: 'Unavailable',
      value: 'unavailable'
    },
    {
      label: 'Unloaded',
      value: 'unloaded'
    }
  ];

  const userOffersSortValues = [
    { label: 'All', value: 'all' },
    { label: 'Arrive Logistics', value: 'arriveLogistics' },
    { label: 'Echo', value: 'echo' },
    { label: 'Coyote', value: 'coyote' },
    {
      label: 'Uber',
      value: 'uber'
    },
    {
      label: 'Newtrul',
      value: 'newtrul'
    },
    {
      label: 'CH Robinson',
      value: 'chRobinson'
    },
    {
      label: 'Loadsmart',
      value: 'loadsmart'
    }
  ];

  const sortByValues = [
    { label: 'User', value: 'user' },
    { label: 'Deadhead', value: 'deadhead' },
    { label: 'Rate Per Mile', value: 'rpm' },
    { label: 'Rate Per Mile (Deadhead)', value: 'rpmDeadhead' },
    {
      label: 'Price Low',
      value: 'priceLow'
    },
    {
      label: 'Price High',
      value: 'priceHigh'
    },
    {
      label: 'PU Date Accending',
      value: 'puDateAccending'
    },
    {
      label: 'PU Date Descending',
      value: 'puDateDescending'
    },
    {
      label: 'DEL Date Accending',
      value: 'delDateAccending'
    },
    {
      label: 'DEL Date Descending',
      value: 'delDateDescending'
    },
    {
      label: 'Driver ID',
      value: 'driverID'
    },
    {
      label: 'Trailer ID',
      value: 'trailerID'
    },
    {
      label: 'Destination Capacity Score',
      value: 'destinationCapacityScore'
    },
    {
      label: 'Load Requirements',
      value: 'loadRequirements'
    },
    {
      label: 'Source',
      value: 'source'
    }
  ];

  const sourceSortValues = [
    {
      label: 'Echo global',
      value: 'echo_global'
    },
    {
      label: 'Coyote',
      value: 'coyote'
    },
    {
      label: 'Uber',
      value: 'uber'
    },
    {
      label: 'Newtrul',
      value: 'newtrul'
    },
    {
      label: 'CH Robinson',
      value: 'ch_robinson'
    },
    {
      label: 'Loadsmart',
      value: 'loadsmart'
    },
    {
      label: 'DAT',
      value: 'dat'
    }
  ];

  const offersValues = [
    { label: 'My Loads', value: 'my' },
    { label: 'Company Loads', value: 'company' }
  ];

  /*
  const handleFilterModal = (values: any) => {
    if (values.origin?.location?.address?.formatted_address) {
      setSearchFormValues(values);
      setIsFilterModalOpen(true);
    }
  };

  const handleSubmit = (values: any) => {
    setIsFilterModalOpen(false);
    if (values.origin?.location?.address?.formatted_address) {
      onSearch(values);
    }
  };
  */

  const setMyLoadsSessionFilter = (filter: SearchMyLoadProps) => {
    sessionStorage.setItem('myLoadsFilter', JSON.stringify(filter));
  };

  const setMyLoadsSessionSort = (sort: string) => {
    sessionStorage.setItem('myLoadsSort', sort);
  };

  const getMyLoadsSessionFilter = () => {
    const sessionFilter = sessionStorage.getItem('myLoadsFilter');

    if (sessionFilter) {
      const filter = JSON.parse(sessionFilter);
      const start = filter.ranges[0];
      const end = filter.ranges[1];

      filter.ranges = [
        start ? new Date(start) : null,
        end ? new Date(end) : null
      ];

      return filter as SearchMyLoadProps;
    }

    return initialValues;
  };

  const getMyLoadsSessionSort = () => {
    return sessionStorage.getItem('myLoadsSort') || 'user';
  };

  const [filter, setFilter] = useState<SearchMyLoadProps>(
    getMyLoadsSessionFilter()
  );

  const [sortByValue, setSortByValue] = useState(getMyLoadsSessionSort());

  useEffect(() => {
    onSearch(filter, sortByValue);
  }, []);

  const form = useForm({
    initialValues: {
      ...filter
    }
  });

  return (
    <form
      onSubmit={form.onSubmit((e) => {
        onSearch(e, sortByValue);
        setMyLoadsSessionFilter(e);
      })}
    >
      <Grid>
        <Paper w="100%" ml={5} mr={5} p="lg" shadow="sm">
          <Col>
            <Group>
              <DateRangePicker
                label={
                  <Text size="sm" c="dimmed">
                    Pickup and delivery dates
                  </Text>
                }
                placeholder="Pick dates range"
                {...form.getInputProps('ranges')}
                styles={{
                  input: {
                    width: '200px'
                  }
                }}
                firstDayOfWeek="sunday"
              />
              <MultiSelect
                label={
                  <Text size="sm" c="dimmed">
                    Source
                  </Text>
                }
                placeholder={'Pick sources'}
                data={sourceSortValues}
                {...form.getInputProps('sources')}
              />
              <Select
                label={
                  <Text size="sm" c="dimmed">
                    Loads
                  </Text>
                }
                data={offersValues}
                {...form.getInputProps('offers')}
                styles={{
                  input: {
                    width: '200px'
                  }
                }}
              />
              <Button
                type="submit"
                size="md"
                h={36}
                w={150}
                mt="sm"
                ml="auto"
                style={{ marginTop: '24px' }}
              >
                Search
              </Button>
            </Group>
          </Col>
        </Paper>
        <Paper
          w="100%"
          ml={5}
          mr={5}
          mt={8}
          style={{ background: 'transparent' }}
        >
          <Col>
            {/* <Checkbox
              label={
                <Text size="sm" c="dimmed">
                  Whole company loads
                </Text>
              }
              value="FTL"
              checked={true}
              onChange={(event) => {}}
              radius="sm"
              mt={30}
            /> */}
            <Flex gap="md" justify="end" align="center">
              <Text size="xs" c="dimmed">
                Sort by
              </Text>
              <Select
                value={sortByValue}
                data={sortByValues}
                w={150}
                mr={20}
                size="xs"
                onChange={(value: string) => {
                  setSortByValue(value);
                  setMyLoadsSessionSort(value);
                  onSort(value);
                }}
              />
            </Flex>
          </Col>
        </Paper>
      </Grid>
    </form>
    // <Paper p="xl" shadow="sm">
    //   <DateRangePicker
    //     type="range"
    //     label="Pick dates range"
    //     placeholder="Pick dates range"
    //     onChange={() => {}}
    //     mx="auto"
    //     maw={400}
    //   />
    //   <Select
    //     data={[{ label: 'Booked', value: 1 }]}
    //     {...form.getInputProps('stops')}
    //     size="md"
    //     mb="xs"
    //   />
    //   <Checkbox
    //     value="FTL"
    //     label="Whole company loads"
    //     checked={true}
    //     onChange={(event) => {}}
    //     radius="sm"
    //   />
    //   <Text fz="sm" c="dimmed">
    //     Sort by:
    //   </Text>
    //   <Select
    //     size="xs"
    //     value={'deadhead'}
    //     data={[
    //       { label: 'Deadhead', value: 'deadhead' },
    //       { label: 'Rate Per Mile', value: 'ratePerMile' },
    //       { label: 'Price Low', value: 'priceLow' },
    //       { label: 'Price High', value: 'priceHigh' },
    //       {
    //         label: 'DeadHead+RatePerMile',
    //         value: 'deadheadPlusRatePerMile'
    //       },
    //       {
    //         label: 'PU Date Accending',
    //         value: 'puDateAccending'
    //       }
    //     ]}
    //     onChange={(value: string) => {}}
    //   />
    //   {/* <SearchForm
    //     form={form}
    //     handleFilterModal={handleFilterModal}
    //     onSubmit={handleSubmit}
    //   />
    //   {isFilterModalOpen && (
    //     <FilterModal
    //       form={form}
    //       isFilterModalOpen={isFilterModalOpen}
    //       setIsFilterModalOpen={setIsFilterModalOpen}
    //       onSubmit={handleSubmit}
    //       onReset={onReset}
    //     />
    //   )} */}
    // </Paper>
  );
};
