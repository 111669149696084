import { ReactElement, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiRadioCircle } from 'react-icons/bi';
import {
  FaMapMarkerAlt,
  FaSearch,
  FaCog,
  FaTrash,
  FaPauseCircle
} from 'react-icons/fa';
import { BsPersonCircle, BsFillPlayCircleFill } from 'react-icons/bs';
import { SiProbot } from 'react-icons/si';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Text } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { ActionIcon } from '../../../components/Buttons/ActionIcon/ActionIcon';
import { Space } from '../../../components/Layout/Space/Space';
import { useTheme } from '../../../hooks/useTheme/useTheme';
import { useMediaQuery } from '../../../hooks/useMediaQuery/useMediaQuery';
import { AppContext } from '../../../context';
import { deleteSavedSearch, updateSavedSearch } from '../../../api/api';
import { SavedSearchModal } from '../SavedSearchModal';

export const Row = ({ savedSearch, isLastRow }: any): ReactElement => {
  const { search }: any = savedSearch;
  const [active, setActive] = useState(search.active);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xl}px)`);
  const navigate = useNavigate();
  const { setLoads, setSavedSearches } = useContext(AppContext);
  const [modalOpened, setModalOpened] = useState(false);

  const navigateToFindLoadsPage = () => {
    setLoads({
      type: 'RESET',
      payload: []
    });
    navigate('/', { state: { savedSearch: savedSearch, isSavedSearch: true } });
  };

  const activate = (active: boolean) => {
    updateSavedSearch(savedSearch.id, { ...savedSearch.search, active }).then(
      (response: any) => {
        if (response) {
          setSavedSearches({
            type: 'UPDATE',
            payload: response
          });
        }
        setActive(active);
      }
    );
  };

  const renderDesktopGrid = () => (
    <Grid gutter="xl" align="center">
      <Col span={1}>
        {!search.ml && <BsPersonCircle size={24} />}
        {search.ml && <SiProbot size={24} />}
      </Col>

      <Col span={4}>
        <Flex align="center">
          <Text color="blue">
            <Flex align="center">
              <BiRadioCircle size={20} />
            </Flex>
          </Text>
          <Text fw="bold" ml="xs">
            {search.origin.location.address.formatted_address}
          </Text>
        </Flex>
      </Col>
      <Col span="auto">
        <Flex align="center">
          <Text color="blue">
            <Flex align="center">
              <FaMapMarkerAlt />
            </Flex>
          </Text>
          <Text fw="bold" ml="xs">
            {search.destination.location.address.formatted_address}
          </Text>
        </Flex>
      </Col>
      {search.ml && active && (
        <>
          <Col span="content">
            <Text c={'green'} fw={900}>
              ACTIVE
            </Text>
          </Col>

          {/*
          <Col span="content">
            <ActionIcon
              color="white"
              sx={(theme) => ({
                color: theme.colors.gray[6]
              })}
              onClick={() => {
                console.log('redirect to recommended loads page');
              }}
            >
              <FaSearch title="Recommended loads" />
            </ActionIcon>
          </Col>
*/}
        </>
      )}
      {search.ml && !active && (
        <Col span="content">
          <Text c={'red'} fw={900}>
            PAUSED
          </Text>
        </Col>
      )}
      <Col span="content">
        {!search.ml && (
          <ActionIcon
            color="white"
            sx={(theme) => ({
              color: theme.colors.gray[6]
            })}
            onClick={navigateToFindLoadsPage}
          >
            <FaSearch title="Search loads" />
          </ActionIcon>
        )}
        {search.ml && !active && (
          <ActionIcon
            color="white"
            sx={(theme) => ({
              color: theme.colors.gray[6]
            })}
            onClick={() => {
              // make a back end request to start this search
              activate(true);
            }}
          >
            <BsFillPlayCircleFill title="Search loads" />
          </ActionIcon>
        )}
        {search.ml && active && (
          <ActionIcon
            color="white"
            sx={(theme) => ({
              color: theme.colors.gray[6]
            })}
            onClick={() => {
              // make a back end request to stop this search
              activate(false);
            }}
          >
            <FaPauseCircle title="Pause search" />
          </ActionIcon>
        )}
      </Col>
      {search.ml && (
        <Col span="content">
          <ActionIcon
            color="white"
            sx={(theme) => ({
              color: theme.colors.gray[6]
            })}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            <FaCog title="Edit" />
          </ActionIcon>
        </Col>
      )}
      <Col span="content">
        <ActionIcon
          color="white"
          sx={(theme) => ({
            color: theme.colors.gray[6]
          })}
          onClick={() => {
            deleteSavedSearch(savedSearch.id).then(() => {
              setSavedSearches({
                type: 'REMOVE',
                payload: savedSearch
              });
            });
          }}
        >
          <FaTrash title="Delete" />
        </ActionIcon>
      </Col>
    </Grid>
  );

  const renderMobileGrid = () => (
    <Grid grow gutter="xl" align="center">
      <Col span="content">
        <Flex align="center">
          <Text color="blue">
            <Flex align="center">
              <BiRadioCircle size={20} />
            </Flex>
          </Text>
          <Text fw="bold" ml="xs">
            {search.origin.location.address.formatted_address}
          </Text>
        </Flex>
        <Flex align="center">
          <Text color="blue">
            <Flex align="center">
              <FaMapMarkerAlt size={20} />
            </Flex>
          </Text>
          <Text fw="bold" ml="xs">
            {search.destination.location.address.formatted_address}
          </Text>
        </Flex>
      </Col>
      <Col span="content">
        <Flex>
          {search.ml && active && (
            <Col span={12} ta="center" style={{ padding: '0' }}>
              <Text c={'green'} fw={900}>
                ACTIVE
              </Text>
            </Col>
          )}
          {search.ml && !active && (
            <Col span={12} ta="center" style={{ padding: '0' }}>
              <Text c={'red'} fw={900}>
                PAUSED
              </Text>
            </Col>
          )}
        </Flex>
        <Flex align="center">
          <Col
            span="content"
            ta="center"
            style={{ padding: '0', display: 'flex', justifyContent: 'center' }}
          >
            {!search.ml && (
              <ActionIcon
                color="white"
                sx={(theme) => ({
                  color: theme.colors.gray[6]
                })}
                onClick={navigateToFindLoadsPage}
              >
                <FaSearch title="Search loads" />
              </ActionIcon>
            )}
            {search.ml && !active && (
              <ActionIcon
                color="white"
                sx={(theme) => ({
                  color: theme.colors.gray[6]
                })}
                onClick={() => {
                  // make a back end request to start this search
                  activate(true);
                }}
              >
                <BsFillPlayCircleFill title="Search loads" />
              </ActionIcon>
            )}
            {search.ml && active && (
              <ActionIcon
                color="white"
                sx={(theme) => ({
                  color: theme.colors.gray[6]
                })}
                onClick={() => {
                  // make a back end request to stop this search
                  activate(false);
                }}
              >
                <FaPauseCircle title="Pause search" />
              </ActionIcon>
            )}
          </Col>
          {search.ml && (
            <Col
              span="content"
              style={{
                padding: '0',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <ActionIcon
                color="white"
                sx={(theme) => ({
                  color: theme.colors.gray[6]
                })}
                onClick={() => {
                  setModalOpened(true);
                }}
              >
                <FaCog title="Edit" />
              </ActionIcon>
            </Col>
          )}
          <Col
            span="content"
            style={{ padding: '0', display: 'flex', justifyContent: 'center' }}
          >
            <ActionIcon
              color="white"
              sx={(theme) => ({
                color: theme.colors.gray[6]
              })}
              onClick={() => {
                deleteSavedSearch(savedSearch.id).then(() => {
                  setSavedSearches({
                    type: 'REMOVE',
                    payload: savedSearch
                  });
                });
              }}
            >
              <FaTrash title="Delete" />
            </ActionIcon>
          </Col>
        </Flex>
      </Col>
    </Grid>
  );

  return (
    <>
      <Paper
        p="lg"
        sx={(theme) => ({ border: `1px solid ${theme.colors.gray[0]}` })}
      >
        {!isMobile && renderDesktopGrid()}
        {isMobile && renderMobileGrid()}
      </Paper>
      {!isLastRow && <Space h={20} />}
      {search.ml && modalOpened && (
        <SavedSearchModal
          search={search}
          savedSearchId={savedSearch.id}
          opened={true}
          close={() => {
            setModalOpened(false);
          }}
        />
      )}
    </>
  );
};
