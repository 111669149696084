import { uniqBy } from 'lodash';
import { LoadProps } from '../context';

export type ActionsType =
  | 'SET'
  | 'ADD'
  | 'REMOVE'
  | 'SORT'
  | 'RESET'
  | 'BOOK'
  | 'OFFER'
  | 'OFFER_EVENT';

export interface ActionProps {
  type: ActionsType;
  payload: any;
}

type StateProps = any;

export const loadsListReducer = (state: StateProps, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      return payload;
    case 'ADD':
      return uniqBy([...state, ...payload], 'load_id');
    case 'RESET':
      return payload;
    case 'SORT': {
      const { sortValue } = payload;

      switch (sortValue) {
        case 'ratePerMile': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd === bAtEnd) {
              return b.rate_per_mile - a.rate_per_mile;
            }

            return aAtEnd ? 1 : -1;
          });

          return sorted;
        }
        case 'priceLow': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd === bAtEnd) {
              return a.rate - b.rate;
            }

            return aAtEnd ? 1 : -1;
          });

          return sorted;
        }
        case 'priceHigh': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd === bAtEnd) {
              return b.rate - a.rate;
            }

            return aAtEnd ? 1 : -1;
          });

          return sorted;
        }
        case 'milesLow': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd === bAtEnd) {
              return a.customer_miles - b.customer_miles;
            }

            return aAtEnd ? 1 : -1;
          });

          return sorted;
        }
        case 'milesHigh': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd === bAtEnd) {
              return b.customer_miles - a.customer_miles;
            }

            return aAtEnd ? 1 : -1;
          });

          return sorted;
        }
        case 'odhLow': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd !== bAtEnd) {
              return aAtEnd ? 1 : -1;
            }

            const aMiles = getOriginDeadHeadMiles(a);
            const bMiles = getOriginDeadHeadMiles(b);

            if (aMiles == null) {
              return -1;
            }
            if (bMiles == null) {
              return 1;
            }

            return aMiles - bMiles;
          });

          return sorted;
        }
        case 'odhHigh': {
          const sorted = [...state].sort((a: any, b: any) => {
            const aAtEnd = shouldBeAtEnd(a);
            const bAtEnd = shouldBeAtEnd(b);

            if (aAtEnd !== bAtEnd) {
              return aAtEnd ? 1 : -1;
            }

            const aMiles = getOriginDeadHeadMiles(a);
            const bMiles = getOriginDeadHeadMiles(b);

            if (aMiles == null) {
              return -1;
            }
            if (bMiles == null) {
              return 1;
            }

            return bMiles - aMiles;
          });

          return sorted;
        }
        case 'deadheadPlusRatePerMile':
          break;
        case 'puDateAccending':
          break;
      }

      return state;
    }

    case 'BOOK': {
      const id = payload;
      const newState = state.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            isBooked: true
          };
        }

        return item;
      });

      return newState;
    }

    case 'OFFER': {
      const { id } = payload;

      const newState = state.map((item: any) => {
        if (item.load_id === id) {
          return {
            ...item,
            isOffer: true
          };
        }

        return item;
      });

      return newState;
    }

    case 'OFFER_EVENT': {
      let newState = state;
      payload.forEach((element: any) => {
        switch (element.status) {
          case 'book': {
            newState = state.map((item: any) => {
              if (item.id === element.load.id) {
                return {
                  ...item,
                  isBooked: true
                };
              }

              return item;
            });

            break;
          }

          case 'bid': {
            newState = state.map((item: any) => {
              if (item.id === element.load.id) {
                return {
                  ...item,
                  isOffer: true
                };
              }

              return item;
            });

            break;
          }

          case 'rejected': {
            newState = state.map((item: any) => {
              if (item.id === element.load.id) {
                return {
                  ...item,
                  isOffer: false,
                  isBooked: false
                };
              }

              return item;
            });

            break;
          }

          default: {
            const filteredState = [...state].filter(
              (item: any) => item.id !== element.load.id
            );

            newState = [...filteredState];

            break;
          }
        }
      });
      return newState;
    }

    default:
      throw new Error('loadsListReducer issue');
  }
};

function shouldBeAtEnd(load: LoadProps): boolean {
  return load.odh_over_query_radius;
}

function getOriginDeadHeadMiles(load: LoadProps) {
  const sourceMiles = load.origin_dead_head_miles;
  const aproximatedMiles = load.aproximated_origin_dead_head_miles;

  if (sourceMiles > 0 || sourceMiles === 0) {
    return sourceMiles;
  }

  if (aproximatedMiles > 0 || aproximatedMiles === 0) {
    return aproximatedMiles;
  }

  return null;
}
