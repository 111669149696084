import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Text } from '../../../components/Typography/Typography';
import { Price } from '../Common/Price/Price';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Button } from '../../../components/Buttons/Button/Button';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Information } from '../Common/Information/Information';

export const Preview = ({
  type,
  offer,
  currentSearch = {}
}: any): ReactElement => {
  const navigate = useNavigate();
  const load = offer.load.content;
  const { full_name, phone, email, position } = load.contacts || {};

  const navigateToDetailsPage = () => {
    navigate(`/my-load-details/${offer.load.stable_id}`, {
      state: { savedSearchId: currentSearch?.id }
    });
  };

  return (
    <Grid>
      <Col span={type ? 12 : 9}>
        <Paper sx={{ backgroundColor: '#F7F8F8' }} radius="md" p="xs">
          <Flex gap="md" justify="space-between" align="center" wrap="wrap">
            <Text fz="sm">
              Load number: <b>#{load.load_id}</b>
            </Text>
            <Button
              variant="white"
              color="black"
              rightIcon={<FaArrowRight />}
              onClick={navigateToDetailsPage}
            >
              Full load details
            </Button>
          </Flex>
        </Paper>
        <Grid m="xs">
          <Col span={12}>
            <Information offer={offer} load={load} />
          </Col>
        </Grid>
      </Col>
      {!type && (
        <Col span={3}>
          <Price
            load={load}
            id={load.load_id}
            rate={load.rate}
            ratePerMile={load.rate_per_mile}
            email={email}
            position={position}
            fullName={full_name}
            phone={phone}
          />
        </Col>
      )}
    </Grid>
  );
};
