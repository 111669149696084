import { ReactElement, useContext, useState } from 'react';
import { Box, CloseButton, Col, Flex, List, Popover } from '@mantine/core';
import { Text } from '../../../components/Typography/Typography';
import { Button } from '../../../components/Buttons/Button/Button';
import { Group } from '../../../components/Layout/Group/Group';

export const ToggleStatusPopOverContent = ({
  setIsToggleStatusOpened,
  enabled,
  toggleEnabled
}: any): ReactElement => {
  return (
    <>
      <Popover.Dropdown>
        <Flex direction={'row-reverse'}>
          <CloseButton
            onClick={() => setIsToggleStatusOpened(false)}
          ></CloseButton>

          <Box>
            <Col>
              <Text size="xs" align="center">
                {enabled
                  ? 'Are you sure you want to stop this lane?'
                  : 'Are you sure you want to activate this lane?'}
              </Text>
              <Group position="center">
                <Button
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    setIsToggleStatusOpened(false);
                    toggleEnabled(!enabled);
                  }}
                >
                  YES
                </Button>
              </Group>
            </Col>
          </Box>
        </Flex>
      </Popover.Dropdown>
    </>
  );
};
