import { ReactElement, useEffect, useState } from 'react';
import { Grid, Col } from '../../components/Layout/Grid/Grid';
import { Text } from '../../components/Typography/Typography';
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';
import { Button } from '../../components/Buttons/Button/Button';
import { AutocompleteWithoutSelect } from '../../components/Inputs/AutocompleteWithoutSelect/AutocompleteWithoutSelect';
import { useMediaQuery } from '../../hooks/useMediaQuery/useMediaQuery';
import { useTheme } from '../../hooks/useTheme/useTheme';
import { AutocompleteItem } from '@mantine/core';
import { autocomplete, geocode } from '../../api/api';
import { Image } from '../../components/DataDisplay/Image/Image';
import ellipseIcon from '../../assets/icons/elipse.svg';
import arrowsIcon from '../../assets/icons/arrows.svg';
import pointerIcon from '../../assets/icons/pointer.svg';
import moment from 'moment';

const mapToAutocompleteItem = (data: any = {}) => {
  return {
    value: data.description,
    name: data.description,
    data: { placeId: data.place_id }
  };
};

interface Props {
  onSubmit: (values: any) => void;
  form: any;
}

export const SearchByCityForm = ({ form, onSubmit }: Props): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xl}px)`);

  const [originLocationData, setOriginLocationData] = useState([]);
  const [destinationLocationData, setDestinationLocationData] = useState([]);
  const [lastValidDestination, setlastValidDestination] = useState('');
  const [lastValidOrigin, setlastValidOrigin] = useState('');

  const handleAddressLookup = (e: any) =>
    autocomplete(e)
      .then((e: any) => e?.predictions || [])
      .catch(() => []);

  const handleDestinationSelect = async (
    type: string,
    value: AutocompleteItem
  ) => {
    const addressData = ((await geocode(value.data.placeId))?.results || [])[0];

    if (!addressData) {
      return;
    }

    setLastValidLocation(type, addressData.formatted_address);

    form.setFieldValue(
      `${type}.location.address.formatted_address`,
      addressData.formatted_address
    );
    form.setFieldValue(
      `${type}.location.coordinates.latitude`,
      addressData.geometry?.location?.lat
    );
    form.setFieldValue(
      `${type}.location.coordinates.longitude`,
      addressData.geometry?.location?.lng
    );
  };

  const handleEmptyAddress = (type: string, value: string) => {
    if (value) {
      return;
    }

    setLastValidLocation(type, '');

    form.setFieldValue(`${type}.location.coordinates.latitude`, '');
    form.setFieldValue(`${type}.location.coordinates.longitude`, '');
  };

  const setLastValidLocation = (type: string, value: string) => {
    if (type === 'origin') {
      setlastValidOrigin(value);
    } else if (type === 'destination') {
      setlastValidDestination(value);
    }
  };

  useEffect(() => {
    handleEmptyAddress(
      'origin',
      form.values.origin.location.address.formatted_address
    );

    const delayDebounceFn = setTimeout(async () => {
      if (form.values.origin.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.origin.location.address.formatted_address
        );
        setOriginLocationData(results.map(mapToAutocompleteItem));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.origin.location.address.formatted_address]);

  useEffect(() => {
    handleEmptyAddress(
      'destination',
      form.values.destination.location.address.formatted_address
    );

    const delayDebounceFn = setTimeout(async () => {
      if (form.values.destination.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.destination.location.address.formatted_address
        );
        setDestinationLocationData(results.map(mapToAutocompleteItem));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.destination.location.address.formatted_address]);

  const renderDesktopButtons = () => (
    <>
      <Col xl="auto" span={12}>
        <Button
          type="submit"
          fullWidth
          size="md"
          h={47}
          disabled={
            !(
              form.values.origin.location.coordinates.latitude &&
              form.values.origin.location.coordinates.longitude &&
              form.values.destination.location.coordinates.latitude &&
              form.values.destination.location.coordinates.longitude
            )
          }
          onClick={() => {
            form.setFieldValue(
              `origin.location.address.formatted_address`,
              lastValidOrigin
            );
            form.setFieldValue(
              `destination.location.address.formatted_address`,
              lastValidDestination
            );
          }}
        >
          Show lane scores
        </Button>
      </Col>
    </>
  );

  const renderMobileButtons = () => (
    <>
      <Col xl="auto" span={9}>
        <Button
          type="submit"
          fullWidth
          size="md"
          h={47}
          mt="sm"
          disabled={
            !(
              form.values.origin.location.coordinates.latitude &&
              form.values.origin.location.coordinates.longitude &&
              form.values.destination.location.coordinates.latitude &&
              form.values.destination.location.coordinates.longitude
            )
          }
          onClick={() => {
            form.setFieldValue(
              `origin.location.address.formatted_address`,
              lastValidOrigin
            );
            form.setFieldValue(
              `destination.location.address.formatted_address`,
              lastValidDestination
            );
          }}
        >
          Show lane scores
        </Button>
      </Col>
    </>
  );

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid gutter="xs" align="end">
        <Col xl={3} span={12}>
          <Text fz="sm" c="dimmed" mb="xs">
            Origin
          </Text>
          <AutocompleteWithoutSelect
            nothingFound="No options"
            filter={(val: any, filter: any) => true}
            data={originLocationData}
            onItemSubmit={(value: any) => {
              handleDestinationSelect('origin', value);
            }}
            icon={<Image src={ellipseIcon} width={12} height={12} />}
            iconWidth={24}
            {...form.getInputProps('origin.location.address.formatted_address')}
          />
        </Col>
        {!isMobile && (
          <Col xl="content" span={12} mb="md">
            <Text fz="sm" c="dimmed">
              <Image src={arrowsIcon} width={12} height={12} />
            </Text>
          </Col>
        )}
        <Col xl={3} span={12}>
          <Text fz="sm" c="dimmed" mb="xs">
            Destination
          </Text>
          <AutocompleteWithoutSelect
            nothingFound="No options"
            data={destinationLocationData}
            filter={(val: any, filter: any) => true}
            onItemSubmit={(value: any) => {
              handleDestinationSelect('destination', value);
            }}
            icon={<Image src={pointerIcon} width={12} height={16} />}
            iconWidth={24}
            {...form.getInputProps(
              'destination.location.address.formatted_address'
            )}
          />
        </Col>
        {!isMobile && renderDesktopButtons()}
        {isMobile && renderMobileButtons()}
      </Grid>
    </form>
  );
};
