import { CSSObject, MantineTheme } from '@mantine/core';
import { keyframes } from '@emotion/react';

const newNotification = (theme: MantineTheme) => keyframes`
  0% {
    box-shadow: 0 0 0px ${theme.colors.gray[4]};
  }
  50% {
    box-shadow: 0 0 40px 3px ${theme.colors.red[6]};
  }
  100% {
    box-shadow: 0 0 0px ${theme.colors.gray[4]};
  }
  `;

export const styles = (theme: MantineTheme, animate: boolean): CSSObject => ({
  position: 'fixed',
  bottom: 0,
  right: '2%',
  background: 'white',
  borderTopLeftRadius: '.5rem',
  borderTopRightRadius: '.5rem',
  cursor: 'pointer',

  animation: animate ? `${newNotification(theme)} 1s linear` : 'none',
  border: '5px solid transparent',

  '&:hover': {
    backgroundColor: theme.colors.gray[0]
  }
});
