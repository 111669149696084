import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  colorScheme: 'light',
  colors: {
    black: ['#000'],
    electricGreen: [
      '#e7f5f1',
      '#d0ebe3',
      '#b8e2d5',
      '#a0d8c7',
      '#89ceb9',
      '#71c4ab',
      '#59ba9d',
      '#41b18f',
      '#2aa781',
      '#129d73'
    ],
    oceanBlue: [
      '#eaf0fd',
      '#d5e0fb',
      '#bfd1f9',
      '#aac1f7',
      '#95b2f6',
      '#80a3f4',
      '#6b93f2',
      '#5584f0',
      '#4074ee',
      '#2b65ec'
    ],
    white: ['#fff']
  },
  primaryColor: 'electricGreen',
  defaultRadius: 'md'
};

export default theme;
