import { ReactElement } from 'react';
import type { ActionIconProps } from '@mantine/core';
import { ActionIcon as MantineActionIcon } from '@mantine/core';

interface Props extends ActionIconProps {
  onClick?: () => void;
}

export const ActionIcon = ({ children, ...rest }: Props): ReactElement => {
  return <MantineActionIcon {...rest}>{children}</MantineActionIcon>;
};
