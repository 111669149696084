import { ReactElement } from 'react';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { NumberInput } from '../../../components/Inputs/NumberInput/NumberInput';
import { Title, Tooltip } from '../../../components/Typography/Typography';
import { RangeSlider } from '../../../components/Inputs/RangeSlider/RangeSlider';
import { Select } from '../../../components/Inputs/Select/Select';
import sonarLogo from '../../../assets/images/sonar-logo.png';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Image } from '../../../components/DataDisplay/Image/Image';

interface Props {
  form: any;
  lineHaul: any;
  duration: number[];
  miles: number[];
  setDuration: (duration: number[]) => void;
  setMiles: (miles: number[]) => void;
}

export const Parameters = ({
  form,
  lineHaul,
  duration,
  miles,
  setDuration,
  setMiles
}: Props): ReactElement => {
  return (
    <>
      <Col span={12}>
        <Grid>
          <Col span={4}>
            <NumberInput
              label={
                <Tooltip
                  label={
                    'This is automatic calculation based on your inputs in fields RPM and Loaded Miles'
                  }
                >
                  <b>$ Min Line Haul</b>
                </Tooltip>
              }
              icon={'$'}
              type="number"
              size="md"
              value={lineHaul}
              readOnly={true}
              disabled={true}
            />
          </Col>
          <Col span={4}>
            <Select
              label={
                <Tooltip
                  label={
                    'Please select how many loads the robot can book in this lane per day.'
                  }
                >
                  <b>Booking Scope</b>
                </Tooltip>
              }
              required
              data={[
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 }
              ]}
              {...form.getInputProps('capacity_of_lane')}
              size="md"
              mb="xs"
            />
          </Col>
          <Col span={4}>
            <NumberInput
              label={<b>RPM from</b>}
              required
              defaultValue={1.0}
              precision={2}
              min={0.1}
              step={0.01}
              allowPositiveOnly={true}
              type="number"
              {...form.getInputProps('rpm_from')}
              size="md"
            />
            <Col span={12}>
              <Flex direction="row-reverse">
                <Flex align="center" direction="column">
                  <p>
                    <b style={{ fontSize: '14px' }}>{'See market rates '}</b>
                  </p>
                  <a href="/lanes" target="_blank">
                    <Image
                      src={sonarLogo}
                      alt="Open Road"
                      width={130}
                      sx={{ cursor: 'pointer' }}
                    />
                  </a>
                </Flex>
              </Flex>
            </Col>
          </Col>
        </Grid>
      </Col>

      <Col span={12}>
        <Grid grow gutter="xl">
          <Col span={12}>
            <Title order={4} my="xs">
              Loaded Miles
            </Title>
            <Grid>
              <Col span={2}>
                <NumberInput
                  value={miles[0]}
                  min={100}
                  max={miles[1]}
                  allowPositiveOnly={true}
                  type="number"
                  allowIntegersOnly
                  onChange={(value: number) => {
                    const maxVal = form.values.loaded_miles.max;
                    const adjustedValue = value > maxVal ? maxVal : value;

                    setMiles([adjustedValue, miles[1]]);
                    form.setFieldValue('loaded_miles.min', adjustedValue);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == '') {
                      setMiles([100, miles[1]]);
                      form.setFieldValue('loaded_miles.min', 100);
                    }
                  }}
                ></NumberInput>
              </Col>
              <Col span={2} offset={8}>
                <NumberInput
                  value={miles[1]}
                  min={miles[0]}
                  max={3000}
                  allowPositiveOnly={true}
                  type="number"
                  allowIntegersOnly
                  onChange={(value: number) => {
                    const minVal = form.values.loaded_miles.min;
                    const adjustedValue = value < minVal ? minVal : value;

                    form.setFieldValue('loaded_miles.max', adjustedValue);
                    setMiles([miles[0], adjustedValue]);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == '') {
                      setMiles([miles[0], 3000]);
                      form.setFieldValue('loaded_miles.max', 3000);
                    }
                  }}
                ></NumberInput>
              </Col>
            </Grid>
            <RangeSlider
              min={100}
              max={3000}
              value={[
                form.values.loaded_miles.min,
                form.values.loaded_miles.max
              ]}
              onChange={(value: [number, number]) => {
                const [from, to] = value;
                form.setFieldValue('loaded_miles.min', from);
                form.setFieldValue('loaded_miles.max', to);
                setMiles([from, to]);
              }}
            ></RangeSlider>
          </Col>
          <Col span={12}>
            <Title order={4} my="xs">
              <Tooltip
                label={
                  <p style={{ fontWeight: 400 }}>Transit duration (Days)</p>
                }
                position="left-end"
              >
                <p>Duration</p>
              </Tooltip>
            </Title>
            <Grid>
              <Col span={2}>
                <NumberInput
                  value={duration[0]}
                  allowPositiveOnly={true}
                  max={duration[1]}
                  min={1}
                  step={1}
                  allowIntegersOnly
                  type="number"
                  onChange={(val: number) => {
                    setDuration([val, duration[1]]);
                    form.setFieldValue('duration.min', val * 10);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == '') {
                      setDuration([1, duration[1]]);
                      form.setFieldValue('duration.min', 1 * 10);
                    }
                  }}
                ></NumberInput>
              </Col>
              <Col span={2} offset={8}>
                <NumberInput
                  allowPositiveOnly={true}
                  max={7}
                  min={duration[0]}
                  allowIntegersOnly
                  type="number"
                  value={duration[1]}
                  onChange={(val: number) => {
                    setDuration([duration[0], val]);
                    form.setFieldValue('duration.max', val * 10);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == '') {
                      setDuration([duration[0], 7]);
                      form.setFieldValue('duration.max', 7 * 10);
                    }
                  }}
                ></NumberInput>
              </Col>
            </Grid>
          </Col>
          <Col span={12}>
            <RangeSlider
              min={10}
              max={70}
              label={null}
              step={10}
              marks={[
                { value: 10, label: '1 day' },
                { value: 20, label: '2 days' },
                { value: 30, label: '3 days' },
                { value: 40, label: '4 days' },
                { value: 50, label: '5 days' },
                { value: 60, label: '6 days' },
                { value: 70, label: '7 days' }
              ]}
              value={[duration[0] * 10, duration[1] * 10]}
              onChange={(value: [number, number]) => {
                const [from, to] = value;
                setDuration([from / 10, to / 10]);
                form.setFieldValue('duration.min', from);
                form.setFieldValue('duration.max', to);
              }}
            ></RangeSlider>
          </Col>
        </Grid>
      </Col>
    </>
  );
};
