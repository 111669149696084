import { ReactElement, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Image } from '../../../components/DataDisplay/Image/Image';
import { Text, Title } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Preview } from '../Preview/Preview';
import { ActionIcon } from '../../../components/Buttons/ActionIcon/ActionIcon';
import { Space } from '../../../components/Layout/Space/Space';
import { LoadProps } from '../../../context';
import { Divider } from '../../../components/Miscellaneous/Divider/Divider';
import { Box } from '../../../components/Miscellaneous/Box/Box';
import moment from 'moment-timezone';
import { formattedDestinationTime, getLogo } from '../../../utils/utils';

interface Props {
  type?: 'booked' | 'offers' | 'modal' | 'bidsLive';
  currentSearch?: any;
  offer: any;
}

const getOriginDeadHeadMiles = (load: LoadProps) => {
  const sourceMiles = load.origin_dead_head_miles;
  const aproximatedMiles = load.aproximated_origin_dead_head_miles;

  if (sourceMiles > 0 || sourceMiles === 0) {
    return sourceMiles;
  }

  if (aproximatedMiles > 0 || aproximatedMiles === 0) {
    return `∽${aproximatedMiles}`;
  }
};

const offerStatusMap: any = {
  ok: 'Booked'
};

export const Row = ({ type, currentSearch, offer }: Props): ReactElement => {
  const [isPreview, setIsPreview] = useState(false);
  const load = offer.load.content;
  const { stops } = load;

  const [firstStop] = stops;
  const [lastStop] = [...stops].reverse();

  const dateFrom = moment.tz(firstStop.time_from_local, firstStop.time_zone);
  const dateTo = moment.tz(lastStop.time_to_local, lastStop.time_zone);

  const isModal = type === 'modal';
  const isBidsLive = type === 'bidsLive';

  return (
    <>
      <Paper
        p="lg"
        sx={{
          backgroundColor: isModal || isBidsLive ? '#eee' : 'white',
          overflow: 'hidden'
        }}
      >
        <Grid gutter="xl" align="center">
          {!isModal && !isBidsLive && (
            <Col span="content">
              <Image
                src={getLogo(load.source)}
                alt="Sample logo"
                width={50}
                height="auto"
              />
            </Col>
          )}
          <Col span="auto">
            <Flex justify="space-between" align="center">
              <Box w="100%" ml="xl">
                <Flex align="center">
                  <Image
                    src="src/assets/icons/elipse.svg"
                    width={12}
                    height={12}
                    ml={-16}
                    mr={16}
                  />
                  <Text fz="md" fw="bold">
                    {firstStop.address.city_name
                      ? `${firstStop.address.city_name}, ${firstStop.address.postal_code}, ${firstStop.address.state_province_code}`
                      : firstStop.address.full_address}
                  </Text>
                </Flex>
                <Text fz="sm" c="dimmed" ml={12}>
                  {formattedDestinationTime(
                    firstStop.time_from_local,
                    firstStop.time_to_local,
                    firstStop.time_zone
                  )}
                </Text>
              </Box>
              <Divider orientation="vertical" h="auto" ml="xl" />
            </Flex>
          </Col>
          <Col span="auto">
            <Flex justify="space-between">
              <Box w="100%" ml="xl">
                <Flex align="center">
                  <Image
                    src="src/assets/icons/pointer.svg"
                    width={12}
                    height={16}
                    ml={-16}
                    mr={16}
                  />
                  <Text fz="md" fw="bold">
                    {lastStop.address.city_name
                      ? `${lastStop.address.city_name}, ${lastStop.address.postal_code}, ${lastStop.address.state_province_code}`
                      : lastStop.address.full_address}
                  </Text>
                </Flex>
                <Text fz="sm" c="dimmed" ml={12}>
                  {formattedDestinationTime(
                    lastStop.time_from_local,
                    lastStop.time_to_local,
                    lastStop.time_zone
                  )}
                </Text>
              </Box>
              <Divider orientation="vertical" h="auto" ml="xl" />
            </Flex>
          </Col>
          {!isBidsLive && (
            <Col span="content">
              <Flex justify="space-between">
                <Box w="100%">
                  <Flex justify="space-between" align="center">
                    <Text fz="sm" c="dimmed">
                      Miles
                    </Text>
                    <Text fz="md" fw="bold" ml={50}>
                      {load.customer_miles}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text fz="sm" c="dimmed">
                      Deadhead
                    </Text>
                    <Text fz="md" fw="bold" ml={50}>
                      {getOriginDeadHeadMiles(load)}
                    </Text>
                  </Flex>
                </Box>
                <Divider orientation="vertical" h="auto" ml="xl" />
              </Flex>
            </Col>
          )}
          {!isModal && !isBidsLive && (
            <Col span="content">
              <Flex justify="space-between">
                <Box w="100%">
                  <Text fz="sm" c="dimmed">
                    Weight
                  </Text>
                  <Text fz="md" fw="bold">
                    {load.weight} lbs
                  </Text>
                </Box>
                <Divider orientation="vertical" h="auto" ml="xl" />
              </Flex>
            </Col>
          )}
          {!isBidsLive && (
            <>
              <Col span="content">
                <Flex justify="space-between">
                  <Box w="100%">
                    <Text fz="sm" c="dimmed">
                      Rate per mile
                    </Text>
                    <Text fz="md" fw="bold">
                      {load.rate_per_mile ? `${load.rate_per_mile}/mi` : '-'}
                    </Text>
                  </Box>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </Flex>
              </Col>
              <Col span="content">
                <Title order={5}>
                  {`Rate: `}
                  <NumericFormat
                    value={load.rate}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Title>
                <Title order={5} mb={5}>
                  {`Offer: `}
                  <NumericFormat
                    value={offer.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Title>
                <Title order={5} className={`offer ${offer.status}`}>
                  {offerStatusMap[offer.status]}
                </Title>
              </Col>
            </>
          )}
          {!isModal && !isBidsLive && (
            <Col span="content">
              <ActionIcon
                onClick={() => {
                  setIsPreview(!isPreview);
                }}
              >
                {!isPreview && <FaChevronDown />}
                {isPreview && <FaChevronUp />}
              </ActionIcon>
            </Col>
          )}
          {isPreview && (
            <Col span={12}>
              <Preview
                offer={offer}
                currentSearch={currentSearch}
                type={type}
              />
            </Col>
          )}
        </Grid>
      </Paper>
      <Space h={20} />
    </>
  );
};
