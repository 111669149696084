import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import {
  FixedSizeList as List,
  VariableSizeList,
  ListChildComponentProps
} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Space } from '../../components/Layout/Space/Space';
import { TopBar } from './TopBar/TopBar';
import { Row } from './Row/Row';
import { AppContext } from '../../context';
import { MyLoadsOffersFilter } from './MyLoadsOffersFilter/MyLoadsOffersFilter';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Props {
  type?: 'booked' | 'offers';
  currentSearch: any;
  onSaveSearchClick: () => void;
  setRerenderListRef: (arg1: () => void) => void;
  loadsFetching: boolean;
  onSort: any;
  scrollToPosition: number;
}

export const FindLoadsList = ({
  type,
  currentSearch,
  setRerenderListRef,
  onSaveSearchClick,
  loadsFetching,
  onSort,
  scrollToPosition
}: Props): ReactElement => {
  const { loads } = useContext(AppContext);
  const isBookedOrOffers = type === 'booked' || type === 'offers';
  const openedPreviewLoads = useSelector(
    (state: RootState) => state.rowReducer.openedPreviewLoads
  );
  const listRef: React.MutableRefObject<any> = useRef(null);
  const rerenderList: () => void = () => {
    listRef?.current?.resetAfterIndex(0);
  };

  setRerenderListRef(rerenderList);

  const getListScrollOffset: () => number | null = () => {
    return listRef?.current?._outerRef?.scrollTop;
  };

  const ListRow = ({ data, style, index }: ListChildComponentProps) => {
    return data ? (
      <div style={style}>
        <Row
          rerenderList={rerenderList}
          getListScrollOffset={getListScrollOffset}
          listRef={listRef}
          loadListIndex={index}
          load={data[index]}
          key={index}
          currentSearch={currentSearch}
          type={type}
        />
      </div>
    ) : null;
  };

  return (
    <>
      {!type && (
        <TopBar
          availableLoads={loads.length}
          currentSearch={currentSearch}
          onSaveSearchClick={onSaveSearchClick}
          loadsFetching={loadsFetching}
          onSort={onSort}
          odhOverRadiusLoads={loads.some((obj) => obj.odh_over_query_radius)}
        />
      )}
      {isBookedOrOffers && (
        <MyLoadsOffersFilter
          initialValues={{}}
          onSearch={() => {}}
          onReset={() => {}}
        />
      )}
      <Space h="md" />
      {!!loads.length && (
        <div style={{ flex: '1' }}>
          <AutoSizer>
            {({ height, width }: any) => (
              <VariableSizeList
                ref={listRef}
                className="List"
                height={height}
                itemCount={loads.length}
                itemSize={(index): number => {
                  return openedPreviewLoads[loads[index].stable_id] ? 430 : 120;
                }}
                initialScrollOffset={scrollToPosition}
                width={width}
                itemData={loads}
              >
                {ListRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </div>
      )}
    </>
  );
};
