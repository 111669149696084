import { ReactElement } from 'react';
import type { CheckboxProps } from '@mantine/core';
import type { GroupProps } from '@mantine/core/';
import { Checkbox as MantineCheckbox } from '@mantine/core';

export const Checkbox = (props: CheckboxProps): ReactElement => {
  return <MantineCheckbox {...props} />;
};

export const CheckboxGroup = ({
  children,
  ...rest
}: GroupProps): ReactElement => {
  return <Checkbox {...rest}>{children}</Checkbox>;
};
