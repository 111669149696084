import { Text, Title } from '../../components/Typography/Typography';
import { Container } from '../../components/Layout/Container/Container';
import { Space } from '../../components/Layout/Space/Space';
import { Paper } from '../../components/Miscellaneous/Paper/Paper';
import { Button, Collapse, Divider, Flex, Grid } from '@mantine/core';
import { ReactElement } from 'react';
import { Image } from '../../components/DataDisplay/Image/Image';
import sonarLogo from '../../assets/images/sonar-logo.png';
import { useDisclosure } from '@mantine/hooks';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import moment from 'moment-timezone';

const maskZip = (value: string | number) => {
  const max_chars = 5;
  const mask_chars = 2;
  const mask_char = 'X';
  let result = '';

  if (!value) {
    return result;
  }

  const zip = value.toString().replace(' ', '');

  if (zip.length >= max_chars) {
    result = zip
      .split('')
      .fill(mask_char, max_chars - mask_chars - 1, mask_chars + 1)
      .join('');
  } else {
    result = zip + mask_char.repeat(max_chars - zip.length);
  }

  return result;
};

const FreightWavesScore = ({ data }: { data: any }): ReactElement => {
  const [opened, { toggle }] = useDisclosure(false);
  const localtz = moment.tz.guess();
  const date = moment(data?.data_timestamp).tz(localtz);

  return (
    <Container>
      <Title order={1} sx={{ color: 'white' }}></Title>
      <Container size="sm">
        <Space h={10} />
        <Paper p="xl" shadow="xl">
          <Flex justify="space-between" align="center">
            <Image
              src={sonarLogo}
              alt="Open Road"
              width={160}
              sx={{ cursor: 'pointer' }}
            />
            <Text fz="lg" sx={{ color: 'grey' }}>
              {maskZip(data?.origin)} - {maskZip(data?.destination)}
            </Text>
            <Text fz="xl" sx={{ color: 'grey' }}>
              {Math.round(data?.effective_miles) || '-'} miles
            </Text>
          </Flex>
          <Divider my={10} orientation="horizontal" />
          <Title order={2}> Spot </Title>
          <Space h={30} />
          <Flex justify="space-between">
            <Text fz="xl" fw={700} align="right">
              RPM range (min - max):
            </Text>
            <Flex justify="flex-end">
              <Text fz="lg" c="red" align="right">
                ${data?.rpm_low || '-'}
                /mi
              </Text>
              <Text fz="lg" align="right">
                &nbsp;-&nbsp;
              </Text>
              <Text fz="lg" c="green" align="right">
                ${data?.rpm_high || '-'}
                /mi
              </Text>
            </Flex>
          </Flex>
          <Collapse in={opened}>
            <Grid grow>
              <Grid.Col>
                <Divider my={10} orientation="horizontal" />
                <Flex justify="space-between">
                  <Text fz="xl" fw={700} align="right">
                    Linehaul range:
                  </Text>
                  <Flex justify="space-between" align="flex-end">
                    <Text fz="lg" c="red" align="right">
                      ${data?.rate_low || '-'}
                    </Text>
                    <Text fz="lg" align="right">
                      &nbsp;-&nbsp;
                    </Text>
                    <Text fz="lg" c="green" align="right">
                      ${data?.rate_high || '-'}
                    </Text>
                  </Flex>
                </Flex>
                <Divider my={10} orientation="horizontal" />
                <Flex justify="space-between" align="center">
                  <Text fz="xl" fw={700}>
                    Average:
                  </Text>
                  <Flex justify="space-between" align="flex-end">
                    <Text fz="xl" fw={700} align="left">
                      ${data?.rate || '-'}
                    </Text>
                    <Text fz="xl" align="right" style={{ paddingLeft: '10px' }}>
                      ($
                      {data?.rpm || '-'}
                      /mi)
                    </Text>
                  </Flex>
                </Flex>
                <Divider my={10} orientation="horizontal" />
                <Flex justify="space-between" align="center">
                  <Text fz="xl" fw={700}>
                    Total load count:
                  </Text>
                  <Flex justify="space-between" align="flex-end">
                    <Text fz="xl" fw={700} align="left">
                      {Math.round(data?.total_load_count) || '-'}
                    </Text>
                  </Flex>
                </Flex>
                <Divider my={10} orientation="horizontal" />
                <Flex justify="space-between">
                  <Text fz="xl" fw={700} align="right">
                    Data timestamp
                  </Text>
                  <Flex justify="space-between" align="flex-end">
                    <Text fz="lg" align="left">
                      {date.format('YYYY-MM-DD HH:mm')} {date.format('z')}
                    </Text>
                  </Flex>
                </Flex>
              </Grid.Col>
            </Grid>
          </Collapse>
          <Divider my={10} orientation="horizontal" />
          <Flex justify="space-between">
            <Text fz="xl" fw={700} align="right">
              Lane confidence:
            </Text>
            <Flex justify="space-between" align="flex-end">
              <Text fz="xl" fw={700} align="right">
                {data?.lane_confidence || '-'}
              </Text>
            </Flex>
          </Flex>
          <Space h={10} />
          <Button
            size="sm"
            color="white"
            onClick={toggle}
            rightIcon={opened ? <FaChevronUp /> : <FaChevronDown />}
            sx={(theme) => ({
              color: theme.colors.gray[6],
              backgroundColor: theme.colors.gray[1]
            })}
          >
            {opened ? 'Show Less' : 'Show More'}
          </Button>
        </Paper>
      </Container>
    </Container>
  );
};

export default FreightWavesScore;
