import { offerStatusMap } from './offerStatusMap';
import { sourcesNamesMap } from './sourcesNamesMap';
import { displayNotification } from './utils';

type NotificationType = 'alert' | 'message' | 'success';

const notificationData = (
  notificationStatus: string
): false | { type: NotificationType; message: string } => {
  let message = '';
  let type: NotificationType = 'message';

  switch (notificationStatus) {
    case 'pending':
      type = 'message';
      message = `has changed its status to: ${offerStatusMap[notificationStatus]}`;
      break;
    case 'ok':
      type = 'success';
      message = 'was accepted and confirmed!';
      break;
    case 'rejected':
      type = 'alert';
      message = 'was rejected!';
      break;
    case 'countered':
      type = 'alert';
      message = 'was countered!';
      break;
    case 'confirm_required':
      type = 'message';
      message = 'requires confirmation!';
      break;
    case 'final_offer':
      type = 'alert';
      message = 'has a final offer!';
      break;
    default:
      return false;
  }

  return {
    type: type,
    message: message
  };
};

export const displayStatusChangeNotification = (offerNotifications: any) => {
  offerNotifications.forEach((element: any) => {
    const data = notificationData(element.status);
    if (!data) {
      return;
    }

    const { type, message } = data;
    displayNotification({
      title: `#${element.load.content.load_id} status change`,
      message: `${sourcesNamesMap[element.load.source]} load #${
        element.load.content.load_id
      } ${message}`,
      type: type
    });
  });
};
