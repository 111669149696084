import { displayNotification } from '../../utils/utils';
import ResponseError from '../errors/responseError';

const processResponse = async (response: Response) => {
  let data: any = {};
  switch (response.status) {
    case 200:
      data = await response.json().catch(() => {});

      if (data?.message) {
        displayNotification({
          title: 'Success',
          message: data.message,
          type: 'message'
        });
      }

      return data;
    case 204:
      return;
    default:
      data = await response.json().catch((e) => {});
      return Promise.reject(
        new ResponseError({
          data,
          message: 'Response error',
          statusCode: response.status
        })
      );
  }
};

export default { processResponse };
