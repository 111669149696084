import { ReactElement } from 'react';
import { Text } from '../../../../components/Typography/Typography';
import { Col, Grid } from '../../../../components/Layout/Grid/Grid';

export const Information = ({ load }: any): ReactElement => {
  return (
    <Grid>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Equipment
        </Text>
        <Text fz="lg" fw="bold">
          {load.equipment_data.types?.join(', ') || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Commodity
        </Text>
        <Text fz="lg" fw="bold">
          {load.commodities?.join(', ') || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Load type
        </Text>
        <Text fz="lg" fw="bold">
          {load.load_type || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Load requirements
        </Text>
        <Text fz="sm" fw="bold">
          {'-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Equipment length
        </Text>
        <Text fz="lg" fw="bold">
          {load.equipment_data?.length || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Cargo value
        </Text>
        <Text fz="lg" fw="bold">
          {load.cargo_value || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Piece type
        </Text>
        <Text fz="lg" fw="bold">
          {load.packaging_type || '-'}
        </Text>
      </Col>
      <Col span={3}>
        <Text fz="sm" c="dimmed">
          Specialty EQ
        </Text>
        <Text fz="lg" fw="bold">
          {load.speciality_equipment || '-'}
        </Text>
      </Col>
      {!load.preview && (
        <Col span={3}>
          <Text fz="sm" c="dimmed">
            Loaded miles
          </Text>
          <Text fz="lg" fw="bold">
            {load.customer_miles || '-'}
          </Text>
        </Col>
      )}
    </Grid>
  );
};
