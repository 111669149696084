export type ActionsType = 'SET' | 'ADD' | 'REMOVE' | 'UPDATE';
export interface ActionProps {
  type: ActionsType;
  payload: any;
}

export const autoBookingFiltersReducer = (state: any, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      return [...payload];
    case 'UPDATE': {
      const index = state.findIndex((item: any) => item.id == payload.id);
      state[index] = payload;

      return [...state];
    }
    case 'ADD':
      return [...state, payload];
    case 'REMOVE': {
      const filteredState = [...state].filter(
        (item: any) => item.id !== payload.id
      );

      return [...filteredState];
    }

    default:
      throw new Error('AutoBookingFilter reducer issue issue');
  }
};
