import { ReactElement, useEffect, useState } from 'react';
import { SearchOfferProps } from '../../../context';
import { useForm } from '@mantine/form';
import { Select } from '../../../components/Inputs/Select/Select';
// import { Checkbox } from '../../../components/Inputs/Checkbox/Checkbox';
import { Text } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { DateRangePicker } from '@mantine/dates';
import { Group } from '../../../components/Layout/Group/Group';
import { Button } from '@mantine/core';
import { MultiSelect } from '../../../components/Inputs/MultiSelect/MultiSelect';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { offerStatusMap } from '../../../utils/offerStatusMap';

interface Props {
  initialValues: SearchOfferProps;
  onSearch: (data: SearchOfferProps, sort: string) => void;
  onSort: (data: string) => void;
}

export const Filter = ({
  initialValues,
  onSearch,
  onSort
}: Props): ReactElement => {
  const [statusSortValue, setStatusSortValue] = useState('all');
  const [userOffersSortValue, setUserOffersStatusSortValue] = useState('all');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [searchFormValues, setSearchFormValues] = useState<any>({});

  const statusSortValues = [
    { label: 'All', value: 'all' },
    { label: 'Booked', value: 'booked' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Unavailable', value: 'unavailable' },
    {
      label: 'Declined',
      value: 'declined'
    }
  ];

  const sourceSortValues = [
    // {
    //   label: 'Arrive Logistics',
    //   value: 'arriveLogistics'
    // },
    {
      label: 'Echo global',
      value: 'echo_global'
    },
    {
      label: 'Coyote',
      value: 'coyote'
    },
    {
      label: 'Uber',
      value: 'uber'
    },
    {
      label: 'Newtrul',
      value: 'newtrul'
    },
    {
      label: 'CH Robinson',
      value: 'ch_robinson'
    },
    {
      label: 'Loadsmart',
      value: 'loadsmart'
    },
    {
      label: 'DAT',
      value: 'dat'
    }
  ];

  // const offerStatuses = [
  //   { label: 'Book', value: 'book' },
  //   { label: 'Bid', value: 'bid' },
  //   { label: 'Pending', value: 'pending' },
  //   { label: 'Confirmed', value: 'confirm' },
  //   { label: 'Confirm', value: 'confirm_required' },
  //   { label: 'Reject', value: 'reject' },
  //   { label: 'Ok', value: 'ok' },
  //   { label: 'Rejected', value: 'rejected' },
  //   { label: 'Counter', value: 'counter' },
  //   { label: 'Countered', value: 'countered' },
  //   { label: 'Final offer', value: 'final_offer' }
  // ];

  const offerStatuses = Object.entries(offerStatusMap).map(
    ([value, label]) => ({ value, label })
  );

  const offersValues = [
    { label: 'My Offers', value: 'my' },
    { label: 'Company Offers', value: 'company' }
  ];

  const sortByValues = [
    { label: 'User', value: 'user' },
    { label: 'Deadhead', value: 'deadhead' },
    { label: 'Rate Per Mile', value: 'rpm' },
    { label: 'Rate Per Mile (Deadhead)', value: 'rpmDeadhead' },
    {
      label: 'Price Low',
      value: 'priceLow'
    },
    {
      label: 'Price High',
      value: 'priceHigh'
    },
    {
      label: 'PU Date Accending',
      value: 'puDateAccending'
    },
    {
      label: 'PU Date Descending',
      value: 'puDateDescending'
    },
    {
      label: 'DEL Date Accending',
      value: 'delDateAccending'
    },
    {
      label: 'DEL Date Descending',
      value: 'delDateDescending'
    },
    {
      label: 'Driver ID',
      value: 'driverID'
    },
    {
      label: 'Trailer ID',
      value: 'trailerID'
    },
    {
      label: 'Destination Capacity Score',
      value: 'destinationCapacityScore'
    },
    {
      label: 'Load Requirements',
      value: 'loadRequirements'
    },
    {
      label: 'Source A to Z',
      value: 'sourceAZ'
    },
    {
      label: 'Source Z to A',
      value: 'sourceZA'
    }
  ];

  /*
  const handleFilterModal = (values: any) => {
    if (values.origin?.location?.address?.formatted_address) {
      setSearchFormValues(values);
      setIsFilterModalOpen(true);
    }
  };

  const handleSubmit = (values: any) => {
    setIsFilterModalOpen(false);
    if (values.origin?.location?.address?.formatted_address) {
      onSearch(values);
    }
  };
  */

  const setMyOffersSessionFilter = (filter: SearchOfferProps) => {
    sessionStorage.setItem('myOffersFilter', JSON.stringify(filter));
  };

  const setMyOffersSessionSort = (sort: string) => {
    sessionStorage.setItem('myOffersSort', sort);
  };

  const getMyOffersSessionFilter = () => {
    const sessionFilter = sessionStorage.getItem('myOffersFilter');

    if (sessionFilter) {
      const filter = JSON.parse(sessionFilter);
      const start = filter.ranges[0];
      const end = filter.ranges[1];

      filter.ranges = [
        start ? new Date(start) : null,
        end ? new Date(end) : null
      ];

      return filter as SearchOfferProps;
    }

    return initialValues;
  };

  const getMyOffersSessionSort = () => {
    return sessionStorage.getItem('myOffersSort') || 'user';
  };

  const [filter, setFilter] = useState<SearchOfferProps>(
    getMyOffersSessionFilter()
  );

  const form = useForm({
    initialValues: {
      ...filter
    }
  });

  const [sortByValue, setSortByValue] = useState(getMyOffersSessionSort());

  useEffect(() => {
    onSearch(filter, sortByValue);
  }, []);

  return (
    <form
      onSubmit={form.onSubmit((e) => {
        onSearch(e, sortByValue);
        setMyOffersSessionFilter(e);
      })}
    >
      <Grid>
        <Paper w="100%" ml={5} mr={5} p="lg" shadow="sm">
          <Col>
            <Group>
              <DateRangePicker
                label={
                  <Text size="sm" c="dimmed">
                    Pickup and delivery dates
                  </Text>
                }
                placeholder="Pick dates range"
                {...form.getInputProps('ranges')}
                styles={{
                  input: {
                    width: '200px'
                  }
                }}
                firstDayOfWeek="sunday"
              />
              <MultiSelect
                label={
                  <Text size="sm" c="dimmed">
                    Source
                  </Text>
                }
                placeholder={'Pick sources'}
                data={sourceSortValues}
                {...form.getInputProps('sources')}
              />
              <MultiSelect
                label={
                  <Text size="sm" c="dimmed">
                    Status
                  </Text>
                }
                placeholder={'Pick status'}
                data={offerStatuses}
                {...form.getInputProps('statuses')}
              />
              <Select
                label={
                  <Text size="sm" c="dimmed">
                    Offers
                  </Text>
                }
                data={offersValues}
                {...form.getInputProps('offers')}
                styles={{
                  input: {
                    width: '200px'
                  }
                }}
              />
              <Button
                type="submit"
                size="md"
                h={36}
                w={150}
                mt="sm"
                ml="auto"
                style={{ marginTop: '24px' }}
              >
                Search
              </Button>
            </Group>
          </Col>
        </Paper>
        <Paper
          w="100%"
          ml={5}
          mr={5}
          mt={8}
          style={{ background: 'transparent' }}
        >
          <Col>
            {/* <Checkbox
              label={
                <Text size="sm" c="dimmed">
                  Whole company loads
                </Text>
              }
              value="FTL"
              checked={true}
              onChange={(event) => {}}
              radius="sm"
              mt={30}
            /> */}
            <Flex gap="md" justify="end" align="center">
              <Text size="xs" c="dimmed">
                Sort by
              </Text>
              <Select
                value={sortByValue}
                data={sortByValues}
                w={150}
                mr={20}
                size="xs"
                onChange={(value: string) => {
                  setSortByValue(value);
                  setMyOffersSessionSort(value);
                  onSort(value);
                }}
              />
            </Flex>
          </Col>
        </Paper>
      </Grid>
    </form>
    // <Paper p="xl" shadow="sm">
    //   <DateRangePicker
    //     type="range"
    //     label="Pick dates range"
    //     placeholder="Pick dates range"
    //     onChange={() => {}}
    //     mx="auto"
    //     maw={400}
    //   />
    //   <Select
    //     data={[{ label: 'Booked', value: 1 }]}
    //     {...form.getInputProps('stops')}
    //     size="md"
    //     mb="xs"
    //   />
    //   <Checkbox
    //     value="FTL"
    //     label="Whole company loads"
    //     checked={true}
    //     onChange={(event) => {}}
    //     radius="sm"
    //   />
    //   <Text fz="sm" c="dimmed">
    //     Sort by:
    //   </Text>
    //   <Select
    //     size="xs"
    //     value={'deadhead'}
    //     data={[
    //       { label: 'Deadhead', value: 'deadhead' },
    //       { label: 'Rate Per Mile', value: 'ratePerMile' },
    //       { label: 'Price Low', value: 'priceLow' },
    //       { label: 'Price High', value: 'priceHigh' },
    //       {
    //         label: 'DeadHead+RatePerMile',
    //         value: 'deadheadPlusRatePerMile'
    //       },
    //       {
    //         label: 'PU Date Accending',
    //         value: 'puDateAccending'
    //       }
    //     ]}
    //     onChange={(value: string) => {}}
    //   />
    //   {/* <SearchForm
    //     form={form}
    //     handleFilterModal={handleFilterModal}
    //     onSubmit={handleSubmit}
    //   />
    //   {isFilterModalOpen && (
    //     <FilterModal
    //       form={form}
    //       isFilterModalOpen={isFilterModalOpen}
    //       setIsFilterModalOpen={setIsFilterModalOpen}
    //       onSubmit={handleSubmit}
    //       onReset={onReset}
    //     />
    //   )} */}
    // </Paper>
  );
};
