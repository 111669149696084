import React, { useRef, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { Title } from '../../../../components/Typography/Typography';
import { Paper } from '../../../../components/Miscellaneous/Paper/Paper';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZXZhbGRhc29yIiwiYSI6ImNsZ2R4dnFwYzAzNjgzZm56czU1djBxMDQifQ._J8mzgOEoeFD7tvKI-oMkA';

interface Stop {
  longitude: number;
  latitude: number;
}

function searchBoundingBox(
  coordinates: Stop[]
): [number, number, number, number] {
  let minLon = coordinates[0].longitude;
  let minLat = coordinates[0].latitude;
  let maxLon = coordinates[0].longitude;
  let maxLat = coordinates[0].latitude;

  for (const coordinate of coordinates) {
    minLon = Math.min(minLon, coordinate.longitude);
    minLat = Math.min(minLat, coordinate.latitude);
    maxLon = Math.max(maxLon, coordinate.longitude);
    maxLat = Math.max(maxLat, coordinate.latitude);
  }

  return [minLon, minLat, maxLon, maxLat];
}

const boundingBoxCenter = (stops: Array<any>) => {
  const boundingBoxCoordinates = searchBoundingBox(stops);
  const llb = new mapboxgl.LngLatBounds(boundingBoxCoordinates);
  const center = llb.getCenter();
  return center;
};

const StopsMap = ({ stops }: any) => {
  const center = boundingBoxCenter(stops);
  const mapContainerRef = useRef<any>(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [center.lng, center.lat],
      zoom: 3
    });

    let stopCount = 1;
    for (const stop of stops) {
      const markerEl = document.createElement('div');
      markerEl.className = 'marker';
      markerEl.style.backgroundColor = '#3388ff';
      markerEl.style.color = '#fff';
      markerEl.style.fontSize = '12px';
      markerEl.style.fontWeight = 'bold';
      markerEl.style.width = '24px';
      markerEl.style.height = '24px';
      markerEl.style.borderRadius = '50%';
      markerEl.style.textAlign = 'center';
      markerEl.style.lineHeight = '24px';
      markerEl.textContent = stopCount.toString();

      new mapboxgl.Marker(markerEl)
        .setLngLat([stop.longitude, stop.latitude])
        .setPopup(new mapboxgl.Popup().setHTML(stop.type))
        .addTo(map);

      stopCount++;
    }

    const stopsCoordinates: [number, number][] = stops.map(
      ({ longitude, latitude }: Stop) => [longitude, latitude]
    );
    map.on('load', () => {
      map.fitBounds(searchBoundingBox(stops), { padding: 50 });

      map.addSource('lines', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                color: '#3388ff'
              },
              geometry: {
                type: 'LineString',
                coordinates: stopsCoordinates
              }
            }
          ]
        }
      });

      map.addLayer({
        id: 'lines',
        type: 'line',
        source: 'lines',
        paint: {
          'line-width': 3,
          'line-color': ['get', 'color']
        }
      });
    });

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    return () => map.remove();
  }, []);

  return (
    <Paper shadow="sm" p="xl">
      <Title order={4} mb="xl">
        Map
      </Title>
      <div>
        <div style={{ height: '300px', width: '100%' }} ref={mapContainerRef} />
      </div>
    </Paper>
  );
};

export default StopsMap;
