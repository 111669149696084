import { merge } from 'lodash';
import AccessKeyManager from './AccessKeyManager';
import responseHandler from './handlers/responseHandler';
import errorHandler from './handlers/errorHandler';

const _ = { merge };

const request = async (
  resource: RequestInfo | URL,
  options?: RequestInit | undefined,
  global = true
) => {
  let accessToken = await AccessKeyManager.getToken();
  const defaultOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  };
  options = _.merge({}, defaultOptions, options);

  let response = await fetch(resource, options);

  if (!response.ok && [401, 403].includes(response.status)) {
    accessToken = await AccessKeyManager.refreshToken();
    response = await fetch(
      resource,
      _.merge(options, { headers: { Authorization: `Bearer ${accessToken}` } })
    );
  }

  return responseHandler
    .processResponse(response)
    .catch((e) => errorHandler.processError(e, global));
};

export default request;
