import { ReactElement } from 'react';
import { NumericFormat } from 'react-number-format';
import { Grid, Col } from '../../components/Layout/Grid/Grid';
import { Image } from '../../components/DataDisplay/Image/Image';
import { Text, Title } from '../../components/Typography/Typography';
import { Flex } from '../../components/Layout/Flex/Flex';
import { LoadProps } from '../../context';
import { Divider } from '../../components/Miscellaneous/Divider/Divider';
import { Box } from '../../components/Miscellaneous/Box/Box';
import moment from 'moment-timezone';
import formats from '../../enums/formats';

interface Props {
  load: LoadProps;
}

export const ModalRow = ({ load }: Props): ReactElement => {
  const { stops } = load;

  const [firstStop] = stops;
  const [lastStop] = [...stops].reverse();

  const dateFrom = moment.tz(firstStop.time_from_local, firstStop.time_zone);
  const dateTo = moment.tz(lastStop.time_to_local, lastStop.time_zone);

  return (
    <Grid
      gutter="xl"
      m={0}
      align="center"
      sx={(theme) => ({
        backgroundColor: theme.colors.gray[1],
        borderRadius: '8px'
      })}
    >
      <Col span="content">
        <Flex justify="space-between" align="center">
          <Box w="100%" ml="xl">
            <Flex align="center">
              <Image
                src="src/assets/icons/elipse.svg"
                width={12}
                height={12}
                ml={-16}
                mr={16}
              />
              <Text fz="md" fw="bold">
                {firstStop.address.city_name}, {firstStop.address.postal_code},{' '}
                {firstStop.address.state_province_code}
              </Text>
            </Flex>
            <Text fz="sm" c="dimmed" ml={12}>
              {dateFrom.isValid()
                ? dateFrom.format(formats.DATE_TIME.DEFAULT)
                : ''}
            </Text>
          </Box>
          <Divider orientation="vertical" h="auto" ml="xl" />
        </Flex>
      </Col>
      <Col span="content">
        <Flex justify="space-between">
          <Box w="100%" ml="xl">
            <Flex align="center">
              <Image
                src="src/assets/icons/pointer.svg"
                width={12}
                height={16}
                ml={-16}
                mr={16}
              />
              <Text fz="md" fw="bold">
                {lastStop.address.city_name}, {lastStop.address.postal_code},{' '}
                {lastStop.address.state_province_code}
              </Text>
            </Flex>
            <Text fz="sm" c="dimmed" ml={12}>
              {dateTo.isValid() ? dateTo.format(formats.DATE_TIME.DEFAULT) : ''}
            </Text>
          </Box>
          <Divider orientation="vertical" h="auto" ml="xl" />
        </Flex>
      </Col>
      <Col span="content">
        <Flex justify="space-between">
          <Box w="100%">
            <Flex justify="space-between" align="center">
              <Text fz="sm" c="dimmed">
                Miles
              </Text>
              <Text fz="md" fw="bold" ml={50}>
                {load.customer_miles}
              </Text>
            </Flex>
            <Flex justify="space-between" align="center">
              <Text fz="sm" c="dimmed">
                Deadhead
              </Text>
              <Text fz="md" fw="bold" ml={50}>
                {load.destination_dead_head_miles}
              </Text>
            </Flex>
          </Box>
          <Divider orientation="vertical" h="auto" ml="xl" />
        </Flex>
      </Col>
      <Col span="auto">
        <Flex justify="space-between">
          <Box w="100%">
            <Text fz="sm" c="dimmed">
              Rate per mile
            </Text>
            <Text fz="md" fw="bold">
              {load.rate_per_mile ? `${load.rate_per_mile}/mi` : '-'}
            </Text>
          </Box>
        </Flex>
      </Col>
      <Col
        span="content"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          height: '70px',
          backgroundColor: theme.colors.electricGreen[9],
          color: 'white',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px'
        })}
      >
        <Title order={2}>
          <NumericFormat
            value={load.rate}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </Title>
      </Col>
    </Grid>
  );
};
