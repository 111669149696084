import { ReactElement } from 'react';
import { createStyles } from '@mantine/core';
import { Flex } from '../../Layout/Flex/Flex';
import { Autocomplete } from '../Autocomplete/Autocomplete';

interface Props {
  value: string;
  nothingFound: string;
  data: any;
}

const useStyles = createStyles(() => ({
  root: { width: '100%' },
  input: { borderWidth: 0 }
}));

export const AutocompleteWithoutSelect = ({
  nothingFound,
  data,
  ...props
}: Props): ReactElement => {
  const { classes } = useStyles();

  return (
    <Flex
      justify="center"
      align="center"
      p={2}
      sx={{
        width: '100%',
        outline: `1px solid #eee`,
        borderRadius: '6px'
      }}
    >
      <Autocomplete
        data={data}
        nothingFound={nothingFound}
        size="md"
        classNames={{
          root: classes.root,
          input: classes.input
        }}
        {...props}
      />
    </Flex>
  );
};
