const formats: any = {
  DATE_TIME: {
    DEFAULT: 'MMM D, H:mm z'
  },
  DATE: {
    DEFAULT: 'LL',
    MONTH: {
      DEFAULT: 'MMM D'
    },
    WEEKDAY: {
      DEFAULT: 'ddd'
    },
    DAY: {
      DEFAULT: 'MM/DD/YYYY'
    }
  },
  TIME: {
    DEFAULT: 'HH:mm'
  },
  ZONE: {
    DEFAULT: 'z'
  }
};

export default formats;
