class ResponseError extends Error {
  statusCode: number;
  data: any;
  constructor(props: any) {
    const { data, message, statusCode } = props;

    super(message);

    this.name = 'ResponseError';
    this.statusCode = statusCode;
    this.data = data;
  }
}

export default ResponseError;
