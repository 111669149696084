export type ActionsType =
  | 'SET'
  | 'ADD'
  | 'SORT'
  | 'RESET'
  | 'UPDATE'
  | 'UPDATE_EXISTING';

export interface ActionProps {
  type: ActionsType;
  payload: any;
}

type StateProps = any;

export const offersReducer = (state: StateProps, action: ActionProps) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      return [...payload];
    case 'ADD':
      return [...state, ...payload];
    case 'RESET':
      return [...payload];
    case 'UPDATE':
      payload.forEach((element: any) => {
        const found = state.find(
          (stateElement: any) => stateElement.id === element.id
        );

        if (found) {
          const index = state.indexOf(found);
          state[index] = element;
        } else {
          state.push(element);
        }
      });
      return [...state];
    case 'UPDATE_EXISTING':
      payload.forEach((element: any) => {
        const found = state.find(
          (stateElement: any) => stateElement.id === element.id
        );

        if (found) {
          const index = state.indexOf(found);
          state[index] = element;
        }
      });
      return [...state];
    case 'SORT': {
      const { sortValue } = payload;

      switch (sortValue) {
        case 'deadhead':
          break;
        case 'rpm':
          {
            const sorted = [...state].sort(
              (a: any, b: any) =>
                b.load.content.rate_per_mile - a.load.content.rate_per_mile
            );
            return sorted;
          }
          break;
        case 'priceLow':
          {
            const sorted = [...state].sort(
              (a: any, b: any) => a.load.content.rate - b.load.content.rate
            );
            return sorted;
          }
          break;
        case 'priceHigh':
          {
            const sorted = [...state].sort(
              (a: any, b: any) => b.load.content.rate - a.load.content.rate
            );
            return sorted;
          }
          break;
        case 'deadheadPlusRatePerMile':
          break;
        case 'puDateAccending':
          break;
        case 'sourceAZ':
          {
            const sorted = [...state].sort((a: any, b: any) =>
              a.load.content.source.localeCompare(b.load.content.source)
            );
            return sorted;
          }
          break;
        case 'sourceZA':
          {
            const sorted = [...state].sort((a: any, b: any) =>
              b.load.content.source.localeCompare(a.load.content.source)
            );
            return sorted;
          }
          break;
      }

      return state;
    }

    default:
      throw new Error('loadsListReducer issue');
  }
};
