import { ReactElement, useState } from 'react';
import { Space } from '../../components/Layout/Space/Space';
import { Row } from './Row/Row';
import { LoadProps } from '../../context';
import { OfferModal } from '../../modals/OfferModal/OfferModal';

interface Props {
  type?: 'booked' | 'offers';
  offers: LoadProps[];
  currentSearch: any;
}

export const MyOffersList = ({
  type,
  offers,
  currentSearch
}: Props): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOffer, setModalOffer] = useState<any>(null);

  const openModal = (offer: any) => {
    setModalOffer(offer);
    setModalOpen(true);
  };

  const renderRow = (offer: any) => (
    <Row
      key={offer.id}
      currentSearch={currentSearch}
      type={type}
      offer={offer}
      onModalOpen={openModal}
    />
  );

  return (
    <>
      <Space h="md" />
      {modalOpen && (
        <OfferModal
          key="myOfferModal"
          offer={modalOffer}
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
        />
      )}
      {offers.map(renderRow)}
    </>
  );
};
