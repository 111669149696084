import { ReactElement, useContext, useEffect, useState } from 'react';
import { Modal } from '../../components/Overlays/Modal/Modal';
import { useForm } from '@mantine/form';
import { AutocompleteItem, Center, MultiSelect, Title } from '@mantine/core';
import { Col, Grid } from '../../components/Layout/Grid/Grid';
import { Text } from '../../components/Typography/Typography';
import { AutocompleteWithSelect } from '../../components/Inputs/AutocompleteWithSelect/AutocompleteWithSelect';
import {
  autocomplete,
  geocode,
  createSavedSearch,
  updateSavedSearch
} from '../../api/api';
import { Image } from '../../components/DataDisplay/Image/Image';
import ellipseIcon from '../../assets/icons/elipse.svg';
import pointerIcon from '../../assets/icons/pointer.svg';
import { TimeInput } from '@mantine/dates';
import { Checkbox } from '../../components/Inputs/Checkbox/Checkbox';
import { NumberInput } from '../../components/Inputs/NumberInput/NumberInput';
import { Divider } from '../../components/Miscellaneous/Divider/Divider';
import { Group } from '../../components/Layout/Group/Group';
import { Select } from '../../components/Inputs/Select/Select';
import { RangeSlider } from '../../components/Inputs/RangeSlider/RangeSlider';
import { EquipmentSizeSelector } from '../../components/Inputs/EquipmentSizeSelector/EquipmentSizeSelector';
import { Space } from '../../components/Layout/Space/Space';
import { Flex } from '../../components/Layout/Flex/Flex';
import { Button } from '../../components/Buttons/Button/Button';
import { AppContext, SavedSearchFilterProps } from '../../context';
import moment from 'moment/moment';

const timeToDate = (time: any) => {
  if (!time) {
    return new Date();
  }
  const now = new Date();
  const hoursAndMinutes = time.split(':');

  const date = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hoursAndMinutes[0],
    hoursAndMinutes[1]
  );
  return date;
};

const loadedMilesMarks = [
  { value: 0, label: '0' },
  { value: 10000, label: '10000' }
];

const weightMarks = [
  { value: 0, label: '0' },
  { value: 80000, label: '80000' }
];

const mapToAutocompleteItem = (data: any = {}) => {
  return {
    value: data.description,
    name: data.description,
    data: { placeId: data.place_id }
  };
};

interface Props {
  search: SavedSearchFilterProps;
  savedSearchId: number | null;
  opened: boolean;
  close: () => void;
}

export const SavedSearchModal = ({
  search,
  savedSearchId,
  opened,
  close
}: Props): ReactElement => {
  const { setSavedSearches } = useContext(AppContext);
  const [originLocationData, setOriginLocationData] = useState<any>([]);
  const [destinationLocationData, setDestinationLocationData] = useState<any>(
    []
  );
  const [timeFrom, setTimeFrom] = useState<Date | undefined>(
    timeToDate(search.time_from)
  );
  const [timeTo, setTimeTo] = useState<Date | undefined>(
    timeToDate(search.time_to)
  );

  const form = useForm({
    initialValues: {
      ...search,
      time_from: timeToDate(search.time_from),
      time_to: timeToDate(search.time_to)
    }
  });

  const handleDestinationSelect = async (
    type: string,
    value: AutocompleteItem
  ) => {
    const addressData = ((await geocode(value.data.placeId))?.results || [])[0];

    if (!addressData) {
      return;
    }

    form.setFieldValue(
      `${type}.location.address.formatted_address`,
      addressData.formatted_address
    );
    form.setFieldValue(
      `${type}.location.coordinates.latitude`,
      addressData.geometry?.location?.lat
    );
    form.setFieldValue(
      `${type}.location.coordinates.longitude`,
      addressData.geometry?.location?.lng
    );
  };

  const isLoadTypeSelected = (value: string) => {
    return (form.values.load_types || []).includes(value);
  };

  const handleOnSelectLoadType = (value: string, checked: boolean) => {
    const newValues = [...form.values.load_types];
    const selectedIndex = newValues.indexOf(value);

    if (checked) {
      selectedIndex == -1 && newValues.push(value);
    } else {
      selectedIndex > -1 && newValues.splice(selectedIndex, 1);
    }

    form.setFieldValue('load_types', newValues);
  };

  const isOriginPresent = () => {
    return form.values.origin.location.address.formatted_address &&
      form.values.origin.location.coordinates.latitude &&
      form.values.origin.location.coordinates.longitude
      ? false
      : true;
  };

  const handleAddressLookup = (e: any) =>
    autocomplete(e)
      .then((e: any) => e?.predictions || [])
      .catch(() => []);

  const resetForm = () => {
    form.reset();
    setTimeFrom(timeToDate(search.time_from));
    setTimeTo(timeToDate(search.time_to));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (form.values.origin.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.origin.location.address.formatted_address
        );
        setOriginLocationData(results.map(mapToAutocompleteItem));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.origin.location.address.formatted_address]);

  const handleEmptyAddress = (type: string, value: string) => {
    if (value) {
      return;
    }
    form.setFieldValue(`${type}.location.coordinates.latitude`, '');
    form.setFieldValue(`${type}.location.coordinates.longitude`, '');
  };

  useEffect(() => {
    handleEmptyAddress(
      'destination',
      form.values.destination.location.address.formatted_address
    );
    const delayDebounceFn = setTimeout(async () => {
      if (form.values.destination.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.destination.location.address.formatted_address
        );
        setDestinationLocationData(results.map(mapToAutocompleteItem));
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.destination.location.address.formatted_address]);

  return (
    <>
      <Modal
        centered
        size="xl"
        opened={opened}
        onClose={() => {
          close();
          resetForm();
        }}
        title={
          <Center>
            <Title order={3}>General filters</Title>
          </Center>
        }
      >
        <form
          onSubmit={form.onSubmit((v) => {
            const time_from = moment(new Date(v.time_from)).format('HH:mm');
            const time_to = moment(new Date(v.time_to)).format('HH:mm');
            const data: any = { ...v, time_from, time_to };

            !savedSearchId &&
              createSavedSearch(data).then((response: any) => {
                if (response) {
                  setSavedSearches({
                    type: 'ADD',
                    payload: response
                  });
                  resetForm();
                  close();
                }
              });
            savedSearchId &&
              updateSavedSearch(savedSearchId, data).then((response: any) => {
                if (response) {
                  setSavedSearches({
                    type: 'UPDATE',
                    payload: response
                  });
                  resetForm();
                  close();
                }
              });
          })}
        >
          <Grid mx="lg">
            <Col span={6}>
              <Title order={4} my="xs">
                Source
              </Title>
              <MultiSelect
                data={[
                  { label: 'Uber', value: 'uber' },
                  { label: 'Coyote', value: 'coyote' },
                  { label: 'Echo global', value: 'echo_global' },
                  { label: 'C.H Robinson', value: 'ch_robinson' },
                  { label: 'Newtrul', value: 'newtrul' },
                  { label: 'Loadsmart', value: 'loadsmart' }
                ]}
                {...form.getInputProps('sources')}
                size="md"
                mb="xs"
              />
            </Col>
            <Col span={6}>
              <Title order={4} my="xs">
                Equipment type
              </Title>
              <MultiSelect
                data={[
                  { label: 'Dry van', value: 'dry_van' },
                  { label: 'Truck', value: 'truck' },
                  { label: 'Towing', value: 'towing' },
                  { label: 'Truck and tow', value: 'truckAndTow' },
                  { label: 'Trailer', value: 'trailer' }
                ]}
                {...form.getInputProps('equipment_types')}
                size="md"
                mb="xs"
              />
            </Col>
            <Col span={12}>
              <Grid>
                <Col span={6}>
                  <Text fz="sm" c="dimmed" mb="xs">
                    From
                  </Text>
                  <AutocompleteWithSelect
                    rangeName="origin.radius"
                    nothingFound="No options"
                    radius={form.values.origin.radius}
                    filter={(val: any, filter: any) => true}
                    data={originLocationData}
                    onItemSubmit={(value: any) => {
                      handleDestinationSelect('origin', value);
                    }}
                    onRangeChange={form.setFieldValue}
                    icon={<Image src={ellipseIcon} width={12} height={12} />}
                    iconWidth={24}
                    {...form.getInputProps(
                      'origin.location.address.formatted_address'
                    )}
                  />
                </Col>
                <Col span={6}>
                  <Text fz="sm" c="dimmed" mb="xs">
                    To
                  </Text>
                  <AutocompleteWithSelect
                    rangeName="destination.radius"
                    nothingFound="No options"
                    radius={form.values.destination.radius}
                    data={destinationLocationData}
                    filter={(val: any, filter: any) => true}
                    onRangeChange={form.setFieldValue}
                    onItemSubmit={(value: any) => {
                      handleDestinationSelect('destination', value);
                    }}
                    icon={<Image src={pointerIcon} width={12} height={16} />}
                    iconWidth={24}
                    {...form.getInputProps(
                      'destination.location.address.formatted_address'
                    )}
                  />
                </Col>
                <Col span={3}>
                  <TimeInput
                    required
                    label="Time From"
                    value={timeFrom}
                    onChange={(v) => {
                      setTimeFrom(v);
                      form.setFieldValue('time_from', v);
                    }}
                  />
                </Col>
                <Col span={3}>
                  <TimeInput
                    required
                    label="Time To"
                    value={timeTo}
                    onChange={(v) => {
                      setTimeTo(v);
                      form.setFieldValue('time_to', v);
                    }}
                  />
                </Col>
              </Grid>
            </Col>
            <Col span={12}>
              <Divider />
            </Col>
            <Col span={12}>
              <Grid>
                <Col span={4}>
                  <Title order={4} my="xs">
                    Load type
                  </Title>
                  <Group mb="xs">
                    <Checkbox
                      value="FTL"
                      label="FTL"
                      checked={isLoadTypeSelected('FTL')}
                      onChange={(event) => {
                        handleOnSelectLoadType(
                          'FTL',
                          event.currentTarget.checked
                        );
                      }}
                      radius="sm"
                    />
                    <Checkbox
                      value="LTL"
                      label="LTL"
                      checked={isLoadTypeSelected('LTL')}
                      onChange={(event) => {
                        handleOnSelectLoadType(
                          'LTL',
                          event.currentTarget.checked
                        );
                      }}
                      radius="sm"
                    />
                  </Group>
                </Col>
                <Col span={4}>
                  <Title order={4} my="xs">
                    Stops
                  </Title>
                  <Select
                    data={[
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 }
                    ]}
                    {...form.getInputProps('stops')}
                    size="md"
                    mb="xs"
                  />
                </Col>
                <Col span={4}>
                  <Title order={4} my="xs">
                    RPM from
                  </Title>
                  <NumberInput
                    required
                    defaultValue={0.1}
                    precision={2}
                    min={0.1}
                    step={0.01}
                    type="number"
                    {...form.getInputProps('rpm_from')}
                    size="md"
                  />
                </Col>
              </Grid>
            </Col>
            <Col span={12}>
              <Divider />
            </Col>
            <Col span={12}>
              <Title order={4} my="xs">
                Loaded miles
              </Title>
              <RangeSlider
                min={0}
                max={10000}
                marks={loadedMilesMarks}
                value={[
                  form.values.loaded_miles.min,
                  form.values.loaded_miles.max
                ]}
                onChange={(value: [number, number]) => {
                  const [from, to] = value;
                  form.setFieldValue('loaded_miles.min', from);
                  form.setFieldValue('loaded_miles.max', to);
                }}
              />
            </Col>
            <Col span={12}>
              <Divider mt="xl" />
            </Col>
            <Col span={12}>
              <Title order={4} my="xs">
                Load weight (Lbs)
              </Title>
              <RangeSlider
                min={0}
                max={80000}
                marks={weightMarks}
                value={[
                  form.values.load_weight.min,
                  form.values.load_weight.max
                ]}
                onChange={(value: [number, number]) => {
                  const [from, to] = value;
                  form.setFieldValue('load_weight.min', from);
                  form.setFieldValue('load_weight.max', to);
                }}
              />
            </Col>
            <Col span={12}>
              <Divider mt="xl" />
            </Col>
            <Col span={12}>
              <Title order={4} my="xs">
                Equipment size
              </Title>
              <EquipmentSizeSelector
                value={form.values.equipment_sizes}
                onChange={(value) => {
                  form.setFieldValue('equipment_sizes', value);
                }}
              />
            </Col>
            <Col span={12}>
              <Divider />
            </Col>
            <Col span={12} style={{ marginTop: '20px' }}>
              <Center>
                <Title order={3}>Auto search and booking filters</Title>
              </Center>
            </Col>
            <Col span={12}>
              <Grid>
                <Col span={6}>
                  <Title order={4} my="xs">
                    Type of Search
                  </Title>
                  <Checkbox
                    value="recommendations"
                    label="Show recommendations"
                    checked={form.values.recommendations}
                    onChange={(event) => {
                      form.setFieldValue(
                        'recommendations',
                        event.currentTarget.checked
                      );
                    }}
                    radius="sm"
                  />
                  <Space h="xl" />
                  <Checkbox
                    value="autobook"
                    label="Auto booking"
                    checked={form.values.autobook}
                    onChange={(event) => {
                      form.setFieldValue(
                        'autobook',
                        event.currentTarget.checked
                      );
                    }}
                    radius="sm"
                  />
                </Col>
                {form.values.autobook && (
                  <Col span={6}>
                    <Title order={4} my="xs">
                      Capacity of Lane
                    </Title>
                    <NumberInput
                      required
                      type="number"
                      {...form.getInputProps('capacity_of_lane')}
                      size="md"
                    />
                  </Col>
                )}
              </Grid>
            </Col>
            <Col span={12} my="xs">
              <Flex justify="space-between" align="center" wrap="wrap">
                <Button
                  variant="white"
                  color="black"
                  size="md"
                  disabled={!!savedSearchId}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Clear all
                </Button>
                <Button type="submit" size="md" disabled={isOriginPresent()}>
                  Save
                </Button>
              </Flex>
            </Col>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
