import { ReactElement, useContext, useEffect } from 'react';
import { Container } from '../../components/Layout/Container/Container';
import { Header } from '../../modules/Header/Header';
import { AutoBookingFiltersList } from '../../modules/AutoBookingFilters/AutoBookingsFiltersList';
import { fetchFilteredAutoBookingFilters } from '../../api/api';
import { AppContext, AutoBookingFiltersSearchProps } from '../../context';
import { Space } from '../../components/Layout/Space/Space';
import { Filter } from '../../modules/AutoBookingFilters/Filter/Filter';
import { Title } from '../../components/Typography/Typography';

export const initialValues: AutoBookingFiltersSearchProps = {
  ab_filters: 'company'
};

export const AutoBookingsFiltersPage = (): ReactElement => {
  const { autoBookingFilters, setAutoBookingFilters } = useContext(AppContext);

  useEffect(() => {
    handleOnSearch({ ab_filters: 'company' });
  }, []);

  const handleOnSearch = async (query: AutoBookingFiltersSearchProps) => {
    const formattedQuery: any = Object.assign({}, query); // copy object

    await fetchFilteredAutoBookingFilters(formattedQuery).then((results) => {
      setAutoBookingFilters({
        type: 'SET',
        payload: results || []
      });
    });
  };

  return (
    <Container fluid p={0}>
      <Header />
      <Container
        fluid
        sx={{
          minHeight: 'calc(100vh - 80px)',
          backgroundColor: '#F4F6FC',
          boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
        }}
        p={0}
      >
        <Container size="xl">
          <Space h="xl" />
          <Title order={2}>
            Auto Booking Lanes ({autoBookingFilters.length})
          </Title>
          <Space h="xl" />
          <Filter onSearch={handleOnSearch} />
          <AutoBookingFiltersList></AutoBookingFiltersList>
        </Container>
      </Container>
    </Container>
  );
};
