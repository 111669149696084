import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  paper: {
    width: '100%',
    marginLeft: '30px'
  },
  stripedPaper: {
    backgroundImage: `linear-gradient(
      45deg,
      ${theme.colors.gray[1]} 25%,
      transparent 25%,
      transparent 50%,
      ${theme.colors.gray[1]} 50%,
      ${theme.colors.gray[1]} 75%,
      transparent 75%,
      transparent
    )`,
    backgroundSize: '56px 56px'
  }
}));
