import { ReactElement } from 'react';
import { Paper } from '../../../../components/Miscellaneous/Paper/Paper';
import {
  Step,
  Stepper,
  getStopSteppertIcon
} from '../../../../components/Navigation/Stepper/Stepper';
import moment from 'moment-timezone';
import formats from '../../../../enums/formats';

export const StopsSummary = ({ stops }: any): ReactElement => {
  const renderRow = (stop: any, index: number) => {
    const date = moment.tz(
      index == 0 ? stop.time_from_local : stop.time_to_local,
      stop.time_zone
    );
    const StepperIcon = getStopSteppertIcon(stop.position, stops.length);

    return (
      <Step
        icon={<StepperIcon size={20} className={'stop-step-icon'} />}
        label={`${stop.address.full_address}`}
        description={
          date.isValid() ? date.format(formats.DATE_TIME.DEFAULT) : ''
        }
        key={index}
      >
        Step 1 content: Create an account
      </Step>
    );
  };
  return (
    <Paper shadow="sm" mb="xl" p="xl">
      <Stepper active={-1} breakpoint="sm">
        {stops.map(renderRow)}
      </Stepper>
    </Paper>
  );
};
