import { ReactElement, useEffect } from 'react';
import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { StateMultiSelect } from '../StateMultiselect/StateMultiSelect';
import { Text, Tooltip } from '../../../components/Typography/Typography';
import ellipseIcon from '../../../assets/icons/elipse.svg';
import pointerIcon from '../../../assets/icons/pointer.svg';
import { Switch } from '@mantine/core';
import { AutocompleteWithSelect } from '../../../components/Inputs/AutocompleteWithSelect/AutocompleteWithSelect';
import { Image } from '../../../components/DataDisplay/Image/Image';
import { autocomplete } from '../../../api/api';

interface Props {
  form: any;
  setDestinationLocationData: any;
  originLocationData: any;
  setOriginLocationData: any;
  clearSpecificStates: any;
  disableForm: any;
  destinationLocationData: any;
  handleDestinationSelect: any;
  setPuStatesVisible: any;
  puStatesVisible: any;
  disableSpecificStateSwitch: any;
  delStatesVisible: any;
  delStates: any;
  setDelStatesVisible: any;
  puStates: any;
  handleSpecificStatesChange: any;
}

export const Direction = ({
  form,
  setOriginLocationData,
  setDestinationLocationData,
  originLocationData,
  clearSpecificStates,
  disableForm,
  destinationLocationData,
  handleDestinationSelect,
  setPuStatesVisible,
  disableSpecificStateSwitch,
  delStates,
  delStatesVisible,
  setDelStatesVisible,
  puStatesVisible,
  handleSpecificStatesChange,
  puStates
}: Props): ReactElement => {
  const mapToAutocompleteItem = (data: any = {}) => {
    return {
      value: data.description,
      name: data.description,
      data: { placeId: data.place_id }
    };
  };

  const handleAddressLookup = (e: any) =>
    autocomplete(e)
      .then((e: any) => e?.predictions || [])
      .catch(() => []);

  const handleEmptyAddress = (type: string, value: string | null) => {
    if (value) {
      return;
    }
    form.setFieldValue(`${type}.location.coordinates.latitude`, '');
    form.setFieldValue(`${type}.location.coordinates.longitude`, '');
    form.setFieldValue(`${type}.location.address.formatted_address`, '');
    form.setFieldValue(`${type}.location.address.state_code`, '');
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (form.values.origin.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.origin.location.address.formatted_address
        );
        setOriginLocationData(results.map(mapToAutocompleteItem));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.origin.location.address.formatted_address]);

  useEffect(() => {
    handleEmptyAddress(
      'destination',
      form.values.destination.location.address.formatted_address
    );
    const delayDebounceFn = setTimeout(async () => {
      if (form.values.destination.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.destination.location.address.formatted_address
        );
        setDestinationLocationData(results.map(mapToAutocompleteItem));
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.destination.location.address.formatted_address]);

  return (
    <Col span={12}>
      <Grid>
        <Col span={6}>
          <Text fz="sm" c="dimmed" mb="xs">
            From<span style={{ color: 'red' }}> *</span>
          </Text>
          <AutocompleteWithSelect
            onKeyDown={() => {
              form.setFieldValue(`origin.location.coordinates.latitude`, '');
              form.setFieldValue(`origin.location.coordinates.latitude`, '');
              form.setFieldValue(`origin.location.address.state_code`, '');
              clearSpecificStates('origin');
            }}
            rangeName="origin.radius"
            nothingFound="No options"
            radius={form.values.origin.radius}
            filter={(val: any, filter: any) => true}
            data={originLocationData}
            onItemSubmit={(value: any) => {
              handleDestinationSelect('origin', value);
            }}
            onRangeChange={form.setFieldValue}
            icon={<Image src={ellipseIcon} width={12} height={12} />}
            iconWidth={24}
            {...form.getInputProps('origin.location.address.formatted_address')}
            validInput={
              !disableForm() &&
              !!(
                form.values.origin.location.coordinates.latitude &&
                form.values.origin.location.coordinates.longitude
              )
            }
          />
        </Col>
        <Col span={6}>
          <Text fz="sm" c="dimmed" mb="xs">
            To<span style={{ color: 'red' }}> *</span>
          </Text>
          <AutocompleteWithSelect
            onKeyDown={() => {
              form.setFieldValue(
                `destination.location.coordinates.latitude`,
                ''
              );
              form.setFieldValue(
                `destination.location.coordinates.latitude`,
                ''
              );
              form.setFieldValue(`destination.location.address.state_code`, '');
              clearSpecificStates('destination');
            }}
            rangeName="destination.radius"
            nothingFound="No options"
            radius={form.values.destination.radius}
            data={destinationLocationData}
            filter={(val: any, filter: any) => true}
            onRangeChange={form.setFieldValue}
            onItemSubmit={(value: any) => {
              handleDestinationSelect('destination', value);
            }}
            icon={<Image src={pointerIcon} width={12} height={16} />}
            iconWidth={24}
            {...form.getInputProps(
              'destination.location.address.formatted_address'
            )}
            validInput={
              !disableForm() &&
              !!(
                form.values.destination.location.coordinates.latitude &&
                form.values.destination.location.coordinates.longitude
              )
            }
          />
        </Col>
        <Col span={6}>
          <Col span={12}>
            <Switch
              {...form.getInputProps('origin.location.address.limit_enabled')}
              onChange={(e) => {
                form
                  .getInputProps('origin.location.address.limit_enabled')
                  .onChange(e);
                setPuStatesVisible(e.target.checked);
              }}
              label={
                <Tooltip
                  label={
                    'Please select states in which the search should be completed only.'
                  }
                >
                  <b>Limit to Selected States</b>
                </Tooltip>
              }
              checked={puStatesVisible}
              disabled={disableSpecificStateSwitch('origin')}
            />
          </Col>
          {puStatesVisible && (
            <Col span={12}>
              <StateMultiSelect
                onChange={handleSpecificStatesChange}
                type={'origin'}
                value={puStates}
              ></StateMultiSelect>
            </Col>
          )}
        </Col>
        <Col span={6}>
          <Col span={12}>
            <Switch
              label={
                <Tooltip
                  label={
                    'Please select states in which the search should be completed only.'
                  }
                >
                  <b>Limit to Selected States</b>
                </Tooltip>
              }
              {...form.getInputProps(
                'destination.location.address.limit_enabled'
              )}
              onChange={(e) => {
                form
                  .getInputProps('destination.location.address.limit_enabled')
                  .onChange(e);
                setDelStatesVisible(e.target.checked);
              }}
              checked={delStatesVisible}
              disabled={disableSpecificStateSwitch('destination')}
            />
          </Col>
          {delStatesVisible && (
            <Col span={12}>
              <StateMultiSelect
                onChange={handleSpecificStatesChange}
                type={'destination'}
                value={delStates}
              ></StateMultiSelect>
            </Col>
          )}
        </Col>
      </Grid>
    </Col>
  );
};
