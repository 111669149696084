import { useForm } from '@mantine/form';
import { ReactElement } from 'react';
import {
  AiOutlineCheck,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle
} from 'react-icons/ai';
import { confirmOffer, counterOffer, rejectOffer } from '../../api/api';
import subscriber from '../../api/Subscriber';
import { Button } from '../../components/Buttons/Button/Button';
import { Center } from '../../components/Layout/Center/Center';
import { Flex } from '../../components/Layout/Flex/Flex';
import { Col, Grid } from '../../components/Layout/Grid/Grid';
import { Loader } from '../../components/Loaders/Loader';
import { Modal } from '../../components/Overlays/Modal/Modal';
import { Text, Title } from '../../components/Typography/Typography';
import { ModalRow } from '../../modules/ModalRow/ModalRow';
import { useStyles } from '../MakeOfferModal/MakeOfferModal.style';
import { NumberInput } from '../../components/Inputs/NumberInput/NumberInput';
import { offerStatusMap } from '../../utils/offerStatusMap';

interface Props {
  offer?: any;
  isOpen: boolean;
  setIsOpen: (isModalOpen: boolean) => void;
}

export const OfferModal = ({
  offer,
  isOpen,
  setIsOpen
}: Props): ReactElement => {
  const { classes } = useStyles();

  const book = offer.status === 'book';
  const bid = offer.status === 'bid';
  const confirm = offer.status === 'confirm';
  const reject = offer.status === 'reject';
  const counter = offer.status === 'counter';
  const pending = offer.status === 'pending';
  const ok = offer.status === 'ok';
  const rejected = offer.status === 'rejected';
  const countered = offer.status === 'countered';
  const confirmRequired = offer.status === 'confirm_required';
  const finalOffer = offer.status === 'final_offer';

  const load = offer.load.content;

  const form = useForm({
    initialValues: {
      amount: offer.amount
    }
  });

  const rejectCurrentOffer = () => {
    rejectOffer(offer.id, subscriber.socketSessionId);
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        classNames={classes}
        opened={isOpen && offer}
        onClose={() => {
          setIsOpen(false);
        }}
        title={
          <Center>
            <Title order={3}>{offerStatusMap[offer.status]}</Title>
          </Center>
        }
        size="930px"
        centered
      >
        <ModalRow load={load} />
        {(book || bid || counter || confirm || reject || pending) && (
          <Grid gutter="xs" align="end">
            <Col span={12} mt="xl">
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  gap="xs"
                >
                  <Loader size="md" variant="dots" />
                  <Title order={5}>{offerStatusMap[offer.status]}</Title>
                  <Text size="sm" c="dimmed">
                    It might take some time to get a response. Please be
                    patient.
                  </Text>
                </Flex>
              </Col>
            </Col>
          </Grid>
        )}
        {ok && (
          <Grid gutter="xs" align="end">
            <Col span={12} mt="xl">
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  gap="xs"
                >
                  <AiOutlineCheck size={20} />
                  <Title order={5}>Thank you!</Title>
                  <Text size="sm" c="dimmed">
                    Your load was successfully booked. Please find a load number
                    below.
                  </Text>
                  <Text size="sm" c="dimmed">
                    Rate Confirmation information will come separatelly.
                  </Text>
                  <Text size="sm" c="dimmed" fw="bold">
                    Load Number: #{load.load_id}
                  </Text>
                  <Text size="sm" c="dimmed">
                    You can check your loads information anytime in section "My
                    Loads"
                  </Text>
                </Flex>
              </Col>
            </Col>
          </Grid>
        )}
        {countered && (
          <Grid>
            <Col>
              <Flex justify="center" align="center" direction="row" gap="xs">
                <form
                  onSubmit={form.onSubmit((e) => {
                    counterOffer(offer.id, e, subscriber.socketSessionId);
                    setIsOpen(false);
                  })}
                >
                  <Grid gutter="xs" align="end">
                    <Col span={12} mt="xl">
                      <Col span={12}>
                        <Flex
                          justify="center"
                          align="center"
                          direction="column"
                          gap="xs"
                        >
                          <Title order={5}>
                            Your offer has been countered!
                          </Title>
                          <NumberInput
                            icon="$"
                            type="number"
                            {...form.getInputProps('amount')}
                            size="md"
                            allowIntegersOnly
                          />
                          <Text size="sm" c="dimmed">
                            Provided counter value
                          </Text>
                        </Flex>
                      </Col>
                    </Col>
                    <Col span={12}>
                      <Flex
                        justify="center"
                        align="center"
                        direction="row"
                        gap="xs"
                      >
                        <Button
                          style={{ background: '#ffe0cc', color: '#cc5200' }}
                          type="submit"
                          size="md"
                        >
                          Counter
                        </Button>
                        <Button
                          onClick={() => {
                            confirmOffer(
                              offer.id,
                              { amount: offer.amount },
                              subscriber.socketSessionId
                            );
                            setIsOpen(false);
                          }}
                          size="md"
                        >
                          Accept counter
                        </Button>
                        <Button
                          style={{ background: '#ffd6cc', color: '#cc2900' }}
                          size="md"
                          onClick={() => {
                            rejectCurrentOffer();
                          }}
                        >
                          Reject
                        </Button>
                      </Flex>
                    </Col>
                  </Grid>
                </form>
              </Flex>
            </Col>
          </Grid>
        )}
        {rejected && (
          <Grid gutter="xs" align="end">
            <Col span={12} mt="xl">
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  gap="xs"
                >
                  <AiOutlineCloseCircle size={20} />
                  <Title order={5}>Offer has been rejected!</Title>
                </Flex>
              </Col>
            </Col>
          </Grid>
        )}
        {confirmRequired && (
          <Grid>
            <Col>
              <Flex justify="center" align="center" direction="row" gap="xs">
                <form
                  onSubmit={form.onSubmit((e) => {
                    confirmOffer(offer.id, e, subscriber.socketSessionId);
                    setIsOpen(false);
                  })}
                >
                  <Grid gutter="xs" align="end">
                    <Col span={12} mt="xl">
                      <Col span={12}>
                        <Flex
                          justify="center"
                          align="center"
                          direction="column"
                          gap="xs"
                        >
                          <AiOutlineCheck size={20} />
                          <Title order={5}>
                            Congratulations! Your offer has been approved!
                          </Title>
                          <NumberInput
                            disabled
                            icon="$"
                            type="number"
                            {...form.getInputProps('amount')}
                            size="md"
                          />
                          <Text size="sm" c="dimmed">
                            Please continue and complete booking of approved
                            offer
                          </Text>
                        </Flex>
                      </Col>
                    </Col>
                    <Col span={12}>
                      <Flex
                        justify="center"
                        align="center"
                        direction="row"
                        gap="xs"
                      >
                        <Button type="submit" size="md">
                          Complete booking
                        </Button>
                      </Flex>
                    </Col>
                  </Grid>
                </form>
              </Flex>
            </Col>
          </Grid>
        )}
        {finalOffer && (
          <Grid>
            <Col>
              <Flex justify="center" align="center" direction="row" gap="xs">
                <form
                  onSubmit={form.onSubmit((e) => {
                    confirmOffer(offer.id, e, subscriber.socketSessionId);
                    setIsOpen(false);
                  })}
                >
                  <Grid gutter="xs" align="end">
                    <Col span={12} mt="xl">
                      <Col span={12}>
                        <Flex
                          justify="center"
                          align="center"
                          direction="column"
                          gap="xs"
                        >
                          <AiOutlineExclamationCircle size={20} />
                          <Title order={5}>
                            Attention! This is your final offer!
                          </Title>
                          <NumberInput
                            disabled
                            icon="$"
                            type="number"
                            {...form.getInputProps('amount')}
                            size="md"
                          />
                          <Text size="sm" c="dimmed">
                            Please accept or reject the offer
                          </Text>
                        </Flex>
                      </Col>
                    </Col>
                    <Col span={12}>
                      <Flex
                        justify="center"
                        align="center"
                        direction="row"
                        gap="xs"
                      >
                        <Button type="submit" size="md">
                          Accept offer
                        </Button>
                        <Button
                          style={{ background: '#ffd6cc', color: '#cc2900' }}
                          size="md"
                          onClick={() => {
                            rejectCurrentOffer();
                          }}
                        >
                          Reject
                        </Button>
                      </Flex>
                    </Col>
                  </Grid>
                </form>
              </Flex>
            </Col>
          </Grid>
        )}
      </Modal>
    </>
  );
};
