import { ReactElement } from 'react';
import { Box, CloseButton, Col, Flex, Popover } from '@mantine/core';
import { Text } from '../../../components/Typography/Typography';
import { Button } from '../../../components/Buttons/Button/Button';
import { Group } from '../../../components/Layout/Group/Group';

export const DeleteFilterPopOverContent = ({
  setIsOpened,
  handleDelete
}: any): ReactElement => {
  return (
    <>
      <Popover.Dropdown>
        <Flex direction={'row-reverse'}>
          <CloseButton onClick={() => setIsOpened(false)}></CloseButton>
          <Box>
            <Col>
              <Text size="xs" align="center">
                Are you sure you want to delete this auto booking filter?
              </Text>
              <Group position="center">
                <Button style={{ marginTop: '10px' }} onClick={handleDelete}>
                  YES
                </Button>
              </Group>
            </Col>
          </Box>
        </Flex>
      </Popover.Dropdown>
    </>
  );
};
