import { DateRangePickerValue } from '@mantine/dates';
import { createContext, Dispatch } from 'react';
import { ActionProps as UserActionProps } from './reducers/usersReducer';
import { ActionProps as LoadListActionProps } from './reducers/loadsListReducer';
import { ActionProps as OfferListActionProps } from './reducers/offersReducer';
import { ActionProps as SavedSearchActionProps } from './reducers/savedSearchesReducer';
import { ActionProps as AutoBookingFiltersActionProps } from './reducers/autoBookingFiltersReducer';
import { ActionProps as RecommendedLoadsActionProps } from './reducers/recommendedLoadsReducer';

export type AddressProps = {
  city_name: string;
  country_code: string;
  facility_name: string;
  full_address: string;
  line_1: string | null;
  line_2: string | null;
  line_3: string | null;
  postal_code: string;
  state_province_code: string;
};

export type StopProps = {
  latitude: number;
  longitude: number;
  meta: any;
  position: number;
  time_from_utc: string;
  time_to_utc: string;
  time_from_local: string;
  time_to_local: string;
  time_zone: string;
  type: 'Pickup';
  address: AddressProps;
};

export type LoadProps = {
  id: number;
  isBooked: boolean | any;
  origin_dead_head_miles: any;
  aproximated_origin_dead_head_miles: any;
  customer_miles: number;
  destination_dead_head_miles: number;
  ratePerMile: number;
  rate_per_mile: number;
  load_id: number;
  load_source_url: any;
  meta: any;
  rate: number;
  source: string;
  stable_id: number;
  temp_max: number;
  temp_min: number;
  weight: number;
  stops: StopProps[];
  created_at: Date;
  ref?: any;
  odh_over_query_radius: boolean;
  odh_mismatch: boolean;
  isOffer: boolean;
};

export type SearchFormProps = {
  id?: string;
  isSaved?: boolean;
  origin: {
    reversed_geo: ReversedGeoProps | null;
    location: {
      coordinates: {
        longitude: number | null;
        latitude: number | null;
      };
      address: {
        formatted_address: string;
      };
    };
    radius: number;
  };
  destination: {
    reversed_geo?: ReversedGeoProps | null;
    location: {
      coordinates: {
        longitude: number | null;
        latitude: number | null;
      };
      address: {
        formatted_address: string;
      };
    };
    radius: number;
  };
  pickup_date: string;
  rpm_from: number;
  delivery_date: string;
  equipment_types: string[];
  equipment_sizes: number[];
  load_id: string | number | null;
  load_types: string[];
  load_weight: {
    min: number;
    max: number;
  };
  loaded_miles: {
    min: number;
    max: number;
  };
  sources: string[];
  stops: number;
  capacity_of_lane: number;
  recommendations: boolean;
  autobook: boolean;
  time_from: Date | string;
  time_to: Date | string;
  ml: boolean;
  active: boolean;
};

//export type SearchesForm = SearchFormProps[];

export type SavedSearchFilterProps = {
  id?: string;
  isSaved?: boolean;
  origin: {
    location: {
      coordinates: {
        longitude: string;
        latitude: string;
      };
      address: {
        formatted_address: string;
      };
    };
    radius: number;
  };
  destination: {
    location: {
      coordinates: {
        longitude: string;
        latitude: string;
      };
      address: {
        formatted_address: string;
      };
    };
    radius: number;
  };
  pickup_date: string;
  delivery_date: string;
  equipment_types: string[];
  equipment_sizes: number[];
  load_id: string | number | null;
  load_types: string[];
  load_weight: {
    min: number;
    max: number;
  };
  loaded_miles: {
    min: number;
    max: number;
  };
  sources: string[];
  stops: number;
  rpm_from: number;
  capacity_of_lane: number;
  recommendations: boolean;
  autobook: boolean;
  time_from: Date | string;
  time_to: Date | string;
  ml: boolean;
  active: boolean;
};

export type SavedSearchProps = {
  id: number;
  search: SavedSearchFilterProps;
};

export type SearchOfferProps = {
  statuses: string[];
  ranges: DateRangePickerValue;
  offers: string;
  sources: string[];
};

export type SearchMyLoadProps = {
  statuses: string[];
  ranges: DateRangePickerValue;
  offers: string;
  sources: string[];
};

export type RecommendedLoadsProps = {
  [key: string]: {
    search: SearchFormProps;
    loads: LoadProps;
  };
};

export type UserProps = {
  email: string;
  organization_id: number;
  offer_defaults: any;
};

export type SourceActionProps = {
  search: boolean;
  book: boolean;
  bid: boolean;
  event_hook: boolean;
};

export type ComplianceProps = {
  required: boolean;
  url: string;
};

export type SourceComplianceProps = {
  required: boolean;
  terms: ComplianceProps;
  privacy: ComplianceProps;
};

export type SourceProps = {
  [key: string]: {
    name: string;
    actions: SourceActionProps;
    compliance: SourceComplianceProps;
    load_lifetime: number;
  };
};

export type LaneScoreSearchFormProps = {
  origin_lat: string;
  origin_lon: string;
  destination_lat: string;
  destination_lon: string;
  origin_zip: string;
  destination_zip: string;
};

export type ReversedGeoProps = {
  city: string;
  state: string;
  country: string;
  postal_code: number | null;
  coordinates: {
    lat: number | null;
    lng: number | null;
  };
};

export type AutoBookingFilterProps = {
  id?: number | undefined;
  settings: {
    enabled: boolean;
    rate_conf_receiver: string;
    origin: {
      location: {
        coordinates: {
          longitude: string | null;
          latitude: string | null;
        };
        address: {
          formatted_address: string | null;
          state_code: string;
          limit_to_states: string[] | [];
          limit_enabled: false;
        };
      };
      radius: number;
      exact_time: boolean;
      time_from: Date | string;
      time_to: Date | string;
    };
    destination: {
      location: {
        coordinates: {
          longitude: string | null;
          latitude: string | null;
        };
        address: {
          state_code: string;
          formatted_address: string | null;
          limit_to_states: string[] | [];
          limit_enabled: boolean;
        };
      };
      radius: number;
      exact_time: boolean;
      time_from: Date | string;
      time_to: Date | string;
    };
    loaded_miles: {
      min: number;
      max: number;
    };
    rpm_from: number;
    capacity_of_lane: number;
    duration: { min: number; max: number };
  };
};

export type AutoBookingFiltersSearchProps = {
  ab_filters: string;
};

export interface AppContextProps {
  user: UserProps | null;
  setUser: Dispatch<UserActionProps>;
  sources: SourceProps | null;
  setSources: (sources: SourceProps | null) => void;
  loads: LoadProps[];
  setLoads: Dispatch<LoadListActionProps>;
  offers: LoadProps[];
  setOffers: Dispatch<OfferListActionProps>;
  liveOffers: LoadProps[];
  setLiveOffers: Dispatch<OfferListActionProps>;
  savedSearches: SavedSearchProps[];
  setSavedSearches: Dispatch<SavedSearchActionProps>;
  notificationOffers: LoadProps[];
  setNotificationOffers: Dispatch<OfferListActionProps>;
  recommendedLoads: RecommendedLoadsProps[];
  setRecommendedLoads: Dispatch<RecommendedLoadsActionProps>;
  autoBookingFilters: AutoBookingFilterProps[];
  setAutoBookingFilters: Dispatch<AutoBookingFiltersActionProps>;
}

export const AppContext = createContext<AppContextProps>({
  user: null,
  setUser: () => {},
  sources: null,
  setSources: () => {},
  loads: [],
  setLoads: () => {},
  offers: [],
  setOffers: () => {},
  liveOffers: [],
  setLiveOffers: () => {},
  savedSearches: [],
  setSavedSearches: () => {},
  notificationOffers: [],
  setNotificationOffers: () => {},
  recommendedLoads: [],
  setRecommendedLoads: () => {},
  autoBookingFilters: [],
  setAutoBookingFilters: () => {}
});
