import { ReactElement } from 'react';
import type { BackgroundImageProps } from '@mantine/core';
import { BackgroundImage as MantineBackgroundImage } from '@mantine/core';

export const BackgroundImage = ({
  children,
  ...rest
}: BackgroundImageProps): ReactElement => {
  return <MantineBackgroundImage {...rest}>{children}</MantineBackgroundImage>;
};
