import { ReactElement } from 'react';
import type { MediaQueryProps } from '@mantine/core';
import { MediaQuery as MantineMediaQuery } from '@mantine/core';

export const MediaQuery = ({
  children,
  ...rest
}: MediaQueryProps): ReactElement => {
  return <MantineMediaQuery {...rest}>{children}</MantineMediaQuery>;
};
