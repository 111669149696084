export type ActionsType = 'SET' | 'ADD';

export interface ActionProps {
  type: ActionsType;
  payload: any;
}

type StateProps = any;

export const recommendedLoadsReducer = (
  state: StateProps,
  action: ActionProps
) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      return [...payload];
    case 'ADD':
      return [...state, payload];
    // case 'REMOVE': {
    //   const filteredState = [...state].filter(
    //     (item: any) => item.id !== payload.id
    //   );
    //
    //   return [...filteredState];
    // }

    default:
      throw new Error('recommendedLoadsReducer issue');
  }
};
