import { ReactElement, memo } from 'react';
import { Center } from '../../components/Layout/Center/Center';
import { Modal } from '../../components/Overlays/Modal/Modal';
import { Title } from '../../components/Typography/Typography';
import { MakeOfferForm } from '../../forms/MakeOfferForm/MakeOfferForm';
import { ModalRow } from '../../modules/ModalRow/ModalRow';
import { useStyles } from './MakeOfferModal.style';
import '../../assets/styles/scss/Offers/offers-form.scss';

interface Props {
  load: any;
  isOpen: boolean;
  setIsOpen: (isFilterModalOpen: boolean) => void;
  onSubmit: (values: any) => void;
}

export const MakeOfferModal = memo(
  ({ load, isOpen, setIsOpen, onSubmit }: Props): ReactElement => {
    const { classes } = useStyles();

    return (
      <Modal
        classNames={classes}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={
          <Center>
            <Title order={3}>Make an offer</Title>
          </Center>
        }
        size="930px"
        centered
        lockScroll={false}
      >
        <ModalRow load={load} />
        <MakeOfferForm
          load={load}
          onSubmit={onSubmit}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    );
  }
);
