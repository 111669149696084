import { ReactElement, useContext } from 'react';
import { Container } from '../../components/Layout/Container/Container';
import { Header } from '../../modules/Header/Header';
import { AppContext, SearchOfferProps } from '../../context';
import { Space } from '../../components/Layout/Space/Space';
import { MyOffersList } from '../../modules/MyOffersList/MyOffersList';
import { fetchFilteredOffers } from '../../api/api';
import { Filter } from '../../modules/MyOffersList/Filter/Filter';
import moment from 'moment';

export const initialValues: SearchOfferProps = {
  statuses: [],
  ranges: [
    moment().subtract(1, 'day').utc().toDate(),
    moment().add(7, 'days').utc().toDate()
  ],
  offers: 'my',
  sources: []
};

export const MyOffersPage = (): ReactElement => {
  //const { get } = useFetch(OFFER_URL);
  const { offers, setOffers } = useContext(AppContext);
  /*
  const fetchOffersList = useCallback(async () => {
    const offers = await fetchOffers();

    setOffers({
      type: 'SET',
      payload:
        offers ||
        [
          {
          meta: {
            original_data: {
              stops: [
                {
                  facility: {
                    name: 'Point A Shipping',
                    address: {
                      line1: 'Carretera A Santa Rosa KM. 3',
                      line2: null,
                      line3: null,
                      cityName: 'Chicago',
                      postalCode: '60007',
                      countryCode: '773',
                      stateProvinceCode: 'IL'
                    },
                    geoCoordinates: {
                      latitude: 20.84265,
                      longitude: -103.73466
                    },
                    timeZoneOffset: '-05:00:00'
                  },
                  sequence: 1,
                  stopType: 'Pickup',
                  appointment: {
                    facilityOpenDateTimeUtc: '2023-03-13T11:07:23.4415215Z',
                    facilityCloseDateTimeUtc: '2023-03-16T02:07:23.4415228Z',
                    appointmentEndDateTimeUtc: '2023-03-14T14:00:00Z',
                    appointmentStartDateTimeUtc: '2023-03-14T11:00:00Z'
                  },
                  stopDetails: {
                    workType: 'Undefined',
                    stopNotes: null,
                    commodities: [
                      {
                        weight: { unit: 'Undefined', value: 0.0 },
                        description: 'Flavored Agave'
                      }
                    ],
                    stopAttributes: {
                      twic: false,
                      uiia: false,
                      blind: true,
                      hardHat: false,
                      earPlugs: false,
                      exitPass: false,
                      longPants: false,
                      noAnimals: false,
                      noWorkIns: false,
                      usCitizen: false,
                      government: false,
                      noTankTops: false,
                      safetyVest: false,
                      scaleEmpty: false,
                      scaleHeavy: false,
                      secureYard: false,
                      strictAppt: false,
                      dropAndHook: false,
                      longSleeves: false,
                      noDetention: false,
                      driverAssist: false,
                      insidePickup: false,
                      liquorPermit: false,
                      safetyGlasses: false,
                      steelToeShoes: false,
                      unpackDetrash: false,
                      closedToeShoes: false,
                      insideDelivery: false,
                      liftgatePickup: false,
                      mustSecureLoad: false,
                      englishSpeaking: false,
                      spanishSpeaking: false,
                      tradeshowPickup: false,
                      legalScaleOnSite: false,
                      liftgateDelivery: false,
                      notAllowedOnDock: false,
                      noEarlyDeliveries: false,
                      residentialPickup: false,
                      tradeshowDelivery: false,
                      dropTrailerRequired: false,
                      loadedToLegalWeight: false,
                      mustCheckInAsCoyote: false,
                      noLoadLocksOrStraps: false,
                      residentialDelivery: false,
                      escortOrTwicRequired: false,
                      inOutTimesWSignature: false,
                      noSameDayReschedules: false,
                      payLumperWCashTCheck: false,
                      prePaidLumperService: false,
                      xTankOfFuelAtShipper: 0,
                      lumperReceiptRequired: false,
                      twicRequiredNoEscorts: false,
                      onSiteParkingAvailable: false,
                      sortAndSegregatePickup: false,
                      limitedAccessPickupFarm: false,
                      mustProvideAndApplySeal: false,
                      limitedAccessPickupOther: false,
                      sortAndSegregateDelivery: false,
                      detentionAuthFormRequired: false,
                      limitedAccessDeliveryFarm: false,
                      limitedAccessPickupChurch: false,
                      limitedAccessPickupPrison: false,
                      limitedAccessPickupSchool: false,
                      overnightParkingAvailable: false,
                      limitedAccessDeliveryOther: false,
                      limitedAccessPickupAirport: false,
                      limitedAccessDeliveryChurch: false,
                      limitedAccessDeliveryPrison: false,
                      limitedAccessDeliverySchool: false,
                      limitedAccessPickupMineSite: false,
                      noTrailerDetentionOrLayover: false,
                      limitedAccessDeliveryAirport: false,
                      limitedAccessDeliveryMineSite: false,
                      limitedAccessPickupRemoteGovt: false,
                      trailerInspectionFormRequired: false,
                      dropTrailerAllowXHoursToUnload: 0,
                      limitedAccessPickupMiniStorage: false,
                      limitedAccessDeliveryRemoteGovt: false,
                      limitedAccessPickupMilitaryBase: false,
                      limitedAccessDeliveryMiniStorage: false,
                      limitedAccessDeliveryMilitaryBase: false,
                      preLoadDropTrailerXHoursInAdvance: 0,
                      limitedAccessPickupConstructionSite: false,
                      needCoyoteApprovalToUseLumperService: false,
                      limitedAccessDeliveryConstructionSite: false,
                      validUSCommercialDriversLicenseHardcopy: false
                    },
                    genericAttributes: []
                  }
                },
                {
                  facility: {
                    name: 'Point B Shipping',
                    address: {
                      line1: 'Grand Central Terminal',
                      line2: null,
                      line3: null,
                      cityName: 'New York',
                      postalCode: '10001',
                      countryCode: '718',
                      stateProvinceCode: 'NY'
                    },
                    geoCoordinates: {
                      latitude: 40.7128,
                      longitude: -74.006058
                    },
                    timeZoneOffset: '-04:00:00'
                  },
                  sequence: 2,
                  stopType: 'Delivery',
                  appointment: {
                    facilityOpenDateTimeUtc: '2023-03-15T10:07:23.4415267Z',
                    facilityCloseDateTimeUtc: '2023-03-17T01:07:23.4415274Z',
                    appointmentEndDateTimeUtc: '2023-03-16T13:07:23.4415289Z',
                    appointmentStartDateTimeUtc: '2023-03-15T13:07:23.4415281Z'
                  },
                  stopDetails: {
                    workType: 'Undefined',
                    stopNotes: null,
                    commodities: [
                      {
                        weight: { unit: 'Undefined', value: 0.0 },
                        description: 'Flavored Agave'
                      }
                    ],
                    stopAttributes: {
                      twic: false,
                      uiia: false,
                      blind: false,
                      hardHat: false,
                      earPlugs: false,
                      exitPass: false,
                      longPants: false,
                      noAnimals: false,
                      noWorkIns: false,
                      usCitizen: false,
                      government: false,
                      noTankTops: false,
                      safetyVest: false,
                      scaleEmpty: false,
                      scaleHeavy: false,
                      secureYard: false,
                      strictAppt: false,
                      dropAndHook: false,
                      longSleeves: false,
                      noDetention: false,
                      driverAssist: true,
                      insidePickup: false,
                      liquorPermit: false,
                      safetyGlasses: false,
                      steelToeShoes: false,
                      unpackDetrash: false,
                      closedToeShoes: false,
                      insideDelivery: false,
                      liftgatePickup: false,
                      mustSecureLoad: false,
                      englishSpeaking: false,
                      spanishSpeaking: false,
                      tradeshowPickup: false,
                      legalScaleOnSite: false,
                      liftgateDelivery: false,
                      notAllowedOnDock: false,
                      noEarlyDeliveries: false,
                      residentialPickup: false,
                      tradeshowDelivery: false,
                      dropTrailerRequired: false,
                      loadedToLegalWeight: false,
                      mustCheckInAsCoyote: false,
                      noLoadLocksOrStraps: false,
                      residentialDelivery: false,
                      escortOrTwicRequired: false,
                      inOutTimesWSignature: false,
                      noSameDayReschedules: false,
                      payLumperWCashTCheck: false,
                      prePaidLumperService: false,
                      xTankOfFuelAtShipper: 0,
                      lumperReceiptRequired: false,
                      twicRequiredNoEscorts: false,
                      onSiteParkingAvailable: false,
                      sortAndSegregatePickup: false,
                      limitedAccessPickupFarm: false,
                      mustProvideAndApplySeal: false,
                      limitedAccessPickupOther: false,
                      sortAndSegregateDelivery: false,
                      detentionAuthFormRequired: false,
                      limitedAccessDeliveryFarm: false,
                      limitedAccessPickupChurch: false,
                      limitedAccessPickupPrison: false,
                      limitedAccessPickupSchool: false,
                      overnightParkingAvailable: false,
                      limitedAccessDeliveryOther: false,
                      limitedAccessPickupAirport: false,
                      limitedAccessDeliveryChurch: false,
                      limitedAccessDeliveryPrison: false,
                      limitedAccessDeliverySchool: false,
                      limitedAccessPickupMineSite: false,
                      noTrailerDetentionOrLayover: false,
                      limitedAccessDeliveryAirport: false,
                      limitedAccessDeliveryMineSite: false,
                      limitedAccessPickupRemoteGovt: false,
                      trailerInspectionFormRequired: false,
                      dropTrailerAllowXHoursToUnload: 0,
                      limitedAccessPickupMiniStorage: false,
                      limitedAccessDeliveryRemoteGovt: false,
                      limitedAccessPickupMilitaryBase: false,
                      limitedAccessDeliveryMiniStorage: false,
                      limitedAccessDeliveryMilitaryBase: false,
                      preLoadDropTrailerXHoursInAdvance: 0,
                      limitedAccessPickupConstructionSite: false,
                      needCoyoteApprovalToUseLumperService: false,
                      limitedAccessDeliveryConstructionSite: false,
                      validUSCommercialDriversLicenseHardcopy: false
                    },
                    genericAttributes: []
                  }
                }
              ],
              loadId: 82400801,
              loadDetails: {
                mode: 'TL',
                rate: { value: 694.0, currencyType: 'USD' },
                weight: { unit: 'Pounds', value: 43000.0 },
                equipment: {
                  equipmentType: 'V',
                  equipmentWidth: { unit: 'Undefined', value: 0.0 },
                  equipmentHeight: { unit: 'Undefined', value: 0.0 },
                  equipmentLength: { unit: 'Undefined', value: 53.0 },
                  genericAttributes: [],
                  equipmentAttributes: {
                    chains: false,
                    eTrack: false,
                    xlCode: false,
                    airRide: false,
                    towAway: false,
                    liftGate: false,
                    noReefer: false,
                    duraplate: false,
                    foodGrade: false,
                    ventedVan: false,
                    xLoadBars: 0,
                    noLiftGate: false,
                    noRollDoor: false,
                    palletJack: false,
                    paperGrade: false,
                    xLoadLocks: 0,
                    tandemSlide: false,
                    xLoadStraps: 0,
                    xSideboards: 0,
                    doubleTrailer: false,
                    tripleTrailer: false,
                    loadOutTrailer: false,
                    translucentTop: false,
                    preCoolRequired: false,
                    rollDoorAllowed: false,
                    scaleUpToXPounds: 0,
                    spaceSaverReefer: false,
                    downloadableReefer: false,
                    functionalAirChute: false,
                    scaleUpToXKilograms: 0,
                    lightweightEquipment: false,
                    trailerXYearsOrNewer: 0,
                    trailerMustHaveAllWoodFloors: false
                  }
                },
                loadDistance: { unit: 'Miles', value: 2006.0 },
                loadAttributes: {
                  ppe: false,
                  tcr: false,
                  fast: false,
                  govt: false,
                  hvhr: false,
                  neec: false,
                  seal: true,
                  team: false,
                  twic: false,
                  uiia: false,
                  blind: false,
                  ctpat: false,
                  haccp: false,
                  liquor: false,
                  airRide: false,
                  drayage: false,
                  coyoteGo: false,
                  exitPass: false,
                  hvhrPlus: false,
                  pipOrPep: false,
                  duraplate: false,
                  powerOnly: false,
                  tradeShow: false,
                  guaranteed: false,
                  palletJack: false,
                  doubleBlind: false,
                  floodRelief: false,
                  intraMexico: false,
                  partialLoad: false,
                  bondedCarrier: false,
                  doubleTrailer: false,
                  overDimension: false,
                  tripleTrailer: false,
                  disasterRelief: false,
                  trailerControl: false,
                  englishSpeaking: false,
                  crossBorderMexico: false,
                  hazmatEndorsement: false,
                  protectFromFreeze: false,
                  tankerEndorsement: false,
                  transloadByCoyote: false,
                  transloadByCustomer: false,
                  fdaClearanceRequired: false,
                  techTrackingRequired: false,
                  borderCrossingByCoyote: false,
                  borderCrossingByCustomer: false,
                  secondaryBorderInspection: false,
                  noCameraPhonePhotosOfPaperwork: false,
                  repairReceiptRequiredForBreakdowns: false
                },
                genericAttributes: [],
                temperatureSettings: {
                  maximumTemperature: { unit: 'Fahrenheit', value: 7.0 },
                  minimumTemperature: { unit: 'Fahrenheit', value: 5.0 },
                  preCoolTemperature: { unit: 'Fahrenheit', value: 10.0 }
                }
              }
            }
          },
          rate: 694,
          stops: [
            {
              meta: {},
              type: 'Pickup',
              address: {
                line_1: 'Carretera A Santa Rosa KM. 3',
                line_2: null,
                line_3: null,
                city_name: 'Chicago',
                postal_code: '60007',
                country_code: '773',
                full_address: 'Carretera A Santa Rosa KM. 3, Chicago, IL 60007',
                facility_name: 'Point A Shipping',
                state_province_code: 'IL'
              },
              latitude: 20.84265,
              position: 1,
              longitude: -103.73466,
              time_zone: 'America/Mexico_City',
              time_to_utc: '2023-03-14T11:00:00Z',
              time_from_utc: '2023-03-14T11:00:00Z',
              time_to_local: '2023-03-14T05:00:00.000-06:00',
              time_from_local: '2023-03-14T05:00:00.000-06:00'
            },
            {
              meta: {},
              type: 'Delivery',
              address: {
                line_1: 'Grand Central Terminal',
                line_2: null,
                line_3: null,
                city_name: 'New York',
                postal_code: '10001',
                country_code: '718',
                full_address: 'Grand Central Terminal, New York, NY 10001',
                facility_name: 'Point B Shipping',
                state_province_code: 'NY'
              },
              latitude: 40.7128,
              position: 2,
              longitude: -74.006058,
              time_zone: 'America/New_York',
              time_to_utc: '2023-03-15T13:07:23.4415281Z',
              time_from_utc: '2023-03-15T13:07:23.4415281Z',
              time_to_local: '2023-03-15T09:07:23.441-04:00',
              time_from_local: '2023-03-15T09:07:23.441-04:00'
            }
          ],
          source: 'coyote',
          weight: 43000.0,
          load_id: 82400801,
          contacts: {
            email: 'ryan.mcaleenan@coyote.com',
            phone: '847-235-7597',
            position: null,
            full_name: 'Ryan McAleenan'
          },
          temp_max: 7.0,
          temp_min: 5.0,
          load_type: 'FTL',
          stable_id: 82400801,
          cargo_value: null,
          commodities: ['Flavored Agave'],
          rate_per_mile: 0.35,
          customer_miles: 2006.0,
          equipment_data: {
            types: ['DRY VAN'],
            width: 0.0,
            height: 0.0,
            length: 53.0
          },
          packaging_type: null,
          speciality_equipment: null,
          origin_dead_head_miles: null,
          destination_dead_head_miles: null
        }
        ]
    });
  }, []);

  useEffect(() => {
    fetchOffersList();
  }, []);
  */

  const handleOnSearch = async (query: SearchOfferProps, sort: string) => {
    const formattedQuery: any = Object.assign({}, query); // copy object
    const start = formattedQuery.ranges[0];
    const end = formattedQuery.ranges[1];

    formattedQuery.ranges = [
      start ? moment(start).format('YYYY-MM-DD') : null,
      end ? moment(end).format('YYYY-MM-DD') : null
    ];

    await fetchFilteredOffers(formattedQuery)
      .then((offers) => {
        setOffers({
          type: 'SET',
          payload: offers || []
        });
      })
      .finally(() => {
        setOffers({
          type: 'SORT',
          payload: {
            sortValue: sort
          }
        });
      });
  };

  const handleSort = (value: string) => {
    setOffers({
      type: 'SORT',
      payload: {
        sortValue: value
      }
    });
  };

  return (
    <Container fluid p={0}>
      <Header />
      <Container
        fluid
        sx={{
          minHeight: 'calc(100vh - 80px)',
          backgroundColor: '#F4F6FC',
          boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
        }}
        p={0}
      >
        <Container size="xl">
          <Space h="xl" />
          <Filter
            initialValues={initialValues}
            onSearch={handleOnSearch}
            onSort={handleSort}
          />
          <MyOffersList type="offers" offers={offers} currentSearch={null} />
        </Container>
      </Container>
    </Container>
  );
};
