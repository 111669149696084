import { Col, Grid } from '../../../components/Layout/Grid/Grid';
import { Tooltip } from '../../../components/Typography/Typography';
import { FaRegClock } from 'react-icons/fa';
import { ReactElement } from 'react';
import { Switch } from '@mantine/core';
import { TimeInput } from '@mantine/dates';

interface Props {
  timeFrom: any;
  delTimeFrom: any;
  timeTo: any;
  delTimeTo: any;
  setDelTimeTo: (e: any) => void;
  setDelTimeFrom: (e: any) => void;
  setTimeFrom: (e: any) => void;
  setTimeTo: (e: any) => void;
  form: any;
}

export const Times = ({
  timeFrom,
  delTimeFrom,
  timeTo,
  delTimeTo,
  setDelTimeTo,
  setDelTimeFrom,
  setTimeFrom,
  setTimeTo,
  form
}: Props): ReactElement => {
  return (
    <>
      <Col span={12} my="xs">
        <Grid>
          <Col span={6}>
            <TimeInput
              label="PU Time From"
              value={timeFrom}
              format="12"
              required
              onChange={(v) => {
                setTimeFrom(v);
                form.setFieldValue('origin.time_from', v);
              }}
              icon={<FaRegClock />}
            />
          </Col>

          <Col span={6}>
            <TimeInput
              required
              label="PU Time To"
              value={timeTo}
              format="12"
              onChange={(v) => {
                setTimeTo(v);
                form.setFieldValue('origin.time_to', v);
              }}
              icon={<FaRegClock />}
            />
          </Col>

          <Col span={12}>
            <Switch
              label={
                <Tooltip
                  label={
                    <p>
                      Enable this option to include appointments at specific
                      times within your selected range <br></br>For example, if
                      selected from 11:00 to 12:00, appointments exactly at
                      11:30 will also be included.
                    </p>
                  }
                >
                  <b>Include Exact Appointment Time</b>
                </Tooltip>
              }
              checked={form.getInputProps('origin.exact_time').value}
              {...form.getInputProps('origin.exact_time')}
            />
          </Col>
        </Grid>
      </Col>

      <Col span={12} my="xs">
        <Grid>
          <Col span={6}>
            <TimeInput
              label="DEL Time From"
              value={delTimeFrom}
              format="12"
              required
              onChange={(v) => {
                setDelTimeFrom(v);
                form.setFieldValue('destination.time_from', v);
              }}
              icon={<FaRegClock />}
            />
          </Col>

          <Col span={6}>
            <TimeInput
              required
              label="DEL Time To"
              value={delTimeTo}
              format="12"
              onChange={(v) => {
                setDelTimeTo(v);
                form.setFieldValue('destination.time_to', v);
              }}
              icon={<FaRegClock />}
            />
          </Col>
          <Col span={12}>
            <Switch
              label={
                <Tooltip
                  label={
                    <p>
                      Enable this option to include appointments at specific
                      times within your selected range <br></br>For example, if
                      selected from 11:00 to 12:00, appointments exactly at
                      11:30 will also be included.
                    </p>
                  }
                >
                  <b>Include Exact Appointment Time</b>
                </Tooltip>
              }
              checked={form.getInputProps('destination.exact_time').value}
              {...form.getInputProps('destination.exact_time')}
            />
          </Col>
        </Grid>
      </Col>
    </>
  );
};
