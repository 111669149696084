import { ReactElement } from 'react';
import type { UnstyledButtonProps } from '@mantine/core';
import { UnstyledButton as MantineUnstyledButton } from '@mantine/core';

interface Props extends UnstyledButtonProps {
  onClick?: () => void;
}

export const UnstyledButton = ({ children, ...rest }: Props): ReactElement => {
  return <MantineUnstyledButton {...rest}>{children}</MantineUnstyledButton>;
};
