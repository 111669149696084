import React, { ReactElement, memo, useRef, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Image } from '../../../components/DataDisplay/Image/Image';
import {
  Text,
  Title,
  Tooltip
} from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Preview } from '../Preview/Preview';
import { ActionIcon } from '../../../components/Buttons/ActionIcon/ActionIcon';
import { Space } from '../../../components/Layout/Space/Space';
import { LoadProps } from '../../../context';
import { Divider } from '../../../components/Miscellaneous/Divider/Divider';
import { Box } from '../../../components/Miscellaneous/Box/Box';
import { reportLoadView } from '../../../api/api';
import { formattedDestinationTime, getLogo } from '../../../utils/utils';
import { MediaQuery } from '../../../components/Layout/MediaQuery/MediaQuery';
import type { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { setPreviewLoads } from '../../../slices/rowSlice';
import { IoMdWarning } from 'react-icons/io';
import { offerStatusMap } from '../../../utils/offerStatusMap';
import { useStyles } from './Row.style';

interface Props {
  rerenderList?: () => void;
  getListScrollOffset?: () => number | null;
  listRef?: React.MutableRefObject<any>;
  loadListIndex?: number;
  type?: 'booked' | 'offers' | 'modal' | 'bidsLive';
  load: LoadProps;
  statusChanged?: boolean;
  currentSearch?: any;
  offer_status?: string;
  offer?: any;
  onModalOpen?: (offer: any) => void;
}

interface OriginDeadHeadMilesProps {
  load: LoadProps;
  search: any;
}

const SEARCH_RADIUS_VALID_MISMATCH_FACTOR = 0.1;

const OriginDeadHeadMiles: React.FC<OriginDeadHeadMilesProps> = ({
  load,
  search
}) => {
  const isValuePresent = (value: number): boolean => value > 0 || value === 0;

  const sourceMilesPresent = isValuePresent(load.origin_dead_head_miles);
  const aproximatedMilesPresent = isValuePresent(
    load.aproximated_origin_dead_head_miles
  );

  const calculateOdhValue = (): number | null => {
    if (sourceMilesPresent) {
      return load.origin_dead_head_miles;
    }
    if (aproximatedMilesPresent) {
      return load.aproximated_origin_dead_head_miles;
    }
    return null;
  };

  const odhValue = useMemo(() => {
    return calculateOdhValue();
  }, [load]);
  const exceedsRadius = load.odh_over_query_radius;
  const odhMismatch = load.odh_mismatch;

  return (
    <Text fz="md" fw="bold" ml={50}>
      {sourceMilesPresent && odhValue}
      {!sourceMilesPresent && aproximatedMilesPresent && `∽${odhValue}`}
      {exceedsRadius && <RadiusExceededWarning />}
      {!exceedsRadius && odhMismatch && (
        <MilesMismatchWarning
          source={load.origin_dead_head_miles}
          approximated={load.aproximated_origin_dead_head_miles}
        />
      )}
    </Text>
  );
};

const RadiusExceededWarning = () => (
  <Tooltip label="ODH is higher than origin search radius">
    <div style={{ display: 'inline-block', cursor: 'pointer' }}>
      <IoMdWarning style={{ fill: 'red', verticalAlign: 'text-top' }} />
    </div>
  </Tooltip>
);

const MilesMismatchWarning: React.FC<{
  source: number;
  approximated: number;
}> = ({ source, approximated }) => (
  <Tooltip
    label={'Mismatch between approximated and source provided ODH detected.'}
  >
    <div style={{ display: 'inline-block', cursor: 'pointer' }}>
      <IoMdWarning style={{ fill: 'red', verticalAlign: 'text-top' }} />
    </div>
  </Tooltip>
);

export const Row = memo(
  ({
    rerenderList,
    getListScrollOffset,
    loadListIndex,
    type,
    load,
    statusChanged,
    currentSearch,
    offer_status,
    offer,
    onModalOpen
  }: Props): ReactElement => {
    const isPreview = useSelector(
      (state: RootState) => state.rowReducer.openedPreviewLoads[load.stable_id]
    );

    const openedPreviewLoads = useSelector(
      (state: RootState) => state.rowReducer.openedPreviewLoads
    );

    const arrowIconRef = useRef<any>(null);
    const rowContainerRef = useRef<any>(null);
    const { stops } = load;
    const [firstStop] = stops;
    const [lastStop] = [...stops].reverse();
    const isModal = type === 'modal';
    const isBidsLive = type === 'bidsLive';
    const dispatch = useDispatch();
    const { classes } = useStyles();

    return (
      <div
        style={{ height: '100%', paddingBottom: '20px' }}
        ref={rowContainerRef}
      >
        <Paper
          p="xs"
          className={(load.isBooked || load.isOffer) && classes.stripedPaper}
          sx={{
            height: '100%',
            backgroundColor: isBidsLive
              ? statusChanged
                ? '#fff5e3'
                : 'white'
              : 'white',
            overflow: 'hidden',
            border: isBidsLive
              ? statusChanged
                ? '1px solid #dfaa4b8a'
                : '1px solid #dad8d8'
              : ''
          }}
        >
          <Grid gutter="xl" align="center">
            <Col
              span="content"
              sx={{ minHeight: '100px', display: 'flex', alignItems: 'center' }}
            >
              <Image
                src={getLogo(load.source)}
                alt="Sample logo"
                width={50}
                height="auto"
              />
            </Col>
            <Col span={3}>
              <Flex justify="space-between" align="center">
                <Box w="100%" ml="xl">
                  <Flex align="center">
                    <Image
                      src="src/assets/icons/elipse.svg"
                      width={12}
                      height={12}
                      ml={-16}
                      mr={16}
                    />
                    <div>
                      <Text fz="md" fw="bold" sx={{ lineHeight: 'normal' }}>
                        {firstStop.address.city_name?.length > 0
                          ? `${firstStop.address.city_name}, ${firstStop.address.postal_code}, ${firstStop.address.state_province_code}`
                          : firstStop.address.full_address}
                      </Text>
                    </div>
                  </Flex>
                  <Text fz="sm" c="dimmed" ml={12}>
                    {formattedDestinationTime(
                      firstStop.time_from_local,
                      firstStop.time_to_local,
                      firstStop.time_zone
                    )}
                  </Text>
                </Box>
                <MediaQuery smallerThan="xl" styles={{ display: 'none' }}>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </MediaQuery>
              </Flex>
            </Col>
            <Col span={3}>
              <Flex justify="space-between">
                <Box w="100%" ml="xl">
                  <Flex align="center">
                    <Image
                      src="src/assets/icons/pointer.svg"
                      width={12}
                      height={16}
                      ml={-16}
                      mr={16}
                    />
                    <div>
                      <Text fz="md" fw="bold" sx={{ lineHeight: 'normal' }}>
                        {lastStop.address.city_name?.length > 0
                          ? `${lastStop.address.city_name}, ${lastStop.address.postal_code}, ${lastStop.address.state_province_code}`
                          : lastStop.address.full_address}
                      </Text>
                    </div>
                  </Flex>
                  <Text fz="sm" c="dimmed" ml={12}>
                    {formattedDestinationTime(
                      lastStop.time_from_local,
                      lastStop.time_to_local,
                      lastStop.time_zone
                    )}
                  </Text>
                </Box>
                <MediaQuery smallerThan="xl" styles={{ display: 'none' }}>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </MediaQuery>
              </Flex>
            </Col>
            {!isBidsLive && (
              <Col
                span="content"
                sx={(theme) => ({
                  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                    display: 'none'
                  }
                })}
              >
                <Flex justify="space-between">
                  <Box w="100%">
                    <Flex justify="space-between" align="center">
                      <Text fz="sm" c="dimmed">
                        Miles
                      </Text>
                      <Text fz="md" fw="bold" ml={50}>
                        {load.customer_miles}
                      </Text>
                    </Flex>
                    <Flex justify="space-between" align="center">
                      <Text fz="sm" c="dimmed">
                        <Tooltip label={'Origin deadhead miles'}>
                          <div>ODH</div>
                        </Tooltip>
                      </Text>
                      <OriginDeadHeadMiles load={load} search={currentSearch} />
                    </Flex>
                  </Box>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </Flex>
              </Col>
            )}
            {!isModal && !isBidsLive && (
              <Col
                span="content"
                sx={(theme) => ({
                  [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                    display: 'none'
                  }
                })}
              >
                <Flex justify="space-between">
                  <Box w="100%">
                    <Text fz="sm" c="dimmed">
                      Weight
                    </Text>
                    <Text fz="md" fw="bold">
                      {load.weight} lbs
                    </Text>
                  </Box>
                  <Divider orientation="vertical" h="auto" ml="xl" />
                </Flex>
              </Col>
            )}
            {!isBidsLive && (
              <>
                <Col
                  span={1}
                  sx={(theme) => ({
                    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
                      display: 'none'
                    }
                  })}
                >
                  <Flex justify="space-between">
                    <Box w="100%">
                      <Text fz="sm" c="dimmed">
                        RPM
                      </Text>
                      <Text fz="md" fw="bold">
                        {load.rate_per_mile ? `$${load.rate_per_mile}/mi` : '-'}
                      </Text>
                    </Box>
                    <Divider orientation="vertical" h="auto" ml="xl" />
                  </Flex>
                </Col>
                <Col span="auto">
                  <Box
                    sx={{
                      minWidth: '6rem',
                      display: 'flex',
                      columnGap: '1rem',
                      justifyContent: 'end',
                      alignItems: 'center'
                    }}
                  >
                    <Title order={2}>
                      <NumericFormat
                        value={load.rate}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Title>
                    {!isModal && !isBidsLive && (
                      <div ref={arrowIconRef}>
                        <ActionIcon
                          onClick={() => {
                            if (load.source === 'newtrul' && !isPreview) {
                              reportLoadView(load.id);
                            }

                            const parentDiv =
                              arrowIconRef?.current?.parentNode.parentNode
                                .parentNode.parentNode.parentNode;

                            parentDiv.style.height = isPreview
                              ? '350px'
                              : '100px';

                            //console(window.pageYOffset + el.getBoundingClientRect().top

                            dispatch(
                              setPreviewLoads({
                                stable_id: load.stable_id,
                                opened: !isPreview
                              })
                            );

                            if (rerenderList) {
                              rerenderList();
                            }
                          }}
                        >
                          {!isPreview && <FaChevronDown />}
                          {isPreview && <FaChevronUp />}
                        </ActionIcon>
                      </div>
                    )}
                  </Box>
                </Col>
              </>
            )}
            {isPreview && (
              <Col span={12} style={{ zIndex: 1, height: '100%' }}>
                <Preview
                  getListScrollOffset={getListScrollOffset}
                  load={load}
                  currentSearch={currentSearch}
                  type={type}
                  loadListIndex={loadListIndex}
                />
              </Col>
            )}
            {isBidsLive && offer_status && (
              <Col span={3}>
                <Title order={5}>
                  {`Rate: `}
                  <NumericFormat
                    value={load.rate}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Title>
                <Title order={5} mb={5}>
                  {`Offer: `}
                  <NumericFormat
                    value={offer.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </Title>
                <Title
                  onClick={() => {
                    if (offer && onModalOpen) {
                      onModalOpen(offer);
                    }
                  }}
                  order={5}
                  className={`offer ${offer_status} opens-modal`}
                >
                  {offerStatusMap[offer_status]}
                </Title>
              </Col>
            )}
          </Grid>
        </Paper>
        <Space h={20} />
      </div>
    );
  }
);
