import { ReactElement } from 'react';
import type { PaperProps } from '@mantine/core';
import { Paper as MantinePaper } from '@mantine/core';

interface Props extends PaperProps {
  onClick?: () => void;
}

export const Paper = ({ children, ...props }: Props): ReactElement => {
  return <MantinePaper {...props}>{children}</MantinePaper>;
};
