import { ReactElement, useContext, useEffect } from 'react';
import { Row } from './Row/Row';
import { Space } from '../../components/Layout/Space/Space';
import { AppContext, AutoBookingFilterProps } from '../../context';

export const AutoBookingFiltersList = (): ReactElement => {
  const { autoBookingFilters } = useContext(AppContext);

  const renderRow = (auto_booking_filter: AutoBookingFilterProps) => (
    <Row autoBookingFilter={auto_booking_filter} key={auto_booking_filter.id} />
  );

  return (
    <>
      <Space h="xl" />
      {autoBookingFilters.map(renderRow)}
    </>
  );
};
