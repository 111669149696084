import { ReactElement } from 'react';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Text, Title } from '../../../components/Typography/Typography';
import { Price } from '../Common/Price/Price';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { StopsInformation } from './StopsInformation/StopsInformation';
import StopsMap from './StopsMap/StopsMap';
import { Information } from '../Common/Information/Information';

interface Props {
  load: any;
  isOffer: boolean;
}

export const LoadDetails = ({ load, isOffer }: Props): ReactElement => {
  const { full_name, phone, email, position } = load.contacts || {};

  return (
    <Grid gutter="xl">
      <Col span={isOffer ? 12 : 9}>
        <Grid gutter="xl">
          <Col span={12}>
            <Paper shadow="sm" p="xl">
              <Title order={4} mb="xl">
                Load details
              </Title>
              <Information load={{ ...load, preview: false }} />
            </Paper>
          </Col>
          <Col span={12}>
            <Paper shadow="sm" p="xl">
              <Title order={4} mb="xl">
                Equipment details
              </Title>
              <Grid>
                <Col span={3}>
                  <Text fz="sm" c="dimmed">
                    Equipment
                  </Text>
                  <Text fz="sm">
                    {load.equipment_data.types?.join(', ') || '-'}
                  </Text>
                </Col>
                <Col span={3}>
                  <Text fz="sm" c="dimmed">
                    Commodity
                  </Text>
                  <Text fz="sm">{load.commodities?.join(', ') || '-'}</Text>
                </Col>
                <Col span={3}>
                  <Text fz="sm" c="dimmed">
                    Load type
                  </Text>
                  <Text fz="sm"> {load.load_type || '-'} </Text>
                </Col>
                <Col span={3}>
                  <Text fz="sm" c="dimmed">
                    Load requirements
                  </Text>
                  <Text fz="sm">-</Text>
                </Col>
              </Grid>
            </Paper>
          </Col>
          <Col span={12}>
            <StopsInformation stops={load.stops} />
          </Col>
          <Col span={12}>
            <Paper shadow="sm" p="xl">
              <Title order={4} mb="xl">
                General notes
              </Title>
              <Text fz="sm">{load.general_notes || '-'}</Text>
            </Paper>
          </Col>
          <Col span={12}>
            <StopsMap stops={load.stops} />
          </Col>
        </Grid>
      </Col>
      {!isOffer && (
        <Col span={3}>
          <Price
            id={load.id}
            load={load}
            loadStableId={load.stable_id}
            source={load.source}
            loadId={load.id}
            rate={load.rate}
            ratePerMile={load.rate_per_mile}
            email={email}
            position={position}
            fullName={full_name}
            phone={phone}
          />
        </Col>
      )}
    </Grid>
  );
};
