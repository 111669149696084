import { ReactElement, useContext } from 'react';
import { BiRadioCircle } from 'react-icons/bi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Grid, Col } from '../../../components/Layout/Grid/Grid';
import { Paper } from '../../../components/Miscellaneous/Paper/Paper';
import { Text } from '../../../components/Typography/Typography';
import { Flex } from '../../../components/Layout/Flex/Flex';
import { Space } from '../../../components/Layout/Space/Space';
import { LoadRow } from '../LoadRow/LoadRow';

export const SearchRow = ({
  savedSearch,
  isLastRow,
  recommendedLoads
}: any): ReactElement => {
  const { search: data } = savedSearch;
  const loads = recommendedLoads;

  const RenderItem = (item: any) => {
    return item ? (
      <div key={item.id}>
        <LoadRow
          load={{
            ...item.content,
            stable_id: item.stable_id,
            id: item.id,
            created_at: item.created_at
          }}
          key={item.id}
          currentSearch={undefined}
          type={undefined}
        />
      </div>
    ) : null;
  };

  const renderDesktopGrid = () => (
    <div>
      <Grid gutter="xl" align="center" style={{ background: 'transparent' }}>
        <Col span={12}>
          <>
            <Paper style={{ background: 'white' }}>
              <Grid align="center">
                <Col span={3} style={{ paddingLeft: '20px' }}>
                  <Flex align="center">
                    <Text fw="bold">Total recommendations: {loads.length}</Text>
                  </Flex>
                </Col>
                <Col span={3}>
                  <Flex align="center">
                    <Text color="blue">
                      <Flex align="center">
                        <BiRadioCircle size={20} />
                      </Flex>
                    </Text>
                    <Text fw="bold" ml="xs">
                      From: {data.origin.location.address.formatted_address}
                    </Text>
                  </Flex>
                </Col>
                <Col span={3}>
                  <Flex align="center">
                    <Text color="blue">
                      <Flex align="center">
                        <FaMapMarkerAlt />
                      </Flex>
                    </Text>
                    <Text fw="bold" ml="xs">
                      To:{' '}
                      {data.destination.location.address.formatted_address
                        ? data.destination.location.address.formatted_address
                        : 'anywhere'}
                    </Text>
                  </Flex>
                </Col>
                <Col span={3}>
                  <Flex align="center">
                    {/*<Text fw="bold" ml="xs">*/}
                    {/*  Pickup date: {data.pickup_date}*/}
                    {/*</Text>*/}
                  </Flex>
                </Col>
              </Grid>
            </Paper>
            <Space h={5} />
          </>
        </Col>
        <Col span={12} style={{ maxHeight: 400, overflow: 'auto' }}>
          {!!loads.length && <div>{loads.map(RenderItem)}</div>}
        </Col>
      </Grid>
    </div>
  );

  return (
    <>
      <Paper p="lg" style={{ background: 'transparent' }}>
        {renderDesktopGrid()}
      </Paper>
      {!isLastRow && <Space h={30} />}
    </>
  );
};
