import { NumericFormat } from 'react-number-format';
import { Button } from '../../../../components/Buttons/Button/Button';
import { Title, Text } from '../../../../components/Typography/Typography';
import { Paper } from '../../../../components/Miscellaneous/Paper/Paper';
import { Flex } from '../../../../components/Layout/Flex/Flex';
import { useNavigate } from 'react-router-dom';

interface IProps {
  load: any;
  id: number;
  rate: number;
  ratePerMile: number;
  email: string;
  position: string;
  fullName: string;
  phone: string;
}

export const Price: React.FC<IProps> = ({
  rate,
  ratePerMile,
  email,
  position,
  fullName,
  phone,
  load
}) => {
  const navigate = useNavigate();
  return (
    <Paper shadow="sm" p="md" sx={{ backgroundColor: '#e8f6f1' }}>
      <Title order={2} mb="sm">
        <NumericFormat
          value={rate}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </Title>
      <Text fz="sm" mb="sm">
        Rate per mile:{' '}
        <NumericFormat
          value={ratePerMile}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
        /mi
      </Text>
      <Button
        fullWidth
        size="lg"
        mb="sm"
        onClick={() => {
          const params = new URLSearchParams();
          const lastStopId = load.stops.length - 1;

          params.append('from_lat', load.stops[0].latitude);
          params.append('from_lng', load.stops[0].longitude);

          params.append('to_lat', load.stops[lastStopId].latitude);
          params.append('to_lng', load.stops[lastStopId].longitude);

          navigate(`/?${params.toString()}`);
        }}
      >
        Search again
      </Button>
      <Flex justify="space-between" mt="sm">
        <Text fz="sm" fw="bold">
          {fullName || '-'}
        </Text>
        <Text fz="sm" c="dimmed">
          {phone || '-'}
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Text fz="sm" c="dimmed">
          {position || '-'}
        </Text>
        <Text fz="sm" c="dimmed">
          {email || '-'}
        </Text>
      </Flex>
    </Paper>
  );
};
