import { ReactElement, useContext, useEffect, useState } from 'react';
import { Modal } from '../../components/Overlays/Modal/Modal';
import { useForm } from '@mantine/form';
import { AutocompleteItem, Center, Title } from '@mantine/core';
import { Col, Grid } from '../../components/Layout/Grid/Grid';
import {
  geocode,
  createAutoBookingFilter,
  updateAutoBookFilter
} from '../../api/api';
import { Divider } from '../../components/Miscellaneous/Divider/Divider';
import { Button } from '../../components/Buttons/Button/Button';
import { AppContext, AutoBookingFilterProps } from '../../context';
import moment from 'moment/moment';
import { Direction } from './Form/Direction';
import { Times } from './Form/Times';
import { Parameters } from './Form/Parameters';
import { TextInput } from '../../components/Inputs/TextInput/TextInput';
import { Flex } from '../../components/Layout/Flex/Flex';
import { MdOutlineAttachEmail } from 'react-icons/md';

const timeToDate = (time: any) => {
  if (!time) {
    return new Date();
  }
  const now = new Date();
  const hoursAndMinutes = time.split(':');

  const date = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hoursAndMinutes[0],
    hoursAndMinutes[1]
  );
  return date;
};

interface Props {
  autoBookingFilter: AutoBookingFilterProps;
  autoBookingFilterId?: number;
  opened: boolean;
  close: () => void;
}

export const AutoBookingFilterModal = ({
  autoBookingFilter,
  autoBookingFilterId,
  opened,
  close
}: Props): ReactElement => {
  const { setAutoBookingFilters, user } = useContext(AppContext);
  const [originLocationData, setOriginLocationData] = useState<any>([]);
  const [destinationLocationData, setDestinationLocationData] = useState<any>(
    []
  );

  const [timeFrom, setTimeFrom] = useState<Date | undefined>(
    timeToDate(autoBookingFilter.settings.origin.time_from)
  );
  const [timeTo, setTimeTo] = useState<Date | undefined>(
    timeToDate(autoBookingFilter.settings.origin.time_to)
  );
  const [delTimeFrom, setDelTimeFrom] = useState<Date | undefined>(
    timeToDate(autoBookingFilter.settings.destination.time_from)
  );
  const [delTimeTo, setDelTimeTo] = useState<Date | undefined>(
    timeToDate(autoBookingFilter.settings.destination.time_to)
  );

  const [lineHaul, setLineHaul] = useState<number>(
    autoBookingFilter.settings.loaded_miles.min *
      autoBookingFilter.settings.rpm_from
  );

  const [delStatesVisible, setDelStatesVisible] = useState<boolean>(
    autoBookingFilter.settings.destination.location.address.limit_enabled
  );

  const [puStatesVisible, setPuStatesVisible] = useState<boolean>(
    autoBookingFilter.settings.origin.location.address.limit_enabled
  );

  const [delStates, setDelStates] = useState<string[]>(
    autoBookingFilter.settings.destination.location.address.limit_to_states
  );
  const [puStates, setPuStates] = useState<string[]>(
    autoBookingFilter.settings.origin.location.address.limit_to_states
  );

  const handleSpecificStatesChange = (type: string, e: any) => {
    if (type == 'destination') {
      if (!e.includes(form.values.destination.location.address.state_code)) {
        e = [form.values.destination.location.address.state_code, ...e];
      }
      setDelStates(e);
    } else if (type == 'origin') {
      if (!e.includes(form.values.origin.location.address.state_code)) {
        e = [form.values.origin.location.address.state_code, ...e];
      }
      setPuStates(e);
    }
  };

  const validEmail = (email: string): string | null => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailRegex.test(email);
    const isLengthValid = email.length <= 40;
    const isSingleEmail = !(email.includes(';') || email.includes(','));

    if (!isValidEmail) {
      return 'Invalid email format';
    } else if (!isLengthValid) {
      return 'Email must be 40 characters or less';
    } else if (!isSingleEmail) {
      return 'Only one email is allowed';
    } else {
      return null; // null indicates the value is valid
    }
  };

  const form = useForm({
    initialValues: {
      ...autoBookingFilter.settings,
      destination: {
        ...autoBookingFilter.settings.destination,
        time_from: timeToDate(autoBookingFilter.settings.destination.time_from),
        time_to: timeToDate(autoBookingFilter.settings.destination.time_to)
      },
      origin: {
        ...autoBookingFilter.settings.origin,
        time_from: timeToDate(autoBookingFilter.settings.origin.time_from),
        time_to: timeToDate(autoBookingFilter.settings.origin.time_to)
      }
    },

    validate: {
      rate_conf_receiver: (email: string) => {
        return validEmail(email);
      }
    }
  });

  // Stupid mantine rangeslider does not work when we have steps fron 1 to  7
  // o it's sort of workaround for visualization
  const [duration, setDuration] = useState<number[]>([
    form.values.duration.min,
    form.values.duration.max
  ]);

  const [miles, setMiles] = useState<number[]>([
    form.values.loaded_miles.min,
    form.values.loaded_miles.max
  ]);

  const clearSpecificStates = (type: string) => {
    if (type == 'origin') {
      setPuStatesVisible(false);
      setPuStates([]);
    } else if (type == 'destination') {
      setDelStatesVisible(false);
      setDelStates([]);
    }
  };

  const handleDestinationSelect = async (
    type: string,
    value: AutocompleteItem
  ) => {
    const addressData = ((await geocode(value.data.placeId))?.results || [])[0];

    clearSpecificStates(type);
    if (!addressData) {
      return;
    }

    const stateInfo = addressData.address_components.find((e: any) =>
      e.types.includes('administrative_area_level_1')
    );

    const stateCode = stateInfo.short_name;
    form.setFieldValue(`${type}.location.address.state_code`, stateCode);

    if (type == 'origin') {
      setPuStates([stateCode]);
    } else if (type == 'destination') {
      setDelStates([stateCode]);
    }

    form.setFieldValue(
      `${type}.location.address.formatted_address`,
      addressData.formatted_address
    );
    form.setFieldValue(
      `${type}.location.coordinates.latitude`,
      addressData.geometry?.location?.lat
    );
    form.setFieldValue(
      `${type}.location.coordinates.longitude`,
      addressData.geometry?.location?.lng
    );
  };

  const disableForm = () => {
    if (originLocationData.length == 0) {
      return true;
    }

    if (validEmail(String(form.values.rate_conf_receiver)) !== null) {
      return true;
    }

    return form.values.origin.location.address.formatted_address &&
      !!form.values.origin.location.coordinates.latitude &&
      !!form.values.origin.location.coordinates.longitude &&
      !!form.values.destination.location.coordinates.longitude &&
      !!form.values.destination.location.coordinates.latitude &&
      !!form.values.destination.location.address.formatted_address &&
      !!form.values.loaded_miles.min &&
      !!form.values.loaded_miles.max &&
      !!form.values.duration.min &&
      !!form.values.duration.max
      ? false
      : true;
  };

  const disableSpecificStateSwitch = (type: string) => {
    if (type == 'destination') {
      return form.values.destination.location.address.state_code ? false : true;
    } else if (type == 'origin') {
      return form.values.origin.location.address.state_code ? false : true;
    }
  };

  const resetForm = () => {
    form.reset();

    setTimeFrom(timeToDate(autoBookingFilter.settings.origin.time_from));
    setTimeTo(timeToDate(autoBookingFilter.settings.origin.time_to));
    setDelTimeFrom(
      timeToDate(autoBookingFilter.settings.destination.time_from)
    );
    setDelTimeTo(timeToDate(autoBookingFilter.settings.destination.time_to));
    setPuStatesVisible(false);
    setDelStatesVisible(false);
    setMiles([350, 3000]);
    setDuration([1, 3]);
  };

  const updateLinehaul = () => {
    const min_miles = form.getInputProps('rpm_from').value;
    const loaded_miles = form.getInputProps('loaded_miles.min').value;

    setLineHaul(min_miles * loaded_miles);
  };

  useEffect(() => {
    updateLinehaul();
  }, [form.values.rpm_from, form.values.loaded_miles.min]);

  useEffect(() => {
    if (!autoBookingFilterId) {
      form.values.rate_conf_receiver = user?.email || '';
    }
  }, [user]);

  return (
    <>
      <Modal
        centered
        size="xl"
        opened={opened}
        onClose={() => {
          close();
        }}
        title={
          <Center>
            <Title order={3}>Filter of Auto Booking Lane</Title>
          </Center>
        }
      >
        <form
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          onSubmit={form.onSubmit((v) => {
            const time_from = moment(new Date(v.origin.time_from)).format(
              'HH:mm'
            );

            const time_to = moment(new Date(v.origin.time_to)).format('HH:mm');
            const delivery_time_from = moment(
              new Date(v.destination.time_from)
            ).format('HH:mm');
            const delivery_time_to = moment(
              new Date(v.destination.time_to)
            ).format('HH:mm');

            if (
              v.destination.location.coordinates.latitude == null ||
              v.destination.location.coordinates.longitude == null
            ) {
              v.destination.location.address.formatted_address = null;
              form.setFieldValue(
                `destination.location.address.formatted_address`,
                ''
              );
            }

            v.destination.location.address.limit_to_states = delStates;
            v.origin.location.address.limit_to_states = puStates;

            const duration_min = duration[0];
            const duration_max = duration[1];

            const data: any = {
              ...v,
              origin: { ...v.origin, time_from: time_from, time_to: time_to },
              destination: {
                ...v.destination,
                time_from: delivery_time_from,
                time_to: delivery_time_to
              },
              duration: {
                min: duration_min,
                max: duration_max
              }
            };

            !autoBookingFilterId &&
              createAutoBookingFilter(data).then((response: any) => {
                if (response) {
                  setAutoBookingFilters({
                    type: 'ADD',
                    payload: response
                  });
                  resetForm();
                  close();
                }
              });
            autoBookingFilterId &&
              updateAutoBookFilter(autoBookingFilterId, data).then(
                (response: any) => {
                  if (response) {
                    setAutoBookingFilters({
                      type: 'UPDATE',
                      payload: response
                    });
                    resetForm();
                    close();
                  }
                }
              );
          })}
        >
          <Grid mx="lg">
            <Col span={12}>
              <Divider />
            </Col>
            <Direction
              form={form}
              originLocationData={originLocationData}
              setOriginLocationData={setOriginLocationData}
              clearSpecificStates={clearSpecificStates}
              disableForm={disableForm}
              handleDestinationSelect={handleDestinationSelect}
              destinationLocationData={destinationLocationData}
              setPuStatesVisible={setPuStatesVisible}
              puStatesVisible={puStatesVisible}
              disableSpecificStateSwitch={disableSpecificStateSwitch}
              delStatesVisible={delStatesVisible}
              delStates={delStates}
              setDelStatesVisible={setDelStatesVisible}
              handleSpecificStatesChange={handleSpecificStatesChange}
              puStates={puStates}
              setDestinationLocationData={setDestinationLocationData}
            ></Direction>
            <Col span={12}>
              <Divider />
            </Col>
            <Times
              timeFrom={timeFrom}
              delTimeFrom={delTimeFrom}
              timeTo={timeTo}
              delTimeTo={delTimeTo}
              setDelTimeTo={setDelTimeTo}
              setDelTimeFrom={setDelTimeFrom}
              setTimeFrom={setTimeFrom}
              setTimeTo={setTimeTo}
              form={form}
            ></Times>
            <Col span={12}>
              <Divider />
            </Col>
            <Parameters
              form={form}
              lineHaul={lineHaul}
              duration={duration}
              miles={miles}
              setDuration={setDuration}
              setMiles={setMiles}
            ></Parameters>
            <Col span={12} style={{ paddingTop: '20px' }}>
              <Divider />
            </Col>
            <Col span={12} my="xs">
              <Flex align={'flex-end'} justify={'space-between'}>
                <TextInput
                  label={<b>Rate Confirmation Receiver:</b>}
                  size="md"
                  icon={<MdOutlineAttachEmail></MdOutlineAttachEmail>}
                  required
                  {...form.getInputProps('rate_conf_receiver')}
                />

                <Button type="submit" size="md" disabled={disableForm()}>
                  {autoBookingFilterId ? 'Update' : 'Create'}
                </Button>
              </Flex>
            </Col>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
