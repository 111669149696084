export const offerStatusMap: any = {
  book: 'Booking placed',
  bid: 'Bid placed',
  pending: 'Pending',
  confirm: 'Confirmation placed',
  reject: 'Rejection placed',
  counter: 'Counter placed',
  ok: 'Confirmed',
  rejected: 'Rejected',
  countered: 'Countered',
  confirm_required: 'Confirmation required',
  final_offer: 'Final offer'
};
