import { showNotification } from '@mantine/notifications';
import uberLogo from '../assets/images/uber-logo.png';
import coyoteLogo from '../assets/images/coyote-logo.png';
import chRobinsonLogo from '../assets/images/ch-robinson-logo.png';
import echoGlobalLogo from '../assets/images/echo-global-logo.png';
import newtrulLogo from '../assets/images/newtrul-logo.png';
import loadsmartLogo from '../assets/images/loadsmart-logo.png';
import datLogo from '../assets/images/dat-logo.png';
import moment from 'moment-timezone';
import formats from '../enums/formats';

export function displayNotification({
  title,
  message,
  type,
  autoClose = 5000
}: {
  title: string;
  message: string;
  type: 'alert' | 'message' | 'success';
  autoClose?: any;
}) {
  showNotification({
    title,
    message,
    autoClose,
    styles: (theme) => {
      let color = '';
      switch (type) {
        case 'alert':
          color = theme.colors.red[6];
          break;
        case 'message':
          color = theme.colors.blue[6];
          break;
        case 'success':
          color = theme.colors.green[6];
          break;
        default:
          color = theme.colors.blue[6];
      }
      return {
        root: {
          borderColor: color,
          '&::before': { backgroundColor: color }
        }
      };
    }
  });
}

export function getLogo(source: string) {
  switch (source) {
    case 'uber':
      return uberLogo;
    case 'coyote':
      return coyoteLogo;
    case 'ch_robinson':
      return chRobinsonLogo;
    case 'echo_global':
      return echoGlobalLogo;
    case 'newtrul':
      return newtrulLogo;
    case 'loadsmart':
      return loadsmartLogo;
    case 'dat':
      return datLogo;
  }
}

export const formattedDestinationTime = (
  timeFrom: string,
  timeTo: string,
  timeZone: string
) => {
  const start = moment.tz(timeFrom, timeZone);
  const end = moment.tz(timeTo, timeZone);

  let date = '';
  let zone = '';
  let startTime = '';
  let endTime = '';

  if (start.isValid()) {
    zone = start.format(formats.ZONE.DEFAULT);
    date = start.format(formats.DATE.DEFAULT);
    startTime = start.format(formats.TIME.DEFAULT);
  }

  if (end.isValid()) {
    endTime = end.format(formats.TIME.DEFAULT);
  }

  return `${date} ${zone} ${startTime} - ${endTime}`;
};
