import {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../../components/Layout/Container/Container';
import { SearchBar } from '../../modules/SearchBar/SearchBar';
import { Header } from '../../modules/Header/Header';
import { Space } from '../../components/Layout/Space/Space';
import { AppContext, SearchFormProps } from '../../context';
import { BackgroundImage } from '../../components/DataDisplay/BackgroundImage/BackgroundImage';
import { FindLoadsList } from '../../modules/FindLoadsList/FindLoadsList';
import { fetchLoads, reverseGeocode } from '../../api/api';
import backgroundImg from '../../assets/images/background.jpg';
import moment from 'moment';
import { cloneDeep, merge } from 'lodash';
import subscriber from '../../api/Subscriber';
import { resetPreviewLoads } from '../../slices/rowSlice';
import { useDispatch } from 'react-redux';

const _ = { cloneDeep, merge };

export const initialSearchList: { list: SearchFormProps[] } = {
  list: [
    {
      origin: {
        reversed_geo: null,
        location: {
          coordinates: {
            longitude: null,
            latitude: null
          },
          address: {
            formatted_address: ''
          }
        },
        radius: 50
      },
      destination: {
        reversed_geo: null,
        location: {
          coordinates: {
            longitude: null,
            latitude: null
          },
          address: {
            formatted_address: ''
          }
        },
        radius: 50
      },
      pickup_date: moment().format('MM/DD/YYYY'),
      delivery_date: moment().format('MM/DD/YYYY'),
      rpm_from: 0.1,
      equipment_types: ['dry_van'],
      equipment_sizes: [53, 48, 45, 40, 32, 20],
      load_id: '',
      load_types: ['FTL', 'LTL'],
      load_weight: {
        min: 0,
        max: 80000
      },
      loaded_miles: {
        min: 0,
        max: 10000
      },
      sources: [
        'uber',
        'coyote',
        'echo_global',
        'ch_robinson',
        'newtrul',
        'loadsmart',
        'dat'
      ],
      stops: 3,
      capacity_of_lane: 1,
      recommendations: false,
      autobook: false,
      time_from: moment().startOf('day').toDate(),
      time_to: moment().endOf('day').toDate(),
      ml: false,
      active: false
    }
  ]
};

export const FindLoadsPage = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isReturnFromDetailsPage, savedSearch, loadListScrollOffset } =
    location.state || {};
  const { loads, setLoads, savedSearches } = useContext(AppContext);

  const [isFetching, setIsFetching] = useState(
    (isReturnFromDetailsPage && loads.length > 0) || false
  );

  let rerenderList = () => {};

  const setFindLoadsSessionFilter = (filter: SearchFormProps) => {
    sessionStorage.setItem('findLoadsFilter', JSON.stringify(filter));
  };

  const getCurrentSearch = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const searchParamsValid =
        queryParams.has('from_lat') && queryParams.has('from_lng');

      if (searchParamsValid) {
        return await getSearchFromQueryParams(queryParams);
      }

      const sessionStorageSearch = sessionStorage.getItem('findLoadsFilter');

      if (sessionStorageSearch) {
        return JSON.parse(sessionStorageSearch) as { list: SearchFormProps[] };
      }

      return initialSearchList;
    } catch (error) {
      return initialSearchList;
    }
  };

  const getSearchFromQueryParams = async (queryParams: URLSearchParams) => {
    const startList = { ...initialSearchList };

    const from_lat = parseFloat(queryParams.get('from_lat') || '0');
    const from_lng = parseFloat(queryParams.get('from_lng') || '0');

    const to_lat = parseFloat(queryParams.get('to_lat') || '0');
    const to_lng = parseFloat(queryParams.get('to_lng') || '0');

    const [originResult, destinationResult] = await Promise.all([
      reverseGeocode(from_lat, from_lng),
      reverseGeocode(to_lat, to_lng)
    ]);

    startList.list[0].origin.location.address.formatted_address = `${originResult.city}, ${originResult.state}`;

    if (to_lat == 0 && to_lng == 0) {
      startList.list[0].destination.location.address.formatted_address = '';
    } else {
      startList.list[0].destination.location.address.formatted_address = `${destinationResult.city}, ${destinationResult.state}`;
    }

    startList.list[0].origin.location.coordinates = {
      latitude: from_lat,
      longitude: from_lng
    };
    startList.list[0].destination.location.coordinates = {
      latitude: to_lat,
      longitude: to_lng
    };

    setSearchKey((prevKey) => prevKey + 1);
    window.history.replaceState(null, '', window.location.pathname);

    return startList;
  };

  const [currentSearch, setCurrentSearch] = useState(initialSearchList);

  useEffect(() => {
    getCurrentSearch().then((res) => {
      setCurrentSearch(res);
    });
  }, []);

  const [searchKey, setSearchKey] = useState(0);

  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (currentSearch: any) => {
      const subscriptionSessionToken = await fetchLoads(
        currentSearch,
        setLoads,
        subscriber.socketSessionId
      );

      if (!subscriptionSessionToken) {
        return;
      }

      subscriber.addListener('search_loads_results', (payload) => {
        subscriptionSessionToken.includes(payload.subscriptionSessionToken) &&
          payload.data?.length &&
          setLoads({
            type: 'ADD',
            payload: payload.data
          });
      });
    },
    [setLoads]
  );

  /*
  useEffect(
    () => () => {
      dispatch(resetPreviewLoads());
    },
    []
  );
*/

  useEffect(() => {
    if (!isFetching && savedSearch) {
      setIsFetching(true);
      setFindLoadsSessionFilter(savedSearch.search);
      setCurrentSearch({
        ...savedSearch.search,
        isSaved: true
      });
      setSearchKey(savedSearch.id); // used to rerender <SearchBar>
      //fetchData(savedSearch.search);
      handleOnSearch(savedSearch.search);
    }
  }, [isFetching, fetchData, savedSearch]);

  const prevLoadLength = useRef<number>(loads.length);
  useEffect(() => {
    if (loads.length < prevLoadLength.current) {
      rerenderList();
    }

    prevLoadLength.current = loads.length;
  }, [loads]);

  const saveSearch = () => {
    setCurrentSearch((prevValues: any) => ({
      ...prevValues,
      isSaved: true
    }));
  };

  const setReverseGeo = async (filterData: any) => {
    const geoObjects: any = {};

    filterData.list.forEach((item: any) => {
      geoObjects[
        `${item.origin.location.coordinates.latitude}|${item.origin.location.coordinates.longitude}`
      ] = null;
      geoObjects[
        `${item.destination.location.coordinates.latitude}|${item.destination.location.coordinates.longitude}`
      ] = null;
    });

    currentSearch.list.forEach((item) => {
      geoObjects[
        `${item.origin.location.coordinates.latitude}|${item.origin.location.coordinates.longitude}`
      ] = item.origin.reversed_geo;
      geoObjects[
        `${item.destination.location.coordinates.latitude}|${item.destination.location.coordinates.longitude}`
      ] = item.destination.reversed_geo;
    });

    const promises: any[] = [];

    Object.keys(geoObjects).forEach((key) => {
      if (geoObjects[key]) {
        return;
      }
      const split = key.split('|');
      const latitude = parseFloat(split[0]);
      const longitude = parseFloat(split[1]);

      if (latitude && longitude) {
        promises.push(
          reverseGeocode(latitude, longitude).then((result) => ({
            key: `${latitude}|${longitude}`,
            data: result
          }))
        );
      }
    });

    const results = await Promise.all(promises);

    results.forEach((item) => {
      geoObjects[item.key] = item.data;
    });

    filterData.list.forEach((item: any) => {
      item.origin.reversed_geo =
        geoObjects[
          `${item.origin.location.coordinates.latitude}|${item.origin.location.coordinates.longitude}`
        ];
      item.destination.reversed_geo =
        geoObjects[
          `${item.destination.location.coordinates.latitude}|${item.destination.location.coordinates.longitude}`
        ];
    });
  };

  const handleOnSearch = async (filterData: any) => {
    location.state = {};
    setIsFetching(true);
    setLoads({
      type: 'RESET',
      payload: []
    });

    await setReverseGeo(filterData);
    dispatch(resetPreviewLoads());

    setCurrentSearch((prevData: any) => {
      return { ...prevData, ...filterData, isSaved: false };
    });

    setFindLoadsSessionFilter(filterData);
    await fetchData(filterData);
  };

  const handleOnReset = (values: any) => {
    setCurrentSearch((prevData: any) => _.merge(prevData, values));
  };

  const handleSort = (value: string) => {
    dispatch(resetPreviewLoads());
    setLoads({
      type: 'SORT',
      payload: {
        sortValue: value
      }
    });
    rerenderList();
  };

  return (
    <>
      <BackgroundImage src={!isFetching && !savedSearch ? backgroundImg : ''}>
        <Container fluid p={0}>
          <Header />
          <Container
            fluid
            sx={{
              backgroundColor: isFetching ? '#F4F6FC' : 'transparent',
              minHeight: 'calc(100vh - 80px)',
              boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
            }}
            p={0}
          >
            <Container size="xl">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 'calc(100vh - 80px)'
                }}
              >
                <Space h={25} />
                <SearchBar
                  initialSearchList={currentSearch}
                  key={searchKey} // used to rerender the component, when clicking on saved search in this page
                  onSearch={handleOnSearch}
                  onReset={handleOnReset}
                />
                <Space h="xl" />
                {/*
              {!isFetching && !savedSearch && (
                <>
                  <Paper shadow="sm" p="xl">
                    <SavedSearchesList />
                  </Paper>
                  <Space h="xl" />
                </>
              )
              )}
              */}
                {isFetching && (
                  <FindLoadsList
                    scrollToPosition={loadListScrollOffset}
                    setRerenderListRef={(rerenderListRef) => {
                      rerenderList = rerenderListRef;
                    }}
                    currentSearch={currentSearch}
                    onSaveSearchClick={saveSearch}
                    loadsFetching={isFetching}
                    onSort={handleSort}
                  />
                )}
              </div>
            </Container>
          </Container>
        </Container>
      </BackgroundImage>
    </>
  );
};
