import { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import useFetch from 'use-http';
import { Button } from '../../../../components/Buttons/Button/Button';
import { Title, Text } from '../../../../components/Typography/Typography';
import { Paper } from '../../../../components/Miscellaneous/Paper/Paper';
import { Flex } from '../../../../components/Layout/Flex/Flex';
import { BookingModal } from '../../../../modals/BookingModal/BookingModal';
import { MakeOfferModal } from '../../../../modals/MakeOfferModal/MakeOfferModal';
import { submitOffer } from '../../../../api/api';

import { OFFER_URL } from '../../../../api/api';
import { AppContext } from '../../../../context';
import subscriber from '../../../../api/Subscriber';

import type { RootState } from '../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { setBiddingModal, setBookingModal } from '../../../../slices/rowSlice';

interface IProps {
  loadStableId: string;
  loadId: number;
  load: any;
  id: number;
  rate: number;
  ratePerMile: number;
  email: string;
  position: string;
  fullName: string;
  phone: string;
  source: string;
}

export const Price: React.FC<IProps> = ({
  loadStableId,
  loadId,
  load,
  id,
  rate,
  ratePerMile,
  email,
  position,
  fullName,
  phone,
  source
}) => {
  const { sources } = useContext(AppContext);
  const [expired, setExpired] = useState(false);
  const { opened: isBookingModalOpen } = useSelector(
    (state: RootState) => state.rowReducer.bookModal
  );

  const { opened: isMakeOfferModalOpen } = useSelector(
    (state: RootState) => state.rowReducer.biddingModal
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (sources) {
      const lifeTime = sources[source].load_lifetime;
      const now = new Date().getTime();
      const then = new Date(load.created_at).getTime();
      setExpired((now - then) / 1000 >= lifeTime);
    }
  }, [load.created_at, source, sources]);

  return (
    <>
      <Paper shadow="sm" p="md" sx={{ backgroundColor: '#e8f6f1' }}>
        <Title order={2} mb="sm">
          {rate ? (
            <NumericFormat
              value={rate}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          ) : (
            '-'
          )}
        </Title>
        <Text fz="sm" mb="sm">
          Rate per mile:{' '}
          <NumericFormat
            value={ratePerMile}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
          /mi
        </Text>
        {load.booking.enabled && (
          <Button
            disabled={expired || load.isBooked}
            fullWidth
            size="lg"
            mb="sm"
            onClick={() => {
              //setIsBookingModalOpen(true);
              dispatch(setBookingModal({ opened: true, data: load }));
            }}
          >
            Book now
          </Button>
        )}

        {load.bidding.enabled && (
          <Button
            disabled={expired || load.isOffer}
            fullWidth
            size="lg"
            variant="outline"
            onClick={() => {
              dispatch(setBiddingModal({ opened: true, data: load }));
            }}
          >
            Make an offer
          </Button>
        )}
        <Flex justify="space-between" mt="sm">
          <Text fz="sm" fw="bold">
            {fullName || '-'}
          </Text>
          <Text fz="sm" c="dimmed">
            {phone || '-'}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text fz="sm" c="dimmed">
            {position || '-'}
          </Text>
          <Text fz="sm" c="dimmed">
            {email || '-'}
          </Text>
        </Flex>
      </Paper>
      <BookingModal
        load={load}
        isOpen={isBookingModalOpen}
        setIsOpen={(flag) => {
          dispatch(setBookingModal({ opened: flag, data: null }));
        }}
        onSubmit={async (values) => {
          dispatch(setBookingModal({ opened: false, data: null }));
          await submitOffer({
            load_id: loadId,
            load_stable_id: loadStableId,
            event_type: 'book',
            amount: load.rate,
            form_values: values,
            wsSessionId: subscriber.socketSessionId
          });
        }}
      />
      <MakeOfferModal
        load={load}
        isOpen={isMakeOfferModalOpen}
        setIsOpen={(flag) => {
          dispatch(setBiddingModal({ opened: flag, data: null }));
        }}
        onSubmit={async (values) => {
          dispatch(setBiddingModal({ opened: false, data: null }));
          await submitOffer({
            load_id: load.id,
            amount: values.offer,
            event_type: 'bid',
            wsSessionId: subscriber.socketSessionId
          });
        }}
      />
    </>
  );
};
