const OR_API_URL = import.meta.env.VITE_OR_URL || 'http://localhost:3002';
export const REFRESH_URL = `${OR_API_URL}/loadboard_sessions/refresh_token`;

class AccessKeyManager {
  readonly earlyExpireOffset: number = 20_000;

  expireTimer: NodeJS.Timeout | undefined;

  constructor() {
    const urlToken = this.tokenInUrl();

    // TODO: this is not ideal, to modify history within a constrctor. Will need a better solution
    if (urlToken) {
      this.rewriteToken();
    }

    this.startExpireTimer();
  }

  setToken(newToken: string, expiresAt?: number) {
    window.localStorage.setItem('loadboard.accessToken', newToken);

    if (expiresAt) {
      this.setTokenExpiration(expiresAt);
      this.startExpireTimer();
    }
  }

  async getToken() {
    const currentToken = window.localStorage.getItem('loadboard.accessToken');

    return currentToken || this.refreshToken();
  }

  setTokenExpiration(expiresAt: number) {
    window.localStorage.setItem(
      'loadboard.accessTokenExpiresAt',
      expiresAt.toString()
    );
  }

  getTokenExpiration() {
    return Number(
      window.localStorage.getItem('loadboard.accessTokenExpiresAt')
    );
  }

  async refreshToken() {
    return await fetch(REFRESH_URL, {
      redirect: 'manual',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        cache: 'no-cache',
        mode: 'no-cors'
      },
      credentials: 'include'
    })
      .then((response) => {
        if (response.type == 'opaqueredirect') {
          window.location.href = `${OR_API_URL}/login`;
        }
        return response.json();
      })
      .then((json) => {
        const expiresAt = Date.parse(json.expires_at);

        this.setToken(json.access_token, expiresAt);

        return json.access_token;
      })
      .catch((e) => {
        console.log('Authentication error');
      });
  }

  // TODO: unused for now. Remove if still unused in a month
  // clearToken() {
  //   window.localStorage.removeItem('loadboard.accessToken');
  // }

  tokenInUrl() {
    const url = new URL(window.location.href);

    if (
      url.searchParams.has('access_token') &&
      url.searchParams.has('expires_at')
    ) {
      return {
        accessToken: url.searchParams.get('access_token') || '',
        expiresAt: Date.parse(url.searchParams.get('expires_at') || '')
      };
    } else {
      return false;
    }
  }

  rewriteToken() {
    const newTokenData = this.tokenInUrl();

    if (!newTokenData) {
      return;
    }

    this.setToken(newTokenData.accessToken, newTokenData.expiresAt);

    const cleanUrl = new URL(window.location.href);

    cleanUrl.searchParams.delete('access_token');
    cleanUrl.searchParams.delete('expires_at');

    window.history.replaceState('', '', cleanUrl);
  }

  startExpireTimer() {
    const expiresAt = this.getTokenExpiration();
    const currentTime = Date.now();
    const expireDelay = expiresAt - currentTime - this.earlyExpireOffset;

    if (!expiresAt || expireDelay <= 0) {
      return;
    }

    if (this.expireTimer) {
      clearTimeout(this.expireTimer);
    }

    this.expireTimer = setTimeout(() => {
      this.expireTimer = undefined;
      this.refreshToken();
    }, expireDelay);
  }
}

export default new AccessKeyManager();
