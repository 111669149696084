import { ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { Tabs } from '../../components/Navigation/Tabs/Tabs';
import { Tabs } from '@mantine/core';
import { Flex } from '../../components/Layout/Flex/Flex';
import { Container } from '../../components/Layout/Container/Container';
import { Image } from '../../components/DataDisplay/Image/Image';
import headerLogo from '../../assets/images/headerLogo.png';
import { MediaQuery } from '../../components/Layout/MediaQuery/MediaQuery';

export const Header = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToHomePage = () => {
    navigate('/');
  };

  return (
    <Container
      fluid
      sx={{
        backgroundColor: 'white'
      }}
      p={0}
    >
      <Container size="xl">
        <Flex gap="xl" align="center" wrap="wrap" sx={{ height: '80px' }}>
          <Image
            src={headerLogo}
            alt="Open Road"
            width={70}
            height={45}
            onClick={navigateToHomePage}
            sx={{ cursor: 'pointer' }}
          />
          <MediaQuery query="(max-width: 550px)" styles={{ display: 'none' }}>
            <Tabs
              value={location.pathname}
              onTabChange={(value) => navigate(`${value}`)}
            >
              <Tabs.List>
                <Tabs.Tab value="/">Find Loads</Tabs.Tab>
                <Tabs.Tab value="/my-loads">My Loads</Tabs.Tab>
                <Tabs.Tab value="/my-offers">My Offers</Tabs.Tab>
                <Tabs.Tab value="/auto-bookings">Auto Bookings</Tabs.Tab>
                {/*
                <Tabs.Tab value="/saved-searches">
                  Saved Searches & Settings
                </Tabs.Tab>
                */}
                <Tabs.Tab value="/lanes">Lane Scores</Tabs.Tab>
                {/*<Tabs.Tab value="/recommended-loads">*/}
                {/*  Recommended Loads*/}
                {/*</Tabs.Tab>*/}
              </Tabs.List>
            </Tabs>
          </MediaQuery>
        </Flex>
      </Container>
    </Container>
  );
};
