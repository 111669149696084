import { ReactElement } from 'react';
import { Title } from '../../../../components/Typography/Typography';
import { Loader } from '../../../../components/Loaders/Loader';
import { Flex } from '../../../../components/Layout/Flex/Flex';

interface AvailableLoadsProps {
  length: number;
  showLoader: boolean;
}

export const AvailableLoads = ({
  length,
  showLoader
}: AvailableLoadsProps): ReactElement => {
  return (
    <Flex align="center" gap="md">
      <Title order={3}>{length} Available loads</Title>
      {showLoader && <Loader size="md" variant="dots" />}
    </Flex>
  );
};
