import { ReactElement, useContext, useEffect, useState } from 'react';
import { SearchRow } from '././SearchRow/SearchRow';
import { Space } from '../../components/Layout/Space/Space';
import { Title } from '../../components/Typography/Typography';
import { getRecommendedLoads } from '../../api/api';
import { AppContext } from '../../context';

export const RecommendedLoadsList = (): ReactElement => {
  const { recommendedLoads, setRecommendedLoads } = useContext(AppContext);

  useEffect(() => {
    getRecommendedLoads().then((response) => {
      setRecommendedLoads({
        type: 'SET',
        payload: response || []
      });
    });
  }, []);

  const renderRow = (recommendations: any, index: number) => (
    <SearchRow
      savedSearch={recommendations.search}
      key={recommendations.search.id}
      recommendedLoads={recommendations.loads}
      isLastRow={index === recommendations.length - 1}
    />
  );

  return (
    <>
      <Space h="xl" />
      <Title order={2}>Recommended Loads</Title>
      <Space h="xl" />
      {recommendedLoads.length > 0 ? (
        recommendedLoads.map(renderRow)
      ) : (
        <p>No recommend loads matching your search criteria.</p>
      )}
    </>
  );
};
