import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Grid, Col } from '../../components/Layout/Grid/Grid';
import { Text } from '../../components/Typography/Typography';
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';
import { Button } from '../../components/Buttons/Button/Button';
import { AutocompleteWithSelect } from '../../components/Inputs/AutocompleteWithSelect/AutocompleteWithSelect';
import { useMediaQuery } from '../../hooks/useMediaQuery/useMediaQuery';
import { useTheme } from '../../hooks/useTheme/useTheme';
import { ActionIcon } from '@mantine/core';
import { AutocompleteItem } from '@mantine/core';
import { autocomplete, geocode } from '../../api/api';
import { Image } from '../../components/DataDisplay/Image/Image';
import filterIcon from '../../assets/icons/filter.svg';
import ellipseIcon from '../../assets/icons/elipse.svg';
import arrowsIcon from '../../assets/icons/arrows.svg';
import pointerIcon from '../../assets/icons/pointer.svg';
import moment from 'moment';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/all';
import { SearchFormProps } from '../../context';
import { FilterModal } from '../../modals/FilterModal/FilterModal';

const mapToAutocompleteItem = (data: any = {}) => {
  return {
    value: data.description,
    name: data.value_type || data.description,
    data: { placeId: data.place_id, states: data.states }
  };
};

interface Props {
  onSubmit: (values: any) => void;
  children?: ReactNode;
  isPrimary?: boolean;
  onAppendNew?: (arg1: number) => void;
  onRemove?: (arg1: number) => void;
  index: number;
  initialValues: SearchFormProps;
  form: any;
  searchClicked: boolean;
  setSearchClicked: (triggered: boolean) => void;
}

export const SearchForm = ({
  onSubmit,
  isPrimary,
  index,
  onAppendNew,
  onRemove,
  form,
  searchClicked,
  setSearchClicked
}: Props): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xl}px)`);

  const [originLocationData, setOriginLocationData] = useState<any>([]);
  const [destinationLocationData, setDestinationLocationData] = useState<any>(
    []
  );
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const isMountingRef = useRef({ origin: false, destination: false });

  useEffect(() => {
    if (searchClicked) {
      // Reset address data if user made search, but did not select from the provided location list
      if (
        !form.values.list[index].destination.location.coordinates.latitude &&
        !form.values.list[index].destination.location.coordinates.longitude
      ) {
        form.setFieldValue(
          `list.${index}.destination.location.coordinates.latitude`,
          null
        );
        form.setFieldValue(
          `list.${index}.destination.location.coordinates.longitude`,
          null
        );
        form.setFieldValue(
          `list.${index}.destination.location.address.formatted_address`,
          ''
        );
      }
      setSearchClicked(false);
    }
  }, [searchClicked, setSearchClicked]);

  const handleAddressLookup = (e: any) =>
    autocomplete(e)
      .then((e: any) => e?.predictions || [])
      .catch(() => []);

  const setFormListValues = (
    params: { name: string; value: any }[],
    indexes: number[] = []
  ) => {
    indexes = indexes.length
      ? indexes
      : form.values.list.map((e: any, i: any) => i);

    indexes.forEach((val) => {
      params.forEach((param) => {
        form.setFieldValue(`list.${val}.${param.name}`, param.value);
      });
    });
  };

  const handleDestinationSelect = async (
    type: string,
    value: AutocompleteItem
  ) => {
    if (type == 'destination' && value.name == 'zip_code') {
      removeFormItemsRange(index + 1, form.values.list.length - 1);

      for (let i = 0; i < value.data.states.length - 1; i++) {
        onAppendNew?.(index);
      }

      let id = index;
      for (const state of value.data.states) {
        const geocodedData = await geocode(state);
        const addressData = geocodedData?.results?.[0];
        if (!addressData) {
          continue;
        }

        const radius = geocodedData?.radius || 250;

        updateFormValues(type, addressData, id, radius);
        id++;
      }
    } else {
      const addressData = ((await geocode(value.data.placeId))?.results ||
        [])[0];

      if (!addressData) {
        return;
      }

      updateFormValues(type, addressData, index);
    }
  };

  const updateFormValues = (
    type: string,
    addressData: any,
    id: number,
    radius?: number
  ) => {
    const { formatted_address, geometry } = addressData;
    const latitude = geometry?.location?.lat;
    const longitude = geometry?.location?.lng;

    const formValues = [
      {
        name: `${type}.location.address.formatted_address`,
        value: formatted_address
      },
      {
        name: `${type}.location.coordinates.latitude`,
        value: latitude
      },
      {
        name: `${type}.location.coordinates.longitude`,
        value: longitude
      }
    ];

    if (radius !== undefined) {
      formValues.push({ name: `${type}.radius`, value: radius });
    }

    setFormListValues(formValues, [id]);
  };

  const handleEmptyAddress = useCallback(
    (type: string, value: string) => {
      if (value) {
        return;
      }
      form.setFieldValue(
        `list.${index}.${type}.location.coordinates.latitude`,
        null
      );
      form.setFieldValue(
        `list.${index}.${type}.location.coordinates.longitude`,
        null
      );
    },
    [index, form]
  );

  const removeFormItemsRange = (fromIndex: number, toIndex: number) => {
    const currentLength = form.values.list.length;
    if (currentLength <= 1) {
      return;
    }

    const adjustedToIndex = Math.min(toIndex, currentLength - 1);

    for (let i = adjustedToIndex; i >= fromIndex; i--) {
      onRemove?.(i);
    }
  };

  const handlePickUpDateChange = useCallback(
    (newDate: Date) => {
      const momentObj = moment(newDate);
      const newDateStr = momentObj.isValid()
        ? momentObj.format('MM/DD/YYYY')
        : moment().format('MM/DD/YYYY');
      setFormListValues([
        {
          name: 'pickup_date',
          value: newDateStr
        },
        {
          name: 'delivery_date',
          value: newDateStr
        }
      ]);
    },
    [index, form]
  );

  const handleFilterModal = useCallback((values: any) => {
    if (values.list[0].origin?.location?.address?.formatted_address) {
      setIsFilterModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isMountingRef.current['origin']) {
      isMountingRef.current['origin'] = true;
      return;
    }

    handleEmptyAddress(
      'origin',
      form.values.list[index].origin.location.address.formatted_address
    );

    const delayDebounceFn = setTimeout(async () => {
      if (form.values.list[index].origin.location.address.formatted_address) {
        const results = await handleAddressLookup(
          form.values.list[index].origin.location.address.formatted_address
        );
        setOriginLocationData(results.map(mapToAutocompleteItem));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.list[index].origin.location.address.formatted_address]);

  useEffect(() => {
    if (!isMountingRef.current['destination']) {
      isMountingRef.current['destination'] = true;
      return;
    }

    handleEmptyAddress(
      'destination',
      form.values.list[index].destination.location.address.formatted_address
    );

    const delayDebounceFn = setTimeout(async () => {
      if (
        form.values.list[index].destination.location.address.formatted_address
      ) {
        const results = await handleAddressLookup(
          form.values.list[index].destination.location.address.formatted_address
        );
        setDestinationLocationData(results.map(mapToAutocompleteItem));
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [form.values.list[index].destination.location.address.formatted_address]);

  const renderDesktopButtons = () => (
    <>
      {isPrimary && (
        <Col xl="content" span={12}>
          <Button
            disabled={!isPrimary}
            color="white"
            fullWidth
            onClick={() => {
              handleFilterModal(form.values);
            }}
            leftIcon={<Image src={filterIcon} width="xs" height="xs" />}
            size="sm"
            h={47}
            sx={(theme) => ({
              color: theme.colors.gray[6],
              backgroundColor: theme.colors.gray[1]
            })}
          >
            Filter
          </Button>
        </Col>
      )}
      <Col xl="content" span={12}>
        {isPrimary ? (
          <Button
            color="white"
            fullWidth
            onClick={() => {
              if (onAppendNew) {
                onAppendNew(index);
              }
            }}
            rightIcon={<AiOutlinePlus />}
            size="sm"
            h={47}
            sx={(theme) => ({
              color: theme.colors.gray[6],
              backgroundColor: theme.colors.gray[1]
            })}
          >
            Multi Search
          </Button>
        ) : (
          <ActionIcon
            mb="sm"
            onClick={() => {
              if (onRemove) {
                onRemove(index);
              }
            }}
          >
            <AiOutlineMinus />
          </ActionIcon>
        )}
      </Col>
    </>
  );

  const renderMobileButtons = () => (
    <>
      <Col xl="content" span={3} mt="sm">
        {isPrimary && (
          <ActionIcon
            color="white"
            onClick={() => {
              handleFilterModal(form.values);
            }}
            size="md"
            h={47}
            sx={(theme) => ({
              width: '100%',
              color: theme.colors.gray[2],
              backgroundColor: theme.colors.gray[0]
            })}
          >
            <Image src={filterIcon} width="xs" height="xs" />
          </ActionIcon>
        )}
      </Col>
      {isPrimary ? (
        <Button
          color="white"
          fullWidth
          onClick={() => {
            if (onAppendNew) {
              onAppendNew(index);
            }
          }}
          rightIcon={<AiOutlinePlus />}
          size="sm"
          h={47}
          sx={(theme) => ({
            color: theme.colors.gray[6],
            backgroundColor: theme.colors.gray[1]
          })}
        >
          Multi Search
        </Button>
      ) : (
        <ActionIcon
          mb="sm"
          onClick={() => {
            if (onRemove) {
              onRemove(index);
            }
          }}
        >
          <AiOutlineMinus />
        </ActionIcon>
      )}
    </>
  );

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid gutter="xs" align="end" columns={27}>
        <Col xl={8} span={12}>
          {isPrimary && (
            <Text fz="sm" c="dimmed" mb="xs">
              From
            </Text>
          )}
          <AutocompleteWithSelect
            rangeName={`origin.radius`}
            nothingFound="No options"
            radius={form.values.list[index].origin.radius}
            filter={(val: any, filter: any) => true}
            data={originLocationData}
            onItemSubmit={(value: any) => {
              handleDestinationSelect('origin', value);
            }}
            onRangeChange={(name, value) => {
              setFormListValues([{ name, value }], [index]);
            }}
            onKeyDown={() => {
              form.setFieldValue(
                `list.${index}.origin.location.coordinates.latitude`,
                null
              );
              form.setFieldValue(
                `list.${index}.origin.location.coordinates.longitude`,
                null
              );
            }}
            icon={<Image src={ellipseIcon} width={12} height={12} />}
            iconWidth={24}
            validInput={
              form.values.list[index].origin.location.coordinates.latitude &&
              form.values.list[index].origin.location.coordinates.longitude
            }
            {...form.getInputProps(
              `list.${index}.origin.location.address.formatted_address`
            )}
          />
        </Col>

        {!isMobile && (
          <Col xl="content" span={12} mb="md">
            <Text fz="sm" c="dimmed">
              <Image src={arrowsIcon} width={12} height={12} />
            </Text>
          </Col>
        )}
        <Col xl={8} span={12}>
          {isPrimary && (
            <Text fz="sm" c="dimmed" mb="xs">
              To
            </Text>
          )}
          <AutocompleteWithSelect
            rangeName={`destination.radius`}
            nothingFound="No options"
            radius={form.values.list[index].destination.radius}
            data={destinationLocationData}
            filter={(val: any, filter: any) => true}
            onRangeChange={(name, value) => {
              setFormListValues([{ name, value }], [index]);
            }}
            onKeyDown={() => {
              form.setFieldValue(
                `list.${index}.destination.location.coordinates.latitude`,
                null
              );
              form.setFieldValue(
                `list.${index}.destination.location.coordinates.longitude`,
                null
              );
            }}
            onItemSubmit={(value: any) => {
              handleDestinationSelect('destination', value);
            }}
            icon={<Image src={pointerIcon} width={12} height={16} />}
            iconWidth={24}
            validInput={
              form.values.list[index].destination.location.coordinates
                .latitude &&
              form.values.list[index].destination.location.coordinates.longitude
            }
            {...form.getInputProps(
              `list.${index}.destination.location.address.formatted_address`
            )}
          />
        </Col>
        {isPrimary && (
          <Col xl={4} span={12}>
            <Text fz="sm" c="dimmed" mb="xs">
              Pick up date
            </Text>
            <DatePicker
              disabled={!isPrimary}
              value={new Date(form.values.list[index].pickup_date)}
              firstDayOfWeek="sunday"
              weekendDays={[]}
              size="md"
              radius={6}
              h={47}
              onChange={handlePickUpDateChange}
            />
          </Col>
        )}
        {!isMobile && renderDesktopButtons()}
        {isMobile && renderMobileButtons()}
      </Grid>

      {isFilterModalOpen && (
        <FilterModal
          onDateChange={(value) => {
            setFormListValues([
              {
                name: 'pickup_date',
                value
              },
              {
                name: 'delivery_date',
                value
              }
            ]);
          }}
          form={form}
          index={index}
          isFilterModalOpen={isFilterModalOpen}
          setIsFilterModalOpen={setIsFilterModalOpen}
          onSubmit={onSubmit}
        />
      )}
    </form>
  );
};
