import { ReactElement, useContext, useEffect } from 'react';
import { Container } from '../../components/Layout/Container/Container';
import { Header } from '../../modules/Header/Header';
import { SavedSearchesList } from '../../modules/SavedSearchesList/SavedSearchesList';
import { getSavedSearches } from '../../api/api';
import { AppContext } from '../../context';

export const SavedSearchesPage = (): ReactElement => {
  const { setSavedSearches } = useContext(AppContext);

  useEffect(() => {
    getSavedSearches().then((response) => {
      setSavedSearches({
        type: 'SET',
        payload: response || []
      });
    });
  }, []);

  return (
    <Container fluid p={0}>
      <Header />
      <Container
        fluid
        sx={{
          minHeight: 'calc(100vh - 80px)',
          backgroundColor: '#F4F6FC',
          boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
        }}
        p={0}
      >
        <Container size="xl">
          <SavedSearchesList />
        </Container>
      </Container>
    </Container>
  );
};
