import { ReactElement, useContext } from 'react';
import { useForm } from '@mantine/form';
import { Grid, Col } from '../../components/Layout/Grid/Grid';
import { Title, Text } from '../../components/Typography/Typography';
import { Button } from '../../components/Buttons/Button/Button';
import { TextInput } from '../../components/Inputs/TextInput/TextInput';
import { Divider } from '../../components/Miscellaneous/Divider/Divider';
import { Flex } from '../../components/Layout/Flex/Flex';
import { AppContext } from '../../context';
import { COMPLIANCE_TYPES } from '../../enums/load';

interface Props {
  load: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}

export const BookingForm = ({
  load,
  onSubmit,
  onCancel
}: Props): ReactElement => {
  const { user } = useContext(AppContext);
  const { sources } = useContext(AppContext);

  const form = useForm({
    initialValues: {
      fullName: user?.organization_id === 1 ? 'GP Transco' : '',
      email: user?.email || '',
      driverName: user?.offer_defaults[load.source]?.driver_full_name || '',
      driverEmail: user?.offer_defaults[load.source]?.driver_email || '',
      driverPhone: user?.offer_defaults[load.source]?.driver_phone || '',
      truckID: user?.offer_defaults[load.source]?.truck_id || '',
      trailerID: user?.offer_defaults[load.source]?.trailer_id || '',
      emptyDate: user?.offer_defaults[load.source]?.empty_date || '',
      emptyTime: user?.offer_defaults[load.source]?.empty_time || ''
    },
    validate: {
      fullName: (value) => (!value.length ? 'Required' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Required'),
      driverName: (value) => (!value.length ? 'Required' : null),
      driverEmail: (value) => (/^\S+@\S+$/.test(value) ? null : 'Required'),
      driverPhone: (value) => (!value.length ? 'Required' : null),
      truckID: (value) => (!value.length ? 'Required' : null),
      trailerID: (value) => (!value.length ? 'Required' : null),
      emptyDate: (value) => (!value.length ? 'Required' : null),
      emptyTime: (value) => (!value.length ? 'Required' : null)
    }
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid gutter="xs" align="end" mt="xl">
        <Col span={12}>
          <Title order={5} mb="sm">
            Load confirmation contacts
          </Title>
          <Flex align="baseline" gap="xl">
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Full name
                </Text>
              }
              {...form.getInputProps('fullName')}
              size="md"
            />
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Email
                </Text>
              }
              {...form.getInputProps('email')}
              size="md"
            />
          </Flex>
        </Col>
        <Col span={12}>
          <Divider my="sm" />
        </Col>
        <Col span={12}>
          <Title order={5} mb="sm">
            Driver details
          </Title>
          <Flex align="baseline" gap="xl">
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Full name/number
                </Text>
              }
              {...form.getInputProps('driverName')}
              size="md"
            />
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Email
                </Text>
              }
              {...form.getInputProps('driverEmail')}
              size="md"
            />
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Phone number
                </Text>
              }
              {...form.getInputProps('driverPhone')}
              size="md"
            />
          </Flex>
        </Col>
        <Col span={12}>
          <Divider my="sm" />
        </Col>
        <Col span={12}>
          <Title order={5} mb="sm">
            Truck details
          </Title>
          <Flex align="baseline" gap="xl">
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Truck ID
                </Text>
              }
              {...form.getInputProps('truckID')}
              size="md"
            />
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Trailer ID
                </Text>
              }
              {...form.getInputProps('trailerID')}
              size="md"
            />
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Empty date
                </Text>
              }
              {...form.getInputProps('emptyDate')}
              size="md"
            />
            <TextInput
              label={
                <Text size="sm" c="dimmed">
                  Empty time
                </Text>
              }
              {...form.getInputProps('emptyTime')}
              size="md"
            />
          </Flex>
        </Col>
        {sources && sources[load.source]?.compliance?.required && (
          <Col span={12}>
            {load?.booking?.compliance_data?.type ==
              COMPLIANCE_TYPES.INDIVIDUAL && (
              <>
                <Text size="sm" c="dimmed">
                  Please &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    className={
                      load.bidding.compliance_data.required
                        ? 'compliance individual-link'
                        : ''
                    }
                    target={'_blank'}
                    href={load.booking.compliance_data.link}
                  >
                    Fill compliance link
                  </a>
                </Text>
              </>
            )}
            {sources[load.source]?.compliance?.terms?.required && (
              <div>
                <Text size="sm" c="dimmed">
                  Agree to{' '}
                  <a
                    href={sources[load.source]?.compliance?.terms?.url}
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </Text>
              </div>
            )}
            {sources[load.source]?.compliance?.terms?.required && (
              <div>
                <Text size="sm" c="dimmed">
                  Agree to{' '}
                  <a
                    href={sources[load.source]?.compliance?.privacy?.url}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </Text>
              </div>
            )}
          </Col>
        )}
        <Col sm={2} mt="xl">
          <Button
            variant="white"
            color="black"
            size="md"
            onClick={onCancel}
            fullWidth
          >
            Cancel
          </Button>
        </Col>
        <Col sm={10}>
          <Button type="submit" size="md" fullWidth>
            Complete booking
          </Button>
        </Col>
      </Grid>
    </form>
  );
};
