import { ReactElement, memo } from 'react';
import { Center } from '../../components/Layout/Center/Center';
import { Modal } from '../../components/Overlays/Modal/Modal';
import { Title } from '../../components/Typography/Typography';
import { FilterForm } from '../../forms/FilterForm/FilterForm';
import { useStyles } from './FilterModal.style';

interface Props {
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: (isFilterModalOpen: boolean) => void;
  onSubmit: (values: any) => void;
  form: any;
  index: number;
  onDateChange: (arg1: any) => void;
}

export const FilterModal = ({
  isFilterModalOpen,
  setIsFilterModalOpen,
  onSubmit,
  form,
  index,
  onDateChange
}: Props): ReactElement => {
  const { classes } = useStyles();

  return (
    <Modal
      classNames={classes}
      opened={isFilterModalOpen}
      onClose={() => {
        setIsFilterModalOpen(false);
      }}
      title={
        <Center>
          <Title order={3}>Filters</Title>
        </Center>
      }
      size="xl"
      centered
    >
      <FilterForm
        form={form}
        index={index}
        onSubmit={(values) => {
          setIsFilterModalOpen(false);
          onSubmit(values);
        }}
        onDateChange={onDateChange}
      />
    </Modal>
  );
};
