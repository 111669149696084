import { ReactElement, useContext } from 'react';
import { Container } from '../../components/Layout/Container/Container';
import { Header } from '../../modules/Header/Header';
import { RecommendedLoadsList } from '../../modules/RecommendedLoadsList/RecommendedLoadsList';
import { AppContext } from '../../context';

export const RecommendedLoadsPage = (): ReactElement => {
  return (
    <Container fluid p={0}>
      <Header />
      <Container
        fluid
        sx={{
          minHeight: 'calc(100vh - 80px)',
          backgroundColor: '#F4F6FC',
          boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
        }}
        p={0}
      >
        <Container size="xl">
          <RecommendedLoadsList />
        </Container>
      </Container>
    </Container>
  );
};
