import { createBrowserRouter } from 'react-router-dom';
import { FindLoadsPage } from './pages/FindLoadsPage/FindLoadsPage';
import { LoadDetailsPage } from './pages/LoadDetailsPage/LoadDetailsPage';
import { SavedSearchesPage } from './pages/SavedSearchesPage/SavedSearchesPage';
import { RecommendedLoadsPage } from './pages/RecommendedLoadsPage/RecommendedLoadsPage';
import { MyLoadsPage } from './pages/MyLoadsPage/MyLoadsPage';
import { MyOffersPage } from './pages/MyOffersPage/MyOffersPage';
import { LaneScoresPage } from './pages/LaneScoresPage/LaneScoresPage';
import { AutoBookingsFiltersPage } from './pages/AutoBookingFilters/AutoBookingFiltersPage';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <FindLoadsPage />
  },
  {
    path: '/details/:id',
    element: <LoadDetailsPage offer={false} navigateBackTo="/" />
  },
  {
    path: '/my-loads',
    element: <MyLoadsPage />
  },
  {
    path: '/my-load-details/:id',
    element: <LoadDetailsPage offer={true} navigateBackTo="/my-loads" />
  },
  {
    path: '/my-offers',
    element: <MyOffersPage />
  },
  {
    path: '/offer-details/:id',
    element: <LoadDetailsPage offer={true} navigateBackTo="/my-offers" />
  },
  {
    path: '/saved-searches',
    element: <SavedSearchesPage />
  },
  {
    path: '/auto-bookings',
    element: <AutoBookingsFiltersPage />
  },
  {
    path: '/recommended-loads',
    element: <RecommendedLoadsPage />
  },
  {
    path: '/lanes',
    element: <LaneScoresPage />
  }
]);
