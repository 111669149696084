import { ReactElement } from 'react';
import { Space } from '../../components/Layout/Space/Space';
import { Row } from './Row/Row';
import { LoadProps } from '../../context';

interface Props {
  type?: 'booked' | 'offers';
  offers: LoadProps[];
  currentSearch: any;
}

export const MyLoadsList = ({
  type,
  offers,
  currentSearch
}: Props): ReactElement => {
  const renderRow = (offer: any) => (
    <Row
      key={offer.id}
      currentSearch={currentSearch}
      type={type}
      offer={offer}
    />
  );

  return (
    <>
      <Space h="md" />
      {offers.map(renderRow)}
    </>
  );
};
