import ResponseError from '../errors/responseError';
import { displayNotification } from '../../utils/utils';

const processError = (e: ResponseError, global: boolean) => {
  if (global) {
    switch (e.statusCode) {
      case 401:
      case 403:
        displayNotification({
          title: 'Authentication error',
          message: e.data?.message || 'Unauthorized action!',
          type: 'alert'
        });
        break;
      case 404:
        displayNotification({
          title: 'System error',
          message: e.data?.message || 'Something was not found!',
          type: 'alert'
        });
        break;
      case 422:
        displayNotification({
          title: 'System error',
          message: e.data?.message || 'Unprocessable request!',
          type: 'alert'
        });
        break;
      default:
        displayNotification({
          title: 'System error',
          message: e.data?.message || 'Something went wrong',
          type: 'alert'
        });
        break;
    }
  }
  return Promise.reject(e);
};

export default { processError };
