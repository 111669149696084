import { ReactElement } from 'react';
import { Grid, Col } from '../../components/Layout/Grid/Grid';
import { Title } from '../../components/Typography/Typography';
import { MultiSelect } from '../../components/Inputs/MultiSelect/MultiSelect';
import { Select } from '../../components/Inputs/Select/Select';
import { RangeSlider } from '../../components/Inputs/RangeSlider/RangeSlider';
import { Checkbox } from '../../components/Inputs/Checkbox/Checkbox';
import { Group } from '../../components/Layout/Group/Group';
import { Button } from '../../components/Buttons/Button/Button';
import { CustomDatePicker } from '../../components/Inputs/CustomDatePicker/CustomDatePicker';
import { TextInput } from '../../components/Inputs/TextInput/TextInput';
import { Flex } from '../../components/Layout/Flex/Flex';
import { Divider } from '../../components/Miscellaneous/Divider/Divider';
import { EquipmentSizeSelector } from '../../components/Inputs/EquipmentSizeSelector/EquipmentSizeSelector';
import { initialSearchList } from '../../pages/FindLoadsPage/FindLoadsPage';
import { NumberInput } from '../../components/Inputs/NumberInput/NumberInput';
import moment from 'moment';

const loadedMilesMarks = [
  { value: 0, label: '0' },
  { value: 10000, label: '10000' }
];

const weightMarks = [
  { value: 0, label: '0' },
  { value: 80000, label: '80000' }
];

interface Props {
  onSubmit: (values: any) => void;
  form: any;
  index: number;
  onDateChange: (arg1: any) => void;
}

export const FilterForm = ({
  onSubmit,
  form,
  index,
  onDateChange
}: Props): ReactElement => {
  const isLoadTypeSelected = (value: string) => {
    return (form.values.list[index].load_types || []).includes(value);
  };

  const handleOnSelectLoadType = (value: string, checked: boolean) => {
    const newValues = [...form.values.list[index].load_types];
    const selectedIndex = newValues.indexOf(value);

    if (checked) {
      selectedIndex == -1 && newValues.push(value);
    } else {
      selectedIndex > -1 && newValues.splice(selectedIndex, 1);
    }

    form.setFieldValue(`list.${index}.load_types`, newValues);
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid mx="lg">
        <Col span={6}>
          <Title order={4} my="xs">
            Source
          </Title>
          <MultiSelect
            data={[
              { label: 'Uber', value: 'uber' },
              { label: 'Coyote', value: 'coyote' },
              { label: 'Echo global', value: 'echo_global' },
              { label: 'C.H Robinson', value: 'ch_robinson' },
              { label: 'Loadsmart', value: 'loadsmart' }
            ]}
            {...form.getInputProps(`list.${index}.sources`)}
            size="md"
            mb="xs"
          />
        </Col>
        <Col span={6}>
          <Title order={4} my="xs">
            Equipment type
          </Title>
          <MultiSelect
            data={[
              { label: 'Dry van', value: 'dry_van' },
              { label: 'Truck', value: 'truck' },
              { label: 'Towing', value: 'towing' },
              { label: 'Truck and tow', value: 'truckAndTow' },
              { label: 'Trailer', value: 'trailer' }
            ]}
            {...form.getInputProps(`list.${index}.equipment_types`)}
            size="md"
            mb="xs"
          />
        </Col>
        <Col span={12}>
          <Divider />
        </Col>
        <Col span={12}>
          <Title order={4} my="xs">
            Pick up date
          </Title>
          <CustomDatePicker
            value={form.values.list[index].pickup_date}
            onChange={(value) => {
              form.setFieldValue(`list.${index}.pickup_date`, value);
              form.setFieldValue(`list.${index}.delivery_date`, value);
              onDateChange(value);
            }}
          />
        </Col>
        <Col span={12}>
          <Divider />
        </Col>
        <Col span={12}>
          <Title order={4} my="xs">
            Loaded miles
          </Title>
          <RangeSlider
            min={0}
            max={10000}
            marks={loadedMilesMarks}
            value={[
              form.values.list[index].loaded_miles.min,
              form.values.list[index].loaded_miles.max
            ]}
            onChange={(value: [number, number]) => {
              const [from, to] = value;
              form.setFieldValue(`list.${index}.loaded_miles.min`, from);
              form.setFieldValue(`list.${index}.loaded_miles.max`, to);
            }}
          />
        </Col>
        <Col span={12}>
          <Divider mt="xl" />
        </Col>
        <Col span={12}>
          <Title order={4} my="xs">
            Load type
          </Title>
          <Group mb="xs">
            <Checkbox
              value="FTL"
              label="FTL"
              checked={isLoadTypeSelected('FTL')}
              onChange={(event) => {
                handleOnSelectLoadType('FTL', event.currentTarget.checked);
              }}
              radius="sm"
            />
            <Checkbox
              value="LTL"
              label="LTL"
              checked={isLoadTypeSelected('LTL')}
              onChange={(event) => {
                handleOnSelectLoadType('LTL', event.currentTarget.checked);
              }}
              radius="sm"
            />
          </Group>
        </Col>
        <Col span={12}>
          <Divider />
        </Col>
        <Col span={12}>
          <Title order={4} my="xs">
            Load weight (Lbs)
          </Title>
          <RangeSlider
            min={0}
            max={80000}
            marks={weightMarks}
            value={[
              form.values.list[index].load_weight.min,
              form.values.list[index].load_weight.max
            ]}
            onChange={(value: [number, number]) => {
              const [from, to] = value;
              form.setFieldValue(`list.${index}.load_weight.min`, from);
              form.setFieldValue(`list.${index}.load_weight.max`, to);
            }}
          />
        </Col>
        <Col span={12}>
          <Divider mt="xl" />
        </Col>
        <Col span={12}>
          <Title order={4} my="xs">
            Equipment size
          </Title>
          <EquipmentSizeSelector
            value={form.values.list[index].equipment_sizes}
            onChange={(value) => {
              form.setFieldValue(`list.${index}.equipment_sizes`, value);
            }}
          />
        </Col>
        <Col span={12}>
          <Divider />
        </Col>
        <Col span={4}>
          <Title order={4} my="xs">
            Stops
          </Title>
          <Select
            data={[
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 }
            ]}
            {...form.getInputProps(`list.${index}.stops`)}
            size="md"
            mb="xs"
          />
        </Col>
        <Col span={4}>
          <Title order={4} my="xs">
            RPM from
          </Title>
          <NumberInput
            required
            defaultValue={0.1}
            precision={2}
            min={0.1}
            step={0.01}
            type="number"
            {...form.getInputProps(`list.${index}.rpm_from`)}
            size="md"
          />
        </Col>
        <Col span={4}>
          <Title order={4} my="xs">
            Load number
          </Title>
          <TextInput
            placeholder="Type in"
            value={form.values.list[0].load_id}
            onChange={(event) => {
              form.setFieldValue(
                `list.${index}.load_id`,
                event.currentTarget.value
              );
            }}
            {...form.getInputProps(`list.${index}.load_id`)}
            size="md"
            mb="xs"
          />
        </Col>
        <Col span={12} my="xs">
          <Flex justify="space-between" align="center" wrap="wrap">
            <Button
              variant="white"
              color="black"
              size="md"
              onClick={() => {
                form.reset();
                form.setFieldValue(
                  `list.${index}.pickup_date`,
                  moment().format('MM/DD/YYYY')
                );
              }}
            >
              Clear all
            </Button>
            <Button type="submit" size="md">
              Show loads
            </Button>
          </Flex>
        </Col>
      </Grid>
    </form>
  );
};
