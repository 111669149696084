import { ReactElement, useState } from 'react';
import { Container } from '../../components/Layout/Container/Container';
import { SearchByCityBar } from '../../modules/SearchByCityBar/SearchByCityBar';
import { Header } from '../../modules/Header/Header';
import { Space } from '../../components/Layout/Space/Space';
import backgroundImg from '../../assets/images/background.jpg';
import { BackgroundImage } from '../../components/DataDisplay/BackgroundImage/BackgroundImage';
import FreightWavesScore from '../../modules/LaneScoreResult/FreightWavesScore';

export const LaneScoresPage = (): ReactElement => {
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState<any>({});

  return (
    <>
      <BackgroundImage src={backgroundImg}>
        <Container fluid p={0}>
          <Header />
          <Container
            fluid
            sx={{
              minHeight: 'calc(100vh - 80px)',
              boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
            }}
            p={0}
          >
            <Container size="xl">
              <Space h={50} />
              <SearchByCityBar
                onFetchingChange={setIsFetching}
                onResults={setResults}
              />
              <Space h="xl" />
              {!isFetching && results && (
                <>
                  <FreightWavesScore data={results?.freightWaves} />
                </>
              )}
            </Container>
          </Container>
        </Container>
      </BackgroundImage>
    </>
  );
};
