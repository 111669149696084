import { ReactElement } from 'react';
import type { StepperProps, StepProps } from '@mantine/core';
import { Stepper as MantineStepper } from '@mantine/core';
import { Step as MantineStep } from '@mantine/core';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';
import { FaMapMarkerAlt } from 'react-icons/fa';

export const getStopSteppertIcon = (position: number, total: any) => {
  if (position == 1) {
    return BiRadioCircleMarked;
  } else if (position == total) {
    return FaMapMarkerAlt;
  } else {
    return BiRadioCircle;
  }
};

export const Stepper = ({ children, ...rest }: StepperProps): ReactElement => {
  return <MantineStepper {...rest}>{children}</MantineStepper>;
};

export const Step = ({ children, ...rest }: StepProps): ReactElement => {
  return <MantineStep {...rest}>{children}</MantineStep>;
};
