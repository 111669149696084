import { ReactElement } from 'react';
import { getStopSteppertIcon } from '../../../../components/Navigation/Stepper/Stepper';
import { Text, Title } from '../../../../components/Typography/Typography';
import { Paper } from '../../../../components/Miscellaneous/Paper/Paper';
import { Grid, Col } from '../../../../components/Layout/Grid/Grid';
import moment from 'moment-timezone';
import '../../../../assets/styles/scss/LoadDetails/stops-information.scss';
import formats from '../../../../enums/formats';
import { Timeline, TimelineItem } from '@mantine/core';

const getStopTile = (position: number, totalStops: number, startsAt = 1) => {
  if (position == startsAt) {
    return 'Origin';
  } else if (position == totalStops) {
    return 'Destination';
  } else {
    return `${position > startsAt ? position - 1 : position} stop`;
  }
};

export const StopsInformation = ({ stops }: any): ReactElement => {
  const renderRow = (stop: any) => {
    const index = stop.position;
    const startTime = moment.tz(stop.time_from_local, stop.time_zone);
    const endTime = moment.tz(stop.time_to_local, stop.time_zone);
    const stopTime = stop.position == 1 ? startTime : endTime;
    const title = getStopTile(stop.position, stops.length);
    const StepperIcon = getStopSteppertIcon(stop.position, stops.length);

    return (
      <TimelineItem
        key={index}
        bullet={<StepperIcon size={20} className={'stop-step-icon'} />}
        bulletSize={40}
      >
        <Paper shadow="xs" p="xl" style={{ fontSize: 14, margin: '0 10px' }}>
          <Text color="blue.5">{title}</Text>
          <Grid mt="sm">
            <Col span={3}>
              <Text mb="sm" c="dimmed">
                Address
              </Text>
              <div>{stop.address.full_address}</div>
            </Col>
            <Col span={2}>
              <Text mb="sm" c="dimmed">
                Date
              </Text>
              <div>
                {stopTime.isValid()
                  ? stopTime.format(formats.DATE.DEFAULT)
                  : ''}
              </div>
            </Col>
            <Col span={2}>
              <Text mb="sm" c="dimmed">
                Time{' '}
                {stopTime.isValid()
                  ? stopTime.format(formats.ZONE.DEFAULT)
                  : ''}
              </Text>
              <div>
                {startTime.isValid()
                  ? startTime.format(formats.TIME.DEFAULT)
                  : ''}
                {' - '}
                {endTime.isValid() ? endTime.format(formats.TIME.DEFAULT) : ''}
              </div>
            </Col>
            <Col span={2}>
              <Text mb="sm" c="dimmed">
                Stop type
              </Text>
              <div>{stop.type}</div>
            </Col>
            <Col span={3}>
              <Text mb="sm" c="dimmed">
                Driver work
              </Text>
              <div>-</div>
            </Col>
            <Col span={12}>
              <Text mb="sm" c="dimmed">
                Stop requirements
              </Text>
              <div>-</div>
            </Col>
            <Col span={12}>
              <Text mb="sm" c="dimmed">
                Notes
              </Text>
              <div>{stop.notes || '-'}</div>
            </Col>
          </Grid>
        </Paper>
      </TimelineItem>
    );
  };

  return (
    <Paper shadow="sm" p="xl">
      <Title order={4} mb="xl">
        Stops information
      </Title>
      <Timeline active={-1} lineWidth={2} className={'load-details-step'}>
        {stops.map(renderRow)}
      </Timeline>
    </Paper>
  );
};
