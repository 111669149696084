import { io, Socket } from 'socket.io-client';

const endpoint = `${
  import.meta.env.VITE_SOCKET_SERVER_URL || 'ws://localhost:8080/'
}`;

class Subscriber {
  public socketSessionId = '';
  private webSocket: Socket | null = null;
  private listenerCallbacks: { [key: string]: (arg: any) => void } = {};

  subscribe(options: any = {}) {
    const { accessToken, onFetchFinish = () => {} } = options;

    this.unsubscribe();

    this.socketSessionId = Date.now().toString();

    this.webSocket = io(endpoint, {
      path: '/channel',
      query: {
        socketSessionId: this.socketSessionId
      },
      auth: {
        accessToken: accessToken
      }
    });

    this.webSocket?.on('connect_error', (err) => {
      console.log(err.message);
    });

    this.webSocket?.on('connect', () => {
      console.log('connected');
    });

    this.webSocket?.on('message', (event: any) => {
      Object.values(this.listenerCallbacks).forEach((listenerCallback) => {
        listenerCallback(event);
      });
    });

    this.webSocket.on('error', (error: any): void => {
      console.log('socket error');
      console.log(error);
      onFetchFinish();
      this.unsubscribe();
    });

    this.webSocket.on('close', (event: any): void => {
      console.log('socket closing');
      this.webSocket = null;
    });

    return this;
  }

  unsubscribe() {
    this.webSocket?.disconnect();
    this.webSocket = null;
  }

  addListener(eventType: string, callback: (payload: any) => void) {
    this.listenerCallbacks[eventType] = (payload) => {
      payload = JSON.parse(payload);
      if (payload?.eventType == eventType) {
        callback(payload);
      }
    };
  }

  removeListener(eventType: string) {
    delete this.listenerCallbacks[eventType];
  }
}

export default new Subscriber();
