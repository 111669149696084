import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import useWindowScroll from '../../hooks/useWindowScroll/useWindowScroll';
import { Container } from '../../components/Layout/Container/Container';
import { Text, Title } from '../../components/Typography/Typography';
import { Space } from '../../components/Layout/Space/Space';
import { Flex } from '../../components/Layout/Flex/Flex';
import { Header } from '../../modules/Header/Header';
import { LoadDetails } from '../../modules/FindLoadsList/LoadDetails/LoadDetails';
import { StopsSummary } from '../../modules/FindLoadsList/LoadDetails/StopsSummary/StopsSummary';
import { ActionIcon } from '../../components/Buttons/ActionIcon/ActionIcon';
import { useShallowEffect } from '@mantine/hooks';
import { getLoad } from '../../api/api';
import { AppContext, LoadProps } from '../../context';
import { Loader } from '../../components/Loaders/Loader';
import { Paper } from '../../components/Miscellaneous/Paper/Paper';
import { Center } from '../../components/Layout/Center/Center';

interface Props {
  offer: boolean;
  navigateBackTo: string;
}

export const LoadDetailsPage = ({
  offer,
  navigateBackTo
}: Props): ReactElement => {
  const { sources } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [, scrollTo] = useWindowScroll();
  const [load, setLoad] = useState<LoadProps | undefined>(undefined);
  const [notFound, setNotFound] = useState(false);
  const [expired, setExpired] = useState(false);

  const savedSearchId = location?.state?.savedSearchId;
  const loadListIndex = location?.state?.loadListIndex;
  const loadListScrollOffset = location?.state?.loadListScrollOffset;

  const navigateBack = () => {
    navigate(navigateBackTo, {
      state: {
        isReturnFromDetailsPage: true,
        savedSearchId,
        loadListIndex,
        loadListScrollOffset
      }
    });
  };

  useEffect(() => {
    const splitLocation = location.pathname.split('/');
    const stable_id = splitLocation.pop();

    getLoad(stable_id).then((load) => {
      if (load && load.id && load.stable_id && load.content) {
        if (sources) {
          const lifeTime = sources[load.content.source].load_lifetime;
          const now = new Date().getTime();
          const then = new Date(load.created_at).getTime();
          setExpired((now - then) / 1000 >= lifeTime);
        }

        // mimic of what websockets push to frontend on load search
        setLoad({
          ...load.content,
          id: load.id,
          stable_id: load.stable_id,
          created_at: load.created_at
        });
      } else {
        setNotFound(true);
      }
    });
  }, [location.pathname, sources]);

  useShallowEffect(() => {
    scrollTo({ y: 0 });
  }, []);

  return (
    <Container fluid p={0}>
      <Header />
      <Container
        fluid
        sx={{
          minHeight: 'calc(100vh - 100px)',
          backgroundColor: '#F4F6FC',
          boxShadow: 'inset 0 0 10px 0 rgba(0,0,0,0.2)'
        }}
        p={0}
      >
        {load === undefined && !notFound && (
          <Loader
            style={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        )}
        {notFound && (
          <Container size="xl">
            <Space h="xl" />
            <Paper shadow="sm" mb="xl" p="xl">
              <Center>
                <Title order={2}>Load not found</Title>
              </Center>
            </Paper>
          </Container>
        )}
        {load && (
          <Container size="xl">
            <Space h="xl" />
            <Flex align="center" justify="space-between">
              <Flex align="center" justify="space-between">
                <ActionIcon onClick={navigateBack} mr="lg">
                  <FaArrowLeft size={20} />
                </ActionIcon>
                <Text mr="xs" c="dimmed" fz="lg">
                  Load number:
                </Text>
                <Title order={2}>#{load.load_id}</Title>
                {!offer && expired && (
                  <Title
                    style={{ margin: 'auto 10px', lineHeight: 1.5 }}
                    className={'offer rejected'}
                    order={4}
                  >
                    Expired
                  </Title>
                )}
              </Flex>
              {load.load_source_url && (
                <a
                  className="offer bid"
                  target={'_blank'}
                  style={{ textDecoration: 'none' }}
                  href={load.load_source_url}
                >
                  Original listing
                </a>
              )}
            </Flex>
            <Space h="xl" />
            <StopsSummary stops={load.stops} />
            <LoadDetails load={load} isOffer={offer} />
          </Container>
        )}
      </Container>
    </Container>
  );
};
