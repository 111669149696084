import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Title, Text } from '../../components/Typography/Typography';
import { Box } from '../../components/Miscellaneous/Box/Box';
import { styles } from './BidsLive.style';
import {
  Alert,
  ColorSwatch,
  Dialog,
  Group,
  ScrollArea,
  useMantineTheme
} from '@mantine/core';
import { AiOutlineCheck } from 'react-icons/ai';
import { Checkbox } from '../../components/Inputs/Checkbox/Checkbox';
import { AppContext, LoadProps } from '../../context';
import { Row } from '../FindLoadsList/Row/Row';
import { Flex } from '../../components/Layout/Flex/Flex';
import { Space } from '../../components/Layout/Space/Space';
import { Button } from '../../components/Buttons/Button/Button';
import { OfferModal } from '../../modals/OfferModal/OfferModal';
import { useTheme } from '@emotion/react';

interface TimeStampProps {
  resetTimeStamp: boolean;
}

const offerStatusChanged = (
  offer: LoadProps,
  notificationOffers: LoadProps[]
) => {
  const off = notificationOffers.find((ele) => ele.id === offer.id);

  return !!off;
};

export const BidsLive = (): ReactElement => {
  const {
    liveOffers: offers,
    setLiveOffers,
    notificationOffers,
    setNotificationOffers
  } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOffer, setModalOffer] = useState<any>(null);
  const [animateNotification, setAnimateNotification] = useState(false);
  const theme = useMantineTheme();
  const prevNotificationOffersLength = useRef<number>(
    notificationOffers.length
  );

  const renderRow = (offer: any) => (
    <Row
      load={offer.load.content}
      key={offer.id}
      type="bidsLive"
      statusChanged={offerStatusChanged(offer, notificationOffers)}
      offer_status={offer.status}
      offer={offer}
      onModalOpen={openModal}
    />
  );

  const clearOffers = () => {
    setLiveOffers({
      type: 'SET',
      payload: []
    });
  };

  const openModal = (offer: any) => {
    setModalOffer(offer);
    setModalOpen(true);
  };

  useEffect(() => {
    if (modalOffer && !offers.includes(modalOffer)) {
      setModalOffer(null);
      setModalOpen(false);
    }
  }, [offers]);

  useEffect(() => {
    if (notificationOffers.length > prevNotificationOffersLength.current) {
      setAnimateNotification(true);
      const timer = setTimeout(() => setAnimateNotification(false), 1000);
      return () => clearTimeout(timer);
    }

    prevNotificationOffersLength.current = notificationOffers.length;
  }, [notificationOffers]);

  return (
    <>
      {isOpen && (
        <Dialog
          className={'bids-dialog'}
          style={{ height: '100%' }}
          opened={isOpen}
          withCloseButton
          onClose={() => {
            setIsOpen(false);
            setNotificationOffers({
              type: 'SET',
              payload: []
            });
          }}
          size={600}
          radius="md"
          position={{ top: 0, bottom: -50, right: 0 }}
          shadow="xl"
        >
          <Flex align="center" gap="sm" style={{ marginBottom: 14 }}>
            <Title order={5}>GP Bids Live</Title>
            <ColorSwatch color="red" size={24}>
              <Text size="sm" sx={{ color: '#fff' }}>
                {offers.length}
              </Text>
            </ColorSwatch>
          </Flex>
          <Alert
            icon={<AiOutlineCheck size={16} />}
            title="Please find information of all GP bids below"
            color="teal"
          >
            You can check your offers information anytime in section "My Offers"
          </Alert>
          <Space h={20} />
          <Button onClick={clearOffers}>Clear offers</Button>
          <Space h={20} />
          {/*
            <Chip.Group position="center" multiple my={15}>
            <Chip value="1" radius="md">
              All
            </Chip>
            <Chip value="2" radius="md">
              Accepted
            </Chip>
            <Chip value="3" radius="md">
              Pending
            </Chip>
            <Chip value="4" radius="md">
              Countered
            </Chip>
            <Chip value="5" radius="md">
              Declined
            </Chip>
          </Chip.Group>
          <Checkbox
            value="wholeCompanyLoads"
            label={
              <Text c="dimmed" size="md">
                Whole company loads
              </Text>
            }
            checked={true}
            onChange={(event) => {}}
            radius="sm"
            mb="sm"
          />
          */}
          <ScrollArea.Autosize maxHeight="calc(100% - 230px)">
            {offers.map(renderRow)}
          </ScrollArea.Autosize>
        </Dialog>
      )}
      {isOpen && modalOpen && (
        <OfferModal
          key="bidsLiveOfferModal"
          offer={modalOffer}
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
        />
      )}
      {!isOpen && (
        <Box
          sx={styles(theme, animateNotification)}
          px="md"
          py="sm"
          onClick={() => {
            if (offers.length) {
              setIsOpen(true);
            }
          }}
        >
          <Group>
            <Title order={6}>GP Bids Live</Title>
            <ColorSwatch
              color={notificationOffers.length > 0 ? 'red' : 'green'}
              size={20}
            >
              <Text size="sm" sx={{ color: '#fff' }}>
                {notificationOffers.length}
              </Text>
            </ColorSwatch>
          </Group>
        </Box>
      )}
    </>
  );
};
