import { ReactElement, useContext, useEffect, useState } from 'react';
import { Row } from './Row/Row';
import { Space } from '../../components/Layout/Space/Space';
import { Title } from '../../components/Typography/Typography';
import { getSavedSearches } from '../../api/api';
import { AppContext, SavedSearchFilterProps } from '../../context';
import { Button } from '../../components/Buttons/Button/Button';
import { SavedSearchModal } from './SavedSearchModal';
import moment from 'moment/moment';

const initialValues: SavedSearchFilterProps = {
  origin: {
    location: {
      coordinates: {
        longitude: '',
        latitude: ''
      },
      address: {
        formatted_address: ''
      }
    },
    radius: 50
  },
  pickup_date: moment().format('MM/DD/YYYY'),
  delivery_date: moment().format('MM/DD/YYYY'),
  destination: {
    location: {
      coordinates: {
        longitude: '',
        latitude: ''
      },
      address: {
        formatted_address: ''
      }
    },
    radius: 50
  },
  equipment_types: ['dry_van'],
  equipment_sizes: [53, 48, 45, 40, 32, 20],
  load_id: '',
  load_types: ['FTL'],
  load_weight: {
    min: 0,
    max: 80000
  },
  loaded_miles: {
    min: 350,
    max: 10000
  },
  sources: [
    'uber',
    'coyote',
    'echo_global',
    'ch_robinson',
    'newtrul',
    'loadsmart'
  ],
  stops: 2,
  rpm_from: 0.1,
  capacity_of_lane: 1,
  recommendations: false,
  autobook: false,
  time_from: '00:00',
  time_to: '23:59',
  ml: true,
  active: false
};

export const SavedSearchesList = (): ReactElement => {
  const { savedSearches, setSavedSearches } = useContext(AppContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    getSavedSearches().then((response) => {
      setSavedSearches({
        type: 'SET',
        payload: response || []
      });
    });
  }, []);

  const renderRow = (search: any, index: number) => (
    <Row
      savedSearch={search}
      key={search.id}
      isLastRow={index === search.length - 1}
    />
  );

  const handleFilterModal = (opened = false) => {
    setIsFilterOpen(opened);
  };

  return (
    <>
      <Space h="xl" />
      <Title order={2}>Saved searches ({savedSearches.length})</Title>
      <Space h="xl" />
      <Button size="md" h={47} onClick={() => handleFilterModal(true)}>
        Create Automatic Load Search
      </Button>
      <Space h="xl" />
      <SavedSearchModal
        search={initialValues}
        savedSearchId={null}
        opened={isFilterOpen}
        close={() => {
          handleFilterModal(false);
        }}
      />
      {savedSearches.map(renderRow)}
    </>
  );
};
